// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// import { initializeApp } from "firebase/app";
// import { getFirestore } from '@firebase/firestore';

export const firebaseConfig = {
  apiKey: "AIzaSyAJgVn2iwHc1EDgGXOQIwhmv2JnehXn9WY",
  authDomain: "website-1-1-b7be3.firebaseapp.com",
  projectId: "website-1-1-b7be3",
  storageBucket: "website-1-1-b7be3.appspot.com",
  messagingSenderId: "1090455787873",
  appId: "1:1090455787873:web:c6efe4fea61b2660828d20",
  measurementId: "G-546DL93FM4"
};

// const app = initializeApp(firebaseConfig);
// export const db = getFirestore(app);