import React, { useState, useEffect } from "react";
import { Drawer } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import NavFront from "./Front/NavFront";
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { auth } from "../../firebase";
import { useForm, ValidationError } from '@formspree/react';
import TextField from '@material-ui/core/TextField';
import Search from "./Front/Search";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';

const ColorButton = withStyles((theme) => ({
  root: {
    color: "white",
    width: "100px",
    height: "40px",
    border: "none",
    color: "white",
    borderRadius: "18px",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "normal",
  },
}))(Button);

const OkBtn = withStyles((theme) => ({
  root: {
    color: "white",
    width: "155px",
    height: "40px",
    border: "none",
    color: "white",
    fontSize: "18px",
    marginTop: "15px",
    borderRadius: "18px",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "normal",
    '@media (max-width: 768px)': {
      width: "140px",
      height: "38px",
      fontSize: "16px",
    },
  },
}))(Button);

const SubmitBtn = withStyles((theme) => ({
  root: {
    margin: "30px 0px 65px",
    width: "160px",
    padding: "10px 8px 6px 8px",
    float: "right",
    alignSelf: "flex-end",
    border: "2px solid #000000",
    borderRadius: "30px",
    '@media (max-width: 1020px)': {
      padding: "8px 6px 4px 6px",
      width: "150px",
    },
    '@media (max-width: 680px)': {
      padding: "6px 6px 4px 6px",
      width: "150px",
      fontSize: "13px"
    },
    '@media (max-width: 430px)': {
      padding: "5px 5px 3px 5px",
      width: "120px",
      fontSize: "12px"
    },
  },
}))(Button);

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontFamily: "Rubik"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    fontSize: "15px",
    boxShadow: "0px 1px 4px rgb(0 0 0 / 20%)",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '@media (max-width: 1020px)': {
      width: `calc(${drawerWidth}px - 40px)`,
    },
    '@media (max-width: 680px)': {
      width: `calc(${drawerWidth}px - 90px)`,
    },
    '@media (max-width: 430px)': {
      width: `calc(${drawerWidth}px - 130px)`,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#DBDBDB",
    overflow: "hidden",
    paddingTop: "100px",
    '@media (max-width: 1020px)': {
      width: `calc(${drawerWidth}px - 40px)`,
    },
    '@media (max-width: 680px)': {
      width: `calc(${drawerWidth}px - 90px)`,
    },
    '@media (max-width: 430px)': {
      width: `calc(${drawerWidth}px - 130px)`,
    },
  },
  elevation4: {
    boxShadow: 0,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    margin: "150px 50px 0",
    '@media (max-width: 1020px)': {
      margin: "150px 30px 0",
    },
    '@media (max-width: 680px)': {
      margin: "150px 20px 0",
    },
    '@media (max-width: 430px)': {
      margin: "150px 10px 0",
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    border: "none",
    outline: "0",
  },
  link: {
    display: "block",
    textDecoration: "none",
    color: "black",
    cursor: "pointer",
    fontWeight: "500",
    fontSize: "18px",
    padding: "10px 0 10px 2vw",
    marginTop: "35px",
    cursor: "pointer",
    '&:hover': {
      cursor: "pointer",
      textDecoration: "none",
      color: "unset"
    },
    '@media (max-width: 1020px)': {
      fontSize: "16px",
    },
    '@media (max-width: 680px)': {
      fontSize: "14px",
    },
    '@media (max-width: 430px)': {
      fontSize: "12px",
    },
  },
  heading: {
    padding: "10px 0 10px 2vw",
    '@media (max-width: 1020px)': {
      fontSize: "22px",
    },
    '@media (max-width: 680px)': {
      fontSize: "20px",
    },
    '@media (max-width: 430px)': {
      fontSize: "18px",
    },
  },

  title: {
    fontSize: "22px",
    '@media (max-width: 1020px)': {
      fontSize: "20px",
    },
    '@media (max-width: 680px)': {
      fontSize: "18px",
    },
    '@media (max-width: 430px)': {
      fontSize: "16px",
    },
  },

  form: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: "40px"
  },

  formgroup: {
    width: "100%",
    marginTop: "10px"
  },
  label: {
    fontSize: "16px",
    textAlign: "left",
    '@media (max-width: 1020px)': {
      fontSize: "14px",
    },
    '@media (max-width: 680px)': {
      fontSize: "12px",
    },
  }
}));

const SettingFeed = () => {
  const classes = useStyles();
  const history = useHistory();
  if (localStorage.getItem('allowed') === '0') {
    window.location.href = '/home';
  }
  const [state, handleSubmit] = useForm("mjvjjvbk");
  const [searchtype, setsearchtype] = useState([]);
  const [navClick, setnavClick] = useState(false);
  const [searchtext, setsearchtext] = useState('');
  const [exacttitle, setexacttitle] = useState('');
  const [searchtitle, setsearchtitle] = useState([]);
  const [searchClicked, setsearchClicked] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const [openFeed, setOpenFeed] = useState(false);
  const handleFeedOpen = () => {
    setOpenFeed(true);
  };
  const handleFeedClose = () => {
    setOpenFeed(false);
  };

  const goToHome = () => {
    window.location.href = '/home';
  };

  useEffect(() => {
    document.title = "Feedback"
    window.scrollTo(0, 0);

  }, []);

  if (state.succeeded) {
    // handleFeedOpen();
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <h1 style={{ textAlign: "center", color: "#000", marginTop: "260px" }}>Thank You!!<br />Feedback Submitted</h1>
        <OkBtn variant="contained" style={{ background: "#5db0d8" }} onClick={goToHome}>
          Ok
        </OkBtn>
      </div>
    )
  }
  const logOut = () => {
    auth.signOut();
    localStorage.removeItem("user");
    history.push('/home');
    localStorage.setItem('allowed', '0');
    localStorage.setItem('signup', '0');
  }




  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar style={{ boxShadow: 'none' }} position="fixed" className={classes.appBar}>
          <NavFront
            setnavClick={setnavClick}
            setsearchClicked={setsearchClicked}
            setexacttitle={setexacttitle}
            searchtitle={searchtitle}
            setsearchtitle={setsearchtitle}
            searchtext={searchtext}
            setsearchtext={setsearchtext}
            setsearchtype={setsearchtype}
          />
          <div>
            {!searchClicked && searchtext !== "" ? (
              <Search
                exacttitle={exacttitle}
                searchtitle={searchtitle}
                setsearchClicked={setsearchClicked}
                searchtype={searchtype}
                searchtext={searchtext}
              />

            ) : null}
          </div>
        </AppBar>

        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerContainer}>
            <h4 className={classes.heading}>Settings</h4>
            <Divider />
            <List>
              <Link className={classes.link} to="/settings">
                Account
              </Link>

              <Link className={classes.link} to="/notification">
                Notifications
              </Link>

              <Link className={classes.link}
                to="/feedback"
                style={{
                  backgroundColor: "#807e7e",
                  color: "white",
                }}
              >
                Feedback
              </Link>

              <Link className={classes.link}
                onClick={handleOpen}
              >
                Log Out
              </Link>

              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div className={classes.paper}>
                    <h5 id="spring-modal-title">Are you sure you want to Log Out?</h5>
                    <div className={classes.root} style={{ justifyContent: "space-evenly", marginTop: "20px" }}>
                      <ColorButton variant="contained" style={{ background: "#5db0d8" }} onClick={() => { logOut() }}>
                        Yes
                      </ColorButton>
                      <ColorButton variant="contained" color="secondary" onClick={() => { handleClose() }}>No</ColorButton>
                    </div>
                  </div>
                </Fade>
              </Modal>

            </List>
          </div>
        </Drawer>


        <main className={classes.content}>
          <h4 className={classes.title}>
            Please give us your feedback and valuable suggestions. So, that we can improve our product and give  you the best possible experience.</h4>
          <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit} method="POST">

            <div className={classes.formgroup}>
              <div className={classes.label}>Email-ID*</div>
              <TextField
                id="outlined-full-width"
                type="email"
                name="Email"
                required
                style={{ marginTop: 8 }}
                size='small'
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
              <ValidationError
                style={{ color: "red" }}
                prefix="Email"
                field="email"
                errors={state.errors}
              />
            </div>

            <div className={classes.formgroup}>
              <div className={classes.label}>Topic*</div>

              <TextField
                id="outlined-full-width"
                name="Topic"
                required
                style={{ marginTop: 8 }}
                size='small'
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </div>

            <div className={classes.formgroup}>
              <div className={classes.label}>Content*</div>

              <TextField
                id="outlined-full-width"
                name="Message"
                required
                multiline
                rows={12}
                fullWidth
                style={{ marginTop: 8 }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
              <ValidationError
                style={{ color: "red" }}
                prefix="Message"
                field="message"
                errors={state.errors}
              />

            </div>

            <SubmitBtn type="submit" disabled={state.submitting}>Submit</SubmitBtn>
            {/* <input type="hidden" name="_next" value="/feedback" /> */}

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={openFeed}
              onClose={handleFeedClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openFeed}>
                <div className={classes.paper}>
                  <h5 id="spring-modal-title">Feedback Submitted,Thank You!!!</h5>
                  <div className={classes.root} style={{ justifyContent: "space-evenly", marginTop: "20px" }}>
                    <ColorButton variant="contained" style={{ background: "#5db0d8" }} onClick={() => { handleFeedClose() }}>
                      Ok
                    </ColorButton>
                  </div>
                </div>
              </Fade>
            </Modal>

          </form>

        </main>
      </div>
    </>
  )
}

export default SettingFeed;