import "./App.css";
import Landing from "./components/pages/Landing";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import React, { useContext, useState, useEffect, createContext } from "react";
import SignIn from "./components/pages/SignIn";
import SignUp from "./components/pages/SignUp";
import Front from "./components/pages/Front/Front";
import HomePage from "./components/pages/HomePage";
import SearchedResult from "./components/pages/Front/SearchedResult";
import Folio from "./components/pages/Folios/Folio";
import About from "./components/pages/About";
import TermsOfService from "./components/pages/TermsOfService";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import FAQs from "./components/pages/FAQs";
import Contact from "./components/pages/Contact";
import FullScreen from "./components/pages/Folios/FullScreen";
import Saved from "./components/pages/Folios/Saved";
import User from "./components/pages/Folios/User";
import Upload from "./components/pages/Folios/Upload";
import File from "./components/pages/File";
import Attributes from "./components/pages/Attributes";
import settings from "./components/pages/SettingAcc";
import settingsFeed from "./components/pages/SettingFeed";
import settingsnotifi from "./components/pages/SettingNoti";
import AllCategory from "./components/pages/Folios/AllCategory";
import { db } from "./firebase";
import { useReducer } from "react";
import { reducer, initialState } from "./reducers/userReducer";
import FirstM from "./components/layouts/First";
import ForgotPasswordModal from "./components/pages/ForgotPasswordModal";
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from "@material-ui/core";
import CookieConsent from "react-cookie-consent";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Helmet } from 'react-helmet';
import OneSignal from "react-onesignal"


const theme = createTheme({
  breakpoints: {
    values: {
      xs: 525,
      sm: 730,
      md: 950,
      lg: 1280,
      xl: 1920,
    },
  },
})


//creating context
export const userContext = createContext();

function Routing() {
  const { state, dispatch } = useContext(userContext);
  // console.log(state);

  useEffect(() => {
    var uid, email, name;
    var user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      uid = user['uid'];
      email = user['email'];
      name = null;
    } else {
      uid = null;
      email = null;
    }
    // OneSignal.push(() => {
    OneSignal.init({
      appId: "98200151-ac48-4869-bf4e-9dc618690ed7",
    });
    OneSignal.sendTag("uid", uid);
    // OneSignal.setEmail(email)
    //   .then(function (emailId) {
    //     console.log("emailId: ", emailId);
    //   });
    // });
  }, []);

  useEffect(() => {
    async function fetchdata() {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user) {


        db.collection("users")
          .get()
          .then((snapShot) => {
            const users = snapShot.docs.map((doc) => {
              const data = doc.data();
              return { userid: doc.id, ...data };
            });
          });



        db.collection("posts")
          .get()
          .then((snapShot) => {
            const posts = snapShot.docs.map((doc) => {
              const data = doc.data();
              return { id: doc.id, ...data };
            });
            const currentUser = {
              user,
              posts,
            };
            dispatch({ type: "USER", payload: currentUser });
          });
      } else {
        //   if (!history.location.pathname.startsWith("/signup") && !state.uid) {
        //       history.push("/signin");
        // }
      }
    }
    fetchdata();
  }, [dispatch]);
  // console.log(state);

  return (
    <Switch>
      {/* <Route exact path="/" component={Start} /> */}
      <Route exact path="/" component={Landing} />
      <Route exact path="/m" component={FirstM} />
      <Route path="/signin" component={SignIn} />
      <Route path="/signup" component={SignUp} />
      <Route path="/home" component={Front} />
      <Route path="/homepage" component={HomePage} />
      <Route path="/folio/:id" component={Folio} />
      <Route path="/p/:postid/:id" component={FullScreen} />
      <Route path="/aboutus" component={About} />
      <Route path="/faq" component={FAQs} />
      <Route path="/contact" component={Contact} />
      <Route path="/terms" component={TermsOfService} />
      <Route path="/privacy" component={PrivacyPolicy} />
      <Route path="/saved" component={Saved} />
      <Route path="/user/:userid" component={User} />
      <Route path="/upload" component={Upload} />
      <Route path="/attributes" component={Attributes} />
      <Route path="/publish" component={File} />
      <Route path="/all" component={AllCategory} />
      <Route path="/settings" component={settings} />
      <Route path="/feedback" component={settingsFeed} />
      <Route path="/notification" component={settingsnotifi} />
      {/* <Route path="/firstmodal" component={First} /> */}
      <Route path="/searchresults" component={SearchedResult} />
      <Route path="/forgetpass" component={ForgotPasswordModal} />
    </Switch>
  );
}

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  // const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   setLoading(true)
  //   setTimeout(() => {
  //     setLoading(false)
  //   }, 3000);
  // }, [])

  if (localStorage.getItem("allowed") === null) {
    localStorage.setItem("allowed", "0");
  }
  return (
    <>
      {/* {
        loading ?

          <div className="loader"></div>

          :
          <> */}

      <Helmet>

        {/* <!-- HTML Meta Tags --> */}
        <title>Kredey | Publish and Read Amazing Documents</title>
        <meta name="description" content="Kredey Folio is a platform where people, can read documents to learn new things and publishers, can share their work and reach millions of readers" />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Kredey | Publish and Read Amazing Documents" />
        <meta itemprop="description" content="Kredey Folio is a platform where people, can read documents to learn new things and publishers, can share their work and reach millions of readers" />
        <meta itemprop="image" content="" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://www.kredey.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Kredey | Publish and Read Amazing Documents" />
        <meta property="og:description" content="Kredey Folio is a platform where people, can read documents to learn new things and publishers, can share their work and reach millions of readers" />
        <meta property="og:image" content="" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Kredey | Publish and Read Amazing Documents" />
        <meta name="twitter:description" content="Kredey Folio is a platform where people, can read documents to learn new things and publishers, can share their work and reach millions of readers" />
        <meta name="twitter:image" content="" />

      </Helmet>


      <ThemeProvider theme={theme}>
        <CookieConsent
          location="bottom"
          buttonText="I understand"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#F1F2F6", color: "#000", zIndex: "9999" }}
          buttonStyle={{ color: "#000", background: "rgb(227 227 227)", fontSize: "14px", width: "150px" }}
          expires={365}
        >
          <ErrorOutlineIcon style={{ paddingBottom: "2px" }} />
          <span style={{ paddingLeft: "10px" }} >This website uses cookies to enhance the user experience. By continuing to use our website, you agree to our  <a style={{ color: "#115cfa", cursor: "pointer" }} href="/privacy">Privacy Policy</a>{" "}</span>
        </CookieConsent>
        <userContext.Provider value={{ state, dispatch }}>
          <React.Fragment>
            <BrowserRouter>
              <Routing />
            </BrowserRouter>
          </React.Fragment>
        </userContext.Provider>
      </ThemeProvider>
      {/* </>
      } */}

    </>
  );
}

export default App;
