import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useHistory } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  root: {
    margin: 0,
    'padding-bottom': "0px",
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.4),
    top: theme.spacing(0.4),
    color: theme.palette.grey[500],
  },
});

const Title = withStyles((theme) => ({
  root: {
    "font-family": "Rubik",
    "font-size": "24px",
    color: "#3e6ae1",
    fontWeight: "bold",
    '@media (max-width: 615px)': {
      "font-size": "20px",
    },
    '@media (max-width: 425px)': {
      "font-size": "18px",
    },
  },
}))(Typography);

const Para = withStyles((theme) => ({
  root: {
    "font-family": "Rubik",
    "font-weight": "bold",
    "color": "#7b7b7b",
    "margin-top": "10px",
    '@media (max-width: 615px)': {
      "font-size": "14px",
    },
    '@media (max-width: 425px)': {
      "font-size": "12px",
    },
  },
}))(Typography);

const Para1 = withStyles((theme) => ({
  root: {
    "font-family": "Rubik",
    "font-size": "26px",
    '@media (max-width: 615px)': {
      "font-size": "22px",
    },
    '@media (max-width: 425px)': {
      "font-size": "20px",
    },
  },
}))(Typography);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Title variant="h6">{children}</Title>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}
);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    display: "flex",
    justifyContent: "center",
    // "text-align": 'center',
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const SigninBtn = withStyles((theme) => ({
  root: {
    'border-radius': "31.55px",
    "background": "#3e6ae1",
    "font-family": "Rubik",
    "font-size": "15px",
    "font-weight": "500",
    color: "white",
    border: "none",
    height: "40px",
    width: "150px",
    margin: "5px 0",
    padding: "9.3px 16.456px",
    cursor: "pointer",
    "text-align": "center",
    '&:hover': {
      backgroundColor: "#3e6ae1",
    },
    '@media (max-width: 615px)': {
      "font-size": "13px",
    },
    '@media (max-width: 425px)': {
      height: "35px",
    },
  },
}))(Button);

export default function VerificatonModal({ open, setOpen }) {
  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
    history.push('/signin');
  };

  return (
    <div>
      <Dialog keepMounted TransitionComponent={Transition} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} style={{ width: "auto", "margin": "0", "text-align": "center" }}>
        <div style={{ padding: "20px" }}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Thank you for signing up
          </DialogTitle>
          <DialogContent>
            <Para1 gutterBottom>
              Verify your email address
            </Para1>
            <Para gutterBottom>
              Please click on the link that has been sent to your email address.
            </Para>
          </DialogContent>
          <DialogActions>
            <SigninBtn onClick={handleClose}>
              Sign In
            </SigninBtn>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}