import React, { useEffect } from 'react'
import Navbar from "../layouts/Navbar";
import { Head, Content } from './TermsOfService'
import Footer from '../layouts/Footer';

const Attributes = () => {
    window.scrollTo(0, 0);

    useEffect(() => {
        document.title = "Attributes | Kredey | Publish and Read Amazing Documents"
    }, []);

    return (
        <React.Fragment>

            <Navbar />
            <Content>

                <Head>Attributes</Head>
                <p style={{
                    marginBottom: "30px",
                }}
                >Our sincere thanks to all the free online resources.<br /><br />Links of the images we used on our site -</p>


                <a href='https://www.freepik.com/photos/technology'>Technology photo created by freepik - www.freepik.com</a><br></br>
                <br></br>
                <a href='https://www.freepik.com/vectors/logo'>Logo vector created by freepik - www.freepik.com</a><br></br>
                <br></br>
                <a href='https://www.freepik.com/vectors/logo'>Logo vector created by freepik - www.freepik.com</a><br></br>
                <br></br>
                <a href="https://storyset.com/work">Illustration by Freepik Storyset</a><br></br>
                <br></br>
                <a href='https://www.freepik.com/vectors/background'>Background vector created by rawpixel.com - www.freepik.com</a><br></br>
                <br></br>
                <a href='https://www.freepik.com/psd/technology'>Technology psd created by freepik - www.freepik.com</a><br></br>
                <br></br>
                <a href='https://www.freepik.com/photos/paper'>Paper photo created by freepik - www.freepik.com</a><br></br>
                <br></br>
                <a href='https://avatars.dicebear.com/styles/adventurer-neutral'>Avatars by DiceBear Avatars(Implementation of Adventurer by Lisa Wischofsky. ) - https://avatars.dicebear.com</a><br></br>
                <br></br>
                <a href="https://storyset.com/idea">Idea illustrations by Storyset</a><br></br>
                <br></br>
                <a href='https://www.freepik.com/photos/star'>Star photo created by kjpargeter - www.freepik.com</a>
                <br></br>
            </Content>
            <hr style={{ margin: 0, height: "0.8px" }} />
            <Footer />
        </React.Fragment>
    )
};

export default Attributes;