import React from "react";
import "../../intro.css";
import Grid from '@material-ui/core/Grid';


const Intro = () => {
    return (
        <>
            <section className="showcase">
                <Grid container>
                    <Grid item md={6} className="intro" style={{ margin: "auto" }}>
                        <div className="head">
                            <p style={{ cursor: "default" }}><span>Kredey Folio</span></p>
                        </div>
                        <p style={{ cursor: "default" }}>Anything that contains readable information and makes learning easy for you,<span style={{ fontWeight: "bold" }}> we call them Folio.</span>
                        </p>
                        <p style={{ cursor: "default" }}>Folio is one word we use for: a document on a certain topic, notes of a subject or any other material in textual form.
                        </p>
                    </Grid>
                    <Grid item md={6} className="showcase-img img-1">
                    </Grid>
                </Grid>
            </section>
        </>
    )
}
export default Intro;
