import React from 'react';
import "../../footer.css"
import fb from "../../assets/images/social-media/fb.svg"
import tweet from "../../assets/images/social-media/tweet.svg"
import insta from "../../assets/images/social-media/insta.svg"
import printest from "../../assets/images/social-media/pinterest.svg"
import linkedin from "../../assets/images/social-media/linkedin.svg"
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <div className="footer">

            <div className="link">
                <Link to="/aboutus" style={{ textDecoration: 'none' }}><p>About Us</p></Link>
                <Link to="/contact" style={{ textDecoration: 'none' }}><p>Contact</p></Link>
                <Link to="/terms" style={{ textDecoration: 'none' }}><p>Terms Of Service</p></Link>
                <Link to="/privacy" style={{ textDecoration: 'none' }}><p>Privacy Policy</p></Link>
                <Link to="/faq" style={{ textDecoration: 'none' }}><p>FAQ’s</p></Link>
                <Link to="/attributes" style={{ textDecoration: 'none' }}><p>Attributes</p></Link>
            </div>

            <div className="social">
                <a href="https://www.facebook.com/Kredey-182297576811708"><img src={fb} alt="fb" /></a>
                <a href="https://www.instagram.com/kredeycompany/"><img src={insta} alt="insta" /></a>
                <a href="https://www.linkedin.com/company/kredey/?viewAsMember=true"><img src={linkedin} alt="linkedin" /></a>
                <a href="https://twitter.com/kredeycompany"><img src={tweet} alt="tweet" /></a>
                <a href="https://in.pinterest.com/kredeycompany/_saved/"><img src={printest} alt="printest" /></a>
            </div>
            <p className="copyright" style={{ cursor: "default" }}>Copyright &#169;2022 Kredey</p>

        </div>
    )
}
export default Footer;