import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Xave from "../../../assets/save.svg";
import share from "../../../assets/ss.svg";
import info from "../../../assets/u.svg";
import like from "../../../assets/like.svg";
import unlike from "../../../assets/Vector.svg";
import { db, auth } from "../../../firebase";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import bookmark from "../../../assets/bookmark.png";
import SignInDialog from "./SignInDialog";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  RedditIcon,
  LinkedinIcon,
  PinterestIcon,
  WhatsappIcon,
  TelegramIcon,
  TwitterIcon,
} from "react-share";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 3),
    border: "none",
    outline: "0",
    '@media (max-width: 760px)': {
      display: "grid",
      gridTemplateColumns: "64px 64px 64px",
      gridGap: "10px",
      justifyContent: "center",
      justifyItems: "center",
      width: "300px"
    },
  },
}));

const Profile = ({
  title,
  Category,
  Date_c,
  D_Name,
  LikeCount,
  click,
  Tofollow,
  followcount,
  readc,
  postid,
  uid,
  puid,
  prof,
  view_prof,
  Desc,
  Cover
}) => {
  const classes = useStyles();
  const [following, setfollowing] = useState(false);
  const [liked, setliked] = useState(false);
  const [btnText, setbtnText] = useState("Follow");
  const [open, setOpen] = React.useState(false);
  const [save, setsave] = useState(false);
  const [openDialog, setopenDialog] = useState(false);

  useEffect(() => {
    if (auth.currentUser !== null) {
      const userRef = db.collection("users").doc(uid);
      userRef.get().then((doc) => {
        if (doc.data().saved.includes(postid)) {
          setsave(true);
          // console.log("ok");
        } else {
          setsave(false);
        }
      });

      //using database like extracttion
      // var likedColor = document.getElementById('LikedImg');
      // const userRef3 = db.collection("posts").doc(postid);
      // userRef3.get().then((doc) => {
      //   if (doc.data().liked_By.includes(uid)) {
      //     likedColor.setAttribute('src', like);
      //   }
      //   else {
      //     likedColor.setAttribute('src', unlike);
      //   }
      // });

      //using states liked
      const userRef3 = db.collection("posts").doc(postid);
      userRef3.get().then((doc) => {
        if (doc.exists) {
          if (doc.data().liked_By.includes(uid)) {
            setliked(true);
          } else {
            setliked(false);
          }
        }
      });


    }
  }, []);



  // using database like extracttion
  // if (window.performance) {
  //   console.info("window.performance works fine on this browser");
  // }
  // console.info(performance.navigation.type);
  // if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
  //   console.info("This page is reloaded");
  //   var likedColor = document.getElementById('LikedImg');
  //   const userRef3 = db.collection("posts").doc(postid);
  //   userRef3.get().then((doc) => {
  //     var likedColor = document.getElementById('LikedImg');
  //     if (doc.data().liked_By.includes(uid)) {
  //       likedColor.setAttribute('src', like);
  //     }
  //     else {
  //       likedColor.setAttribute('src', unlike);
  //     }
  //   });

  // } else {
  //   console.info("This page is not reloaded");
  // }


  // // using states liked
  // if (window.performance) {
  //   console.info("window.performance works fine on this browser");
  // }
  // console.info(performance.navigation.type);
  // if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
  //   // console.info("This page is reloaded");
  //   const userRef3 = db.collection("posts").doc(postid);
  //   userRef3.get().then((doc) => {
  //     if (doc.exists) {
  //       if (doc.data().liked_By.includes(uid)) {
  //         setliked(true);
  //       } else {
  //         setliked(false);
  //       }

  //       if (doc.data().liked_By.includes(uid)) {
  //         setsave(true);
  //       } else {
  //         setsave(false);
  //       }
  //     }
  //   });

  // } else {
  //   console.info("This page is not reloaded");
  // }



  useEffect(() => {
    if (puid) {
      const userRef2 = db.collection("users").doc(puid);
      userRef2.get().then((doc) => {
        if (doc.data().follower.includes(uid)) {
          setbtnText("Following");
          setfollowing(true);
          // console.log("ok");
        }
      });
    }
  }, [puid]);

  // useEffect(() => {
  //   const userRef3 = db.collection("posts").doc(postid);
  //   userRef3.get().then((doc) => {
  //     if (doc.exists) {
  //       if (doc.data().liked_By.includes(uid)) {
  //         setliked(true);
  //       } else {
  //         setliked(false);
  //       }

  //       if (doc.data().liked_By.includes(uid)) {
  //         setsave(true);
  //       } else {
  //         setsave(false);
  //       }
  //     }
  //   });
  // }, [])


  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  function changeFollowing() {
    if (auth.currentUser === null) {
      setopenDialog(true);
    } else {
      Tofollow();

      if (following) {
        setbtnText("Follow");
        setfollowing(false);
      } else {
        setbtnText("Following");
        setfollowing(true);
      }
    }
  }

  //using database like extracttion
  // function changeLike() {
  //   if (auth.currentUser === null) {
  //     setopenDialog(true);
  //   } else {
  //     click();
  //     var likedColor = document.getElementById('LikedImg');

  //     const userRef3 = db.collection("posts").doc(postid);
  //     userRef3.get().then((doc) => {
  //       if (doc.data().liked_By.includes(uid)) {
  //         // setliked(true);

  //         likedColor.setAttribute('src', like);
  //       } else {
  //         likedColor.setAttribute('src', unlike);
  //       }
  //     });

  //   }
  // }


  //using states liked
  function changeLike() {
    if (auth.currentUser === null) {
      setopenDialog(true);
    } else {
      click();
      if (liked) {
        setliked(false);
      }
      else {
        setliked(true);
      }

    }
  }


  // console.log(save, "save");
  var saveId = [];
  const changeSaved = async (id) => {
    const uid = JSON.parse(localStorage.getItem("user"))["uid"];
    // setpass(!pass);
    db.collection("users")
      .doc(uid)
      .get()
      .then((doc) => {
        saveId = doc.data().saved;
        let arr = [...saveId];
        if (arr.indexOf(id) > -1) {
          arr = arr.filter((e) => e !== id);
          setsave(false);

          db.collection("users").doc(uid).update({ saved: arr });
        } else {
          arr.push(id);

          db.collection("users").doc(uid).update({ saved: arr });
          setsave(true);
        }
      });
  };
  function changeSave() {
    if (auth.currentUser === null) {
      setopenDialog(true);
    } else {
      changeSaved(postid);
    }
  }


  if (auth.currentUser !== null) {
    const userRef = db.collection("users").doc(uid);
    userRef.get().then((doc) => {
      if (doc.data().saved.includes(postid)) {
        setsave(true);
        // console.log("ok");
      } else {
        setsave(false);
      }
    });


    const userRef3 = db.collection("posts").doc(postid);
    userRef3.get().then((doc) => {
      if (doc.exists) {
        if (doc.data().liked_By.includes(uid)) {
          setliked(true);
        } else {
          setliked(false);
        }
      }
    });
  }

  const [openTip, setOpenTip] = React.useState(false);




  const handleTooltipClose = () => {
    setOpenTip(false);
  };

  const handleTooltipOpen = () => {
    navigator.clipboard.writeText(window.location.href)
    setOpenTip(true);
  };

  React.useEffect(() => {
    setTimeout(() => {
      setOpenTip(false)
    }, 3000)
  }, [openTip])


  var linker = "mailto:hello@kredey.com?subject=Report Folio: " + window.location.href;

  var imageurl = `${Cover}`;
  return (
    <>
      <Info>
        <Title>{title}</Title>
        <Links>

          <Iconsmd>
            <Like id="LikedImg"
              onClick={changeLike}
              src={liked ? like : unlike}
            />
            <Icon
              src={save ? bookmark : Xave}
              onClick={changeSave}
              bookmark={bookmark}
              Xave={Xave}
              save={save}
            />
            {/* <Icon/> */}
            <IconX onClick={handleOpen} />
          </Iconsmd>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div className={classes.paper}>

                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={openTip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="URL Copied"
                  placement="top"
                >
                  <Button url={window.location.href} onClick={handleTooltipOpen}
                    style={{ width: "64px", height: "64px", marginRight: "5px", borderRadius: "50px", color: "white", background: "#8F9CAC" }} >
                    <FileCopyIcon />
                  </Button>
                </Tooltip>


                <EmailShareButton
                  url={window.location.href}
                  target="_blank"
                  subject={title}
                  style={{ margin: "0 5px" }}>
                  <EmailIcon round={true} />
                </EmailShareButton>

                <WhatsappShareButton url={window.location.href}
                  style={{ margin: "0 5px" }}>
                  <WhatsappIcon round={true} />
                </WhatsappShareButton>

                <LinkedinShareButton
                  url={window.location.href}
                  // title={title}
                  // summary={}
                  style={{ margin: "0 5px" }}>
                  <LinkedinIcon round={true} />
                </LinkedinShareButton>

                <FacebookShareButton
                  url={window.location.href}
                  quote="Kredey | Publish and Read Amazing Documents"
                  hashtag="#document #read #share #folio"
                  style={{ margin: "0 5px" }}>
                  <FacebookIcon round={true} />
                </FacebookShareButton>

                <TwitterShareButton url={window.location.href} style={{ marginLeft: "5px" }}>
                  <TwitterIcon round={true} />
                </TwitterShareButton>

                <TelegramShareButton url={window.location.href} style={{ margin: "0 5px" }}>
                  {" "}
                  <TelegramIcon round={true} />
                </TelegramShareButton>

                <RedditShareButton url={window.location.href} style={{ margin: "0 5px" }}>
                  <RedditIcon round={true} />
                </RedditShareButton>

                <PinterestShareButton media={imageurl} url={window.location.href} description={Desc} style={{ margin: "0 5px" }}>
                  <PinterestIcon round={true} />
                </PinterestShareButton>

              </div>
            </Fade>
          </Modal>
          <div style={{ marginLeft: "auto", zIndex: 0 }}>
            <a href={linker} target="_blank">
              <IconY style={{ float: "right" }} />
            </a>
          </div>
        </Links>

        <Tab>
          <Stat>
            Reads <Span>{readc}</Span>
          </Stat>
          <Stat>
            Recommendations<Span>{LikeCount}</Span>
          </Stat>
          <Stat style={{ width: "min-content" }}>
            Category
            <Span>
              <Link to={{ pathname: '/home', category: `${Category}` }}>{Category}</Link>
            </Span>
          </Stat>
          <Stat >
            Published <Span>{Date_c}</Span>
          </Stat>
        </Tab>

        <DetailInfo>
          {/* Image */}
          <Avatar
            // pic={prof}
            onClick={() => view_prof(puid)}
            style={{ cursor: "pointer", backgroundImage: `url(${prof})` }}
          />
          {/* Name */}
          <NameInfo>
            <Name onClick={() => view_prof(puid)} style={{ cursor: "pointer" }}>{D_Name}</Name>

            <Rating>
              {followcount} Followers
            </Rating>
          </NameInfo>
          {/* Button */}
          <div >
            {uid === puid ? (
              <Button
                onClick={() => view_prof(puid)}
              >
                View Profile
              </Button>
            ) : (
              <Button onClick={changeFollowing}>
                {btnText}
              </Button>
            )}
          </div>

          {/* Links */}
        </DetailInfo>
      </Info>

      {
        openDialog ? (
          <SignInDialog
            open={true}
            from={"Profile"}
            setopenDialog={setopenDialog}
          />
        ) : null
      }
    </>
  );
};
// =====================Styling components==================================
const Info = styled.div`
  display: flex;
  margin: 61px 40px 0px 30px;
  flex-direction: column;
  width: 36.73%;
  @media (max-width: 1145px){
    margin: 61px 30px 0px 30px;
    width: 38%;
  };
  @media (max-width: 1000px){
    margin: 61px 20px 0px 20px;
    width: 41%;
  };
  @media (max-width: 815px){
    margin: 30px 0px 0px 0px;
    width: 100%;
    padding: 0 60px;
  };
  @media (max-width: 620px){
    padding: 0 20px;
  };
  @media (max-width: 450px){
    width: 100%;
    padding: 0 10px;
    };
`;

const Title = styled.div`
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 25px;
line-height: 30px;
overflow: hidden;
word-break: break-word;
@media (max-width: 1000px){
  font-size: 22px;
}
@media (max-width: 815px){
  text-align: center;
}
`;

const Iconsmd = styled.div`
display: flex;
align-items: center;
@media (max-width: 815px){
  position: absolute;
  margin: 0 0 0 -60px;
  width: 100%;
  justify-content: center;
}
@media (max-width: 620px){
  margin: 0 0 0 -20px;
}
@media (max-width: 450px){
  margin: 0 0 0 -10px;
}

`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;  
  width: 100%;
  align-items: center;
  @media (max-width: 815px){
    text-align: center;
  }
`;

const Like = styled.img`
width: 23px;
margin: 0px 30px 0 0px;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 1000px){
    width: 20px;
  }
`;
const Icon = styled.img.attrs((props) => ({
  // src: props.save ? props.Xave : props.bookmark
}))`
  height: ${(props) => (props.save ? "20px" : "20px")};
  width: ${(props) => (props.save ? "20px" : "20px")};
  margin-right: 30px;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 1000px){
    height: ${(props) => (props.save ? "18px" : "18px")};
  width: ${(props) => (props.save ? "18px" : "18px")};
  }
`;
const IconX = styled.img.attrs((props) => ({
  src: share,
}))`
  height: 22.85px;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 1000px){
    height: 18px;
  }
`;
const IconY = styled.img.attrs((props) => ({
  src: info,
}))`
  height: 27px;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 1000px){
    height: 22px;
  }
`;

const Tab = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
  width: 100%;
`;
const Stat = styled.div`
  display: flex;
  color: #7d7b7b;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  margin: 0 4px;
  flex-direction: column;
  @media (max-width: 1000px){
  font-size: 12px;
  }
`;

const Span = styled.span`
  margin-top: 9px;
  color: black;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  @media (max-width: 1000px){
    font-size: 13px;
    }
  
  a{
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`;
const DetailInfo = styled.div`
display: flex;
flex-direction: row;
  margin-top: 50px;
  align-items: center;
  justify-content: space-between
`;

const Avatar = styled.div`
  position: absolute;
  height: 52px;
  width: 52px;
  border-radius: 100%;
  border: 0;
  outline: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 1000px){
    height: 45px;
    width: 45px;
  }
`;

const NameInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  padding: 7.25px 0px 7.25px 60px;
  @media (max-width: 1000px){
    padding: 6px 0px 6px 50px;
  };
  @media (max-width: 815px){
    padding: 6px 0px 6px 50px;
  };
`

const Name = styled.text`
  font-size: 14px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 600;
  @media (max-width: 1000px){
    font-size: 13px;
  }
  `;
const Rating = styled(Name)`
  font-size: 13px;
  font-weight: normal;
  color: #7d7b7b;
  @media (max-width: 1000px){
    font-size: 11px;
  }
  `;

const Button = styled.button`
  // position: relative;
  width: 160px;
  height: 40px;
  border: none;
  color: white;
  background: #5db0d8;
  border-radius: 18px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  @media (max-width: 1000px){
    width: 140px;
    height: 35px;
  };
`;

export default Profile;