import React, { useEffect } from "react"
import Navbar from "../layouts/Navbar"
import Footer from '../layouts/Footer';
import styled from "styled-components"
import { Link } from "react-router-dom";
import img from "../../assets/z1.png"
// import { Helmet } from "react-helmet";

const Contact = () => {
    window.scrollTo(0, 0);
    // useEffect(() => {
    //     document.title = "Contact | Kredey | Publish and Read Amazing Documents"
    // }, []);
    return (
        <>

            <div style={{ minHeight: "100vh" }}>
                <Navbar />
                <Head>Hello, there<span style={{ color: "#5DB0D8", marginLeft: '5px' }}>!</span></Head>
                <ContactMain>
                    <div>
                        <P>
                            <p>For any queries related to job openings,
                                <br />
                                complaints, feedbacks or anything else</p>
                            <br />

                            <p>Please mail us at <Link to="mailto:hello@kredey.com?subject=Hello" style={{ color: '#5DB0D8' }}>hello@kredey.com</Link></p>
                            <p>or</p>
                            <div>Call <a style={{ color: '#5DB0D8' }} href="/">+91-9990250055</a></div>
                        </P>

                    </div>
                    <LineV>.</LineV>
                    <LineH />
                    <Mapbox>

                        <p style={{ marginBottom: "20px" }}>We reside in</p>
                        <MapFrame frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224369.03562454428!2d77.25804439942362!3d28.51668171093333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5a43173357b%3A0x37ffce30c87cc03f!2sNoida%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1628706624902!5m2!1sen!2sin"
                            id="map1">
                        </MapFrame>

                        {/* <GoogleApiWrapper/> */}
                    </Mapbox>

                </ContactMain>
            </div>
            <hr style={{ margin: 0, height: "0.8px" }} />
            <Footer />
        </>
    )
}






const ContactMain = styled.div`
margin:auto;
display:flex;
flex-direction:row;
justify-content:center;
align-items: center;
width: fit-content;
padding-bottom: 150px;
@media(max-width:1125px){
    flex-direction:column;
}
`
const Mapbox = styled.div`
// margin-left: 50px;
width: 655px;
font-size: 20px;
margin: 0 30px 50px;
font-weight: 500;
@media(max-width:1435px){
    width: 500px;
}
@media(max-width:1125px){
    width: 800px;
    text-align: center;
}
@media(max-width:864px){
    width: 536px;
}
@media(max-width:600px){
    width: 400px;
    font-size: 18px;
    margin: 0 12px 50px;
}
@media(max-width:424px){
    width: 330px;
    font-size: 16px;
    margin: 0 8px 50px;
}
`
const MapFrame = styled.iframe`
height: 362px;
width: 655px;
@media(max-width:1435px){
    width: 500px;
}
@media(max-width:1125px){
    width: 800px;
}
@media(max-width:864px){
    width: 536px;
}
@media(max-width:600px){
    width: 400px;
}
@media(max-width:424px){
    width: 330px;
}
`

const LineV = styled.div`
background-image: linear-gradient(rgb(199, 199, 199), rgb(199, 199, 199));
  background-size: 2px 100%;
  background-repeat: no-repeat;
  background-position: center center;
  height: 417px;
  position: absolute;
  top: 243px;
  color: rgb(199, 199, 199);
//   transform: translate(0, -50%);
@media(max-width:1125px){
    display: none;
}
`

const LineH = styled.hr`
display: none;
@media(max-width:1125px){
    display: block;
    border: 1px solid black;
    width: 100%;
}
@media(max-width:600px){
    border: 0.8px solid black;
}
`


const P = styled.div`
font-family: Rubik;
font-style: normal;
font-weight: 500;
font-size: 20px;
text-align: center;
color: #000000;
width: 655px;
margin: 0 30px;
@media(max-width:1435px){
    width: 500px;
}
@media(max-width:1125px){
    width: 800px;
}
@media(max-width:864px){
    width: 536px;
}
@media(max-width:600px){
    width: 400px;
    font-size: 18px;
    margin: 0 12px;
}
@media(max-width:424px){
    width: 330px;
    font-size: 16px;
    margin: 0 8px;
}
`;
const Head = styled.p`
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 40px;
text-align: center;
margin: 50px auto;
@media(max-width:600px){
    font-size: 35px;
}
@media(max-width:424px){
    font-size: 30px;
}
`
export default Contact;