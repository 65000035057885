import React, { useEffect, useState } from "react";
import "../../../discussions.css";
import PublicChat from "../PublicChat";
import { db } from "../../../firebase";
import styled from "styled-components";
import info from "../../../assets/u.svg";

const Discussions = ({ puid, view_prof }) => {
  const ref = React.createRef();
  const postid = JSON.parse(localStorage.getItem("postid"))["postid"];
  const [chatnum, setchatNum] = useState(0);
  const View = () => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    var k = 0;
    db.collection("posts")
      .doc(postid)
      .collection("conversations")
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const chatid = doc.id;
          k = k + 1;
        });
        setchatNum(k);
        k = 0;
      });
  }, [postid]);

  var linker = "mailto:hello@kredey.com?subject=Report Discussion: " + window.location.href;
  return (
    <>
      <div className="text" ref={ref}>
        <p style={{ fontSize: "20px", cursor: "default" }}>Discussions</p>
        <div className="main">

          <div style={{
            display: "flex",
            fontSize: "15px",
            fontWeight: "500",
            padding: "13px 15px",
          }}>
            <p style={{ width: "fit-content", marginBottom: 0, cursor: "default" }}>
              {chatnum}&nbsp;Chats
            </p>

            <div style={{ width: "100%" }}>
              <a href={linker} target="_blank">
                <IconY style={{ float: "right" }} />
              </a>
            </div>
          </div>

          <hr style={{ margin: "0 0 10px" }} />
          <PublicChat puid={puid} View={View} view_prof={view_prof} />
        </div>

        {/* <div className="ad1">
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4510551333259158"
            crossorigin="anonymous"
          ></script>
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-4510551333259158"
            data-ad-slot="2158945935"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
          <script>(adsbygoogle = window.adsbygoogle || []).push({ });</script>
        </div>
        <div className="ad2">
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4510551333259158"
            crossorigin="anonymous"
          ></script>
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-4510551333259158"
            data-ad-slot="2158945935"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
          <script>(adsbygoogle = window.adsbygoogle || []).push({ });</script>
        </div> */}

      </div>
    </>

  );
};

const IconY = styled.img.attrs((props) => ({
  src: info,
}))`
  height: 23px;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 1000px){
    height: 20px;
  }
`;

export default Discussions;