import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { auth, db } from "../../firebase";
import firebase from "../../firebase";
import google from "../../assets/google.png";
import brand from "../../assets/images/logos/logo2.png";
import '../../signup.css';
import VerificatonModal from "./VerificationModal";
import { useAlert } from 'react-alert'
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Tooltip from '@material-ui/core/Tooltip';

import { userContext } from "../../App";


const genUsername = require("unique-username-generator");

const SignUp = () => {
  window.scrollTo(0, 0);
  const { state, dispatch } = useContext(userContext);
  const history = useHistory();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, seterror] = useState('');
  const [open, setopen] = React.useState(false);

  const [showTooltip, setShowTooltip] = React.useState(false);
  //Checking For user is already signed Up?
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        // console.log("User Logged IN");
        // console.log(user);
      } else {
        // console.log("User is Signed Out");
      }
    });
  }, []);

  //FOR GOOGLE AUTHENTICATION
  const username = genUsername.generateUsername("-", 0, 20);

  var randomLength = Math.floor((Math.random() * 10) + 1);
  function randomString(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  const userProfPic = `https://avatars.dicebear.com/api/adventurer-neutral/:${randomString(randomLength)}.svg`;

  const handleGoogleAuthentication = async () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    try {
      const data = await auth.signInWithPopup(provider);
      const userRef = db.collection('users').doc(auth.currentUser.uid);
      userRef.get().then(
        docsnapshot => {
          if (docsnapshot.exists) {
            userRef.onSnapshot(doc => {
              const username = doc.data().name;
              const follower = doc.data().follower;
              const follower_Count = doc.data().follower_Count;
              const saved = doc.data().saved;
              const noti = doc.data().noti;
              const userData = {
                uid: auth.currentUser.uid,
                email: auth.currentUser.email,
                name: username,
                follower: follower,
                follower_Count: follower_Count,
                saved: saved,
                noti: noti,
              };
              localStorage.setItem("user", JSON.stringify(userData));
              localStorage.setItem('allowed', '1');
              localStorage.setItem('signup', '0');
              history.push('/home');
            })
          }
          else {
            db.collection("users").doc(auth.currentUser.uid).set({
              email: auth.currentUser.email,
              name: username,
              follower: [],
              follower_Count: 0,
              saved: [],
              profilePic: `${userProfPic}`,
              description: "No Description to show",
              noti: true,
            });

            localStorage.setItem(auth.currentUser.uid, 'firsttime');

            const userData = {
              uid: auth.currentUser.uid,
              email: auth.currentUser.email,
              name: username,
              follower: [],
              follower_Count: 0,
              saved: [],
              profilePic: "",
              description: "No Description to show",
              noti: true,
            };
            localStorage.setItem("user", JSON.stringify(userData));
            localStorage.setItem('allowed', '1');
            dispatch({ type: "USER_SIGNUP", payload: userData });
            history.push('/home');
            localStorage.setItem('signup', '1');
          }
        }
      )
    } catch (err) {
      console.log(err);
    }
  };

  const alert = useAlert();

  // Network request for SIGN_UP the user from firebase auth
  const handleSubmitCredentials = async (e) => {
    e.preventDefault();
    db.collection("users").where("name", "==", name)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // console.log(doc.id, " => ", doc.data());
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });

    db.collection("users").where("name", "==", name).get().then(async function (querySnapshot) {
      if (querySnapshot.empty) {
        try {
          e.preventDefault();
          const result = await auth.createUserWithEmailAndPassword(
            email.toString(),
            password
          )

          const user = auth.currentUser;
          user.sendEmailVerification();

          const uid = user.uid;
          db.collection("users").doc(auth.currentUser.uid).set({
            email,
            name,
            follower: [],
            follower_Count: 0,
            saved: [],
            profilePic: `${userProfPic}`,
            description: "No Description to show",
            noti: true,
          });
          localStorage.setItem(uid, 'firsttime');
          setopen(true);
        } catch (err) {
          if (err.code === 'auth/email-already-in-use') {
            seterror('Email Address already registered');
          }
          else if (err.code === 'auth/weak-password') {
            seterror('Password should be of atleast 6 characters')
          }
          console.log(err);
        }
      }

      else {
        alert.success(<div style={{ textTransform: 'none', fontSize: "16px", textAlign: "center", padding: "5px" }}>Username Already Exist! Try some other name.</div>)
      }
    })
  };

  function AvoidSpace(e) {
    if (e.which == 32 || e.key === " ") {
      // console.log('Space Detected');
      e.preventDefault();
    }
  };

  const handleChangeName = (event) => {
    event.preventDefault();
    setName(event.target.value);

    event.target.value = ("" + event.target.value).toLowerCase();

  }

  const avoidCCP = (event) => {
    event.preventDefault();
  }

  const [isActive, setActive] = useState("false");

  const handleClickShowPassword = (e) => {
    setActive(!isActive);
    e.preventDefault();
    document.getElementsByClassName("input")[2].focus();
    var pass = document.querySelector('#password');
    if (pass.type === "password") {
      pass.type = "text";
    } else {
      pass.type = "password";
    }
  };

  React.useEffect(() => {
    document.title = "SignUp | Kredey | Publish and Read Amazing Documents"
    window.scrollTo(0, 0);
  }, []);

  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  function CustomTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow open={showTooltip} classes={classes} {...props} onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)} />;
  }

  function handleTooltip() {
    setShowTooltip(!showTooltip);
    setTimeout(() => {
      setShowTooltip(false);
    }, 2000);
  }


  return (
    <React.Fragment>
      {/* Logo here */}

      <div className='comlogo'>
        <img src={brand} onClick={(e) => window.location.href = '/'} />
      </div>

      {/* Main Content */}
      <div className="content">
        {open ? <VerificatonModal open={open} setOpen={setopen} /> : null}

        <div>

          <form onSubmit={(e) => handleSubmitCredentials(e)}>

            {error !== '' ? error === 'Password should be of atleast 6 characters' ? <p className="passerror">{error}</p> : <p className="passerror2">{error}</p> : null}

            <div>

              <h2 className='heading'>Create Account</h2>

              <div>
                <label style={{ cursor: 'default' }}>Username</label>
                <CustomTooltip title="Uppercase characters and Space not allowed" placement="top">
                  <HelpOutlineIcon onClick={handleTooltip} style={{
                    width: "15px",
                    paddingBottom: "9px",
                    marginLeft: "2px",
                  }} />
                </CustomTooltip>
              </div>
              <input className='input'
                aria-required="true"
                autoCorrect="off"
                autoComplete="off"
                name="firstName"
                required
                type="text"
                maxLength="20"
                onKeyPress={(e) => AvoidSpace(e)}
                onChange={handleChangeName}
                onCut={avoidCCP}
                onCopy={avoidCCP}
                onPaste={avoidCCP}
              />

              <div>
                <label style={{ cursor: 'default' }}>Email Address</label>
              </div>
              <input className='input' aria-label="email" aria-required="true" autoCapitalize="off" autoCorrect="off" maxLength="75" name="email" required type="email" onChange={(e) => setEmail(e.target.value)}
              />

              <div>
                <label style={{ cursor: 'default' }}>Password</label>
              </div>

              <input className='input' id="password" aria-label="Password" aria-required="true" autoCapitalize="off" autoCorrect="off" name="password" type="password" required
                onChange={(e) => setPassword(e.target.value)}
                style={{ paddingRight: "60px" }}
              />
              <span className="icon" onClick={(e) => handleClickShowPassword(e)}>
                {isActive ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </span>



              <p className="tnc-note" style={{ cursor: 'default' }}>
                By clicking Agree & Join or Sign up with Google, You agree to
                Kredey's{" "}
                <Link to="/terms" style={{ color: "#3e6ae1", cursor: "pointer", textDecoration: "none" }}>
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link to="/privacy" style={{ color: "#3e6ae1", cursor: "pointer", textDecoration: "none" }}>
                  Privacy Policy
                </Link>
              </p>

              <div>
                <button type="submit" className='signup-button'>Agree and Join</button>
              </div>

              <div className='or' style={{ 'margin': '30px 0' }}>
                <hr className='hr-left' />
                <div className='or-text' style={{ fontFamily: "Rubik" }}>OR</div>
                <hr className='hr-left' />
              </div>

              <button className='google-button' onClick={handleGoogleAuthentication} style={{ marginBottom: '30px' }}>
                <span>
                  <img src={google} />
                </span>
                <span
                  className='continue'
                  style={{
                    fontFamily: "Rubik",
                  }}
                >
                  Continue With Google
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignUp;
