import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NavFront from "./Front/NavFront";
import wing1 from "../../assets/wing.png";
import { Link, useHistory } from "react-router-dom";
import { useContext } from "react";
import { userContext } from "../../App";
import { db, auth } from "../../firebase";
import firebase from "../../firebase";
import Search from "./Front/Search";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import Footer from "../layouts/Footer";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({

  root: {
    display: 'flex',
    fontFamily: "Rubik",
    justifyContent: "center",
  },
  content: {
    marginTop: "160px",
    fontFamily: 'Rubik',
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "24px 24px 110px",
    alignItems: "center",
    '@media (max-width: 617px)': {
      padding: "24px 15px 24px 15px",
    },
    '@media (max-width: 450px)': {
      marginTop: "90px",
      padding: "24px 8px 24px 8px",
    },
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: "0 20px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "20px",
    border: "none",
    outline: "0",
  },
  knowledge: {
    color: "#5DB0D8",
    cursor: 'default',
    margin: "0 -5px",
    alignSelf: "flex-end",
    '@media (max-width: 690px)': {
      fontSize: "35px",
    },
    '@media (max-width: 617px)': {
      fontSize: "30px",
    },
    '@media (max-width: 520px)': {
      fontSize: "25px",
    },
  },
  fly: {
    '@media (max-width: 450px)': {
      position: "relative",
      bottom: "40px",
    },
  },
  p1: {
    fontSize: "18px",
    width: "640px",
    textAlign: "center",
    '@media (max-width: 690px)': {
      fontSize: "16px",
      width: "570px",
    },
    '@media (max-width: 617px)': {
      width: "500px",
    },
    '@media (max-width: 520px)': {
      fontSize: "14px",
      width: "auto",
    },
  },
  p2: {
    fontSize: '12px',
    margin: '0',
    padding: '10px 0',
    color: 'black',
    cursor: 'default',
    '@media (max-width: 690px)': {
      fontSize: "11px",
    },
    '@media (max-width: 520px)': {
      textAlign: "center",
      padding: '10px 5px',
    },
  },
  p3: {
    fontSize: '12px',
    margin: '0',
    padding: '10px 0',
    color: 'black',
    cursor: 'default',
    '@media (max-width: 690px)': {
      fontSize: "11px",
    },
  },
  p4: {
    fontSize: "11px",
    margin: '0',
    padding: '10px 0',
    textAlign: "center",
    color: "#A6A6A6",
    cursor: 'default',
    marginTop: '20px',
    '@media (max-width: 690px)': {
      fontSize: "10px",
    },
  },
  uploadbox: {
    width: "562px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "20px",
    justifyContent: "center",
    background: "#FFFFFF",
    border: "1px solid #000000",
    boxSizing: "border-box",
    '@media (max-width: 690px)': {
      width: "500px",
    },
    '@media (max-width: 617px)': {
      width: "450px",
    },
    '@media (max-width: 520px)': {
      width: "345px",
    },
    '@media (max-width: 380px)': {
      width: "290px",
    },
  },
  buttonlabel: {
    color: "white",
    background: "#5DB0D8",
    cursor: "pointer",
    borderRadius: "18px",
    width: "180px",
    padding: "8px",
    textAlign: "center",
    margin: "10px 0",
    '@media (max-width: 690px)': {
      width: "170px",
      padding: "6px",
      fontSize: "15px",
    },
    '@media (max-width: 440px)': {
      width: "140px",
      padding: "4px",
      fontSize: "13px",
    },
  }
}));
const File = () => {
  if (localStorage.getItem('allowed') === '0') {
    window.location.href = '/home';
  }
  const ColorButton = withStyles((theme) => ({
    root: {

      width: "140px",
      height: "40px",
      border: "none",
      color: "white",
      background: "#5db0d8",
      borderRadius: "18px",
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "19px",
      textAlign: "center",

      "@media (max-width: 543px)": {
        fontSize: "16px",
        width: "126.34px",
        height: "35px",
      },

      "@media (max-width: 400px)": {
        width: "110px",
      },
    },
  }))(Button);
  const [completed, setCompleted] = useState(0);
  const [navClick, setnavClick] = useState(false);
  const [searchtype, setsearchtype] = useState([]);
  const [searchtext, setsearchtext] = useState('');
  const [exacttitle, setexacttitle] = useState('');
  const [searchtitle, setsearchtitle] = useState([]);
  const [searchClicked, setsearchClicked] = useState(false);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [showTooltip, setShowTooltip] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const history = useHistory();
  const { state, dispatch } = useContext(userContext);
  useEffect(() => { }, [completed]);
  const containerStyles = {
    position: "relative",
    height: "20px",
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
  };

  const fillerStyles = {
    height: "100%",
    width: 0,
    backgroundColor: "#5DB0D8",
    borderRadius: "inherit",
    textAlign: "right",
  };

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
  };

  // console.log(state);
  const uploadPDF = (e) => {
    var fileSize = e.target.files[0].size / 1024 / 1024;
    if (fileSize <= 10) {
      // console.log("Size within limits");
      // console.log(e.target.files[0].size / 1024 / 1024)
      // console.log(e.target.files[0]);
      const uid = auth.currentUser.uid;
      var da = new Date().getTime();
      const fileRef = firebase
        .storage()
        .ref()
        .child(`/folios/${uid}/${da}`);
      const uploadTask = fileRef.put(e.target.files[0]);
      uploadTask.on(
        "state_changed",
        (snapShot) => {
          let percentage = (snapShot.bytesTransferred / snapShot.totalBytes) * 100
          let element = document.getElementById('progress');
          element.style.width = percentage + '%';
          if (percentage >= 100)
            history.push("/upload");
        },
        (error) => {
          // console.log(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            const postArray = [];
            postArray.push({
              url: downloadURL,
            });
            // console.log(downloadURL);
            localStorage.setItem(
              "post",
              JSON.stringify({
                tempURL: downloadURL,
              })
            );
            dispatch({ type: "POST_URL", payload: downloadURL });
          });
        }
      );
    }
    else {
      handleOpen();
    }

  };

  const user = auth.currentUser;
  let uid = 'empty';
  if (user) {
    uid = user.uid;
    // console.log(auth.currentUser.emailVerified, 'emailverify');
  }

  function getPathStorageFromUrl(url) {
    const baseUrl = "https://firebasestorage.googleapis.com/v0/b/website-1-1-b7be3.appspot.com/o/";
    let imagePath = url.replace(baseUrl, "");
    const indexOfEndPath = imagePath.indexOf("?");
    imagePath = imagePath.substring(0, indexOfEndPath);
    imagePath = imagePath.replace("%2F", "/");
    imagePath = imagePath.replace("%2F", "/");

    return imagePath;
  }

  if (auth.currentUser) {
    setTimeout(async () => {
      var db_urls = [];
      db.collection("posts").where("postedBy", "==", uid).get().then(querySnapshot => {
        querySnapshot.forEach(function (doc) {
          var pdfPath = getPathStorageFromUrl(doc.data().pdf_url);
          var imgPath = getPathStorageFromUrl(doc.data().img_url);
          db_urls.push(pdfPath);
          db_urls.push(imgPath);
        })
      });

      var sto_urls = [];

      const storageRef = firebase.storage().ref(`folios/${uid}`);
      await storageRef.listAll().then((listResults) => {
        const promises = listResults.items.map((item) => {
          // console.log("In storage: ", item.fullPath);
          sto_urls.push(item.fullPath);
        });
        Promise.all(promises);
      });

      // console.log("db arr: ", db_urls);
      // console.log("storage arr: ", sto_urls);

      var array3 = []
      array3 = sto_urls.filter(val => !db_urls.includes(val));

      // console.log("After removing duplicate database files => ", array3);

      // console.log(db_urls.length);
      // console.log(db_urls['length']);
      // console.log(sto_urls.length);
      // console.log(array3.length);

      if (db_urls.length > 0 && sto_urls.length > 0) {
        for (let i = 0; i < array3.length; i++) {
          const element = array3[i];
          // console.log(element);
          firebase.storage().ref().child(element).delete().then(function () {
            // console.log("Extra Pdfs Deleted from storage")
          }).catch(function (error) {
            console.log(error);
          });
        }
      }
    }, 1000);

  }

  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);


  // useEffect(async () => {
  //   document.title = "Let The Knowledge Fly | Upload notes, research findings, documents or any other written information that you think is worth sharing to the world"
  // }, []);




  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  function CustomTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow open={showTooltip} classes={classes} {...props} onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)} />;
  }

  function handleTooltip() {
    setShowTooltip(!showTooltip);
    setTimeout(() => {
      setShowTooltip(false);
    }, 2000);
  }

  return (
    <>
      <Helmet>

        {/* <!-- HTML Meta Tags --> */}
        <title>Let The Knowledge Fly | Upload notes, research findings, documents or any other written information that you think is worth sharing to the world</title>
        <meta name="description" content="Upload notes, research findings, documents or any other written information that you think is worth sharing to the world" />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Let The Knowledge Fly | Upload notes, research findings, documents or any other written information that you think is worth sharing to the world" />
        <meta itemprop="description" content="Upload notes, research findings, documents or any other written information that you think is worth sharing to the world" />
        <meta itemprop="image" content="" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://www.kredey.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Let The Knowledge Fly | Upload notes, research findings, documents or any other written information that you think is worth sharing to the world" />
        <meta property="og:description" content="Upload notes, research findings, documents or any other written information that you think is worth sharing to the world" />
        <meta property="og:image" content="" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Let The Knowledge Fly | Upload notes, research findings, documents or any other written information that you think is worth sharing to the world" />
        <meta name="twitter:description" content="Upload notes, research findings, documents or any other written information that you think is worth sharing to the world" />
        <meta name="twitter:image" content="" />

      </Helmet>

      <div>
        {/* Navbar */}
        <NavFront
          setnavClick={setnavClick}
          setsearchClicked={setsearchClicked}
          setexacttitle={setexacttitle}
          searchtitle={searchtitle}
          setsearchtitle={setsearchtitle}
          searchtext={searchtext}
          setsearchtext={setsearchtext}
          setsearchtype={setsearchtype}
        />
        {!searchClicked && searchtext !== "" ? (
          <Search
            exacttitle={exacttitle}
            searchtitle={searchtitle}
            setsearchClicked={setsearchClicked}
            searchtype={searchtype}
            searchtext={searchtext}
          />
        ) : null}
      </div>

      <div className={classes.root}
        style={{ minHeight: "100vh" }}
      >
        <main className={classes.content}>
          <Head style={{ cursor: 'default' }}>
            Let The{" "}
            <Span style={{ cursor: 'default' }}>
              <Image1 style={{ cursor: 'default' }} />
              <p className={classes.knowledge} style={{ cursor: 'default' }}>
                Knowledge
              </p>
              <Image2 style={{ cursor: 'default' }} />
            </Span>
            <text className={classes.fly}>Fly</text>
          </Head>
          <p className={classes.p1} style={{ cursor: 'default' }}>Upload notes, research findings, documents or any other written information that you think is worth sharing to the world</p>


          <div className={classes.uploadbox}>
            <p className={classes.p2} style={{ cursor: 'default' }}>
              Please follow our Publishing Guidelines
              <CustomTooltip title="Refer to the Folio, “Kredey Folio Publishing Rules and Guidelines”" placement="top">
                <InfoOutlinedIcon onClick={handleTooltip} style={{
                  width: "15px",
                  paddingBottom: "8px",
                  marginLeft: "2px",
                  marginRight: "2px",
                }} />
              </CustomTooltip>
              and don’t upload Scanned Images.
            </p>
            <input
              type="file"
              accept=".pdf"
              onChange={(e) => uploadPDF(e)}
              id="upload"
              hidden
            />

            <label className={classes.buttonlabel} for="upload">
              Select File
            </label>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div className={classes.paper}>
                  <h5 id="spring-modal-title" style={{ marginBottom: "15px" }}>Sorry! Uploading failed!<br />Size of the PDF must not exceed the limit.</h5>
                  <div className={classes.root}>
                    <ColorButton variant="contained" onClick={() => { handleClose() }}>
                      OK
                    </ColorButton>

                  </div>
                </div>
              </Fade>
            </Modal>
            <p className={classes.p3} style={{ cursor: 'default' }}
            >
              Only <strong>Pdfs</strong> are supported | Max File Size 10MB
            </p>

            <p className={classes.p4} style={{ cursor: 'default' }}>
              By uploading, you agree to Kredey’s{" "}
              <Link to="/terms" style={{ textDecoration: 'none', cursor: 'pointer' }}>Terms Of Service</Link> and{" "}
              <Link to="/privacy" style={{ textDecoration: 'none', cursor: 'pointer' }}>Privacy Policy</Link>
            </p>
          </div>

          <Prog>
            <div style={containerStyles}>
              <div style={fillerStyles} id="progress">
                <span style={labelStyles}></span>
              </div>
            </div>
          </Prog>
        </main>
      </div >

      <hr style={{ margin: 0, height: "0.8px" }} />
      <Footer />

    </>
  );
};

const Prog = styled.div`
  width: 743px;
  margin: 30px 0;
  padding: 8px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 100px;
  @media (max-width: 790px){
    width: 640px;
  };
  @media (max-width: 690px){
    width: 570px;
  };
  @media (max-width: 617px){
    width: 500px;
    padding: 7px;
  };
  @media (max-width: 520px){
    width: 400px;
    padding: 7px;
  };
  @media (max-width: 440px){
    width: 345px;
    padding: 7px;
  };
  @media (max-width: 380px){
    width: 325px;
    padding: 7px;
  };
`;
const Span = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 23px;
  align-items:center;
  // transform: rotate(8deg) translateX(-12px) translateY(3px);
  @media (max-width: 690px){
    bottom: 27px;
  };
  @media (max-width: 616px){
    bottom: 23px;
  };
  @media (max-width: 520px){
    transform: none;
    bottom: 26px;
  };
  @media (max-width: 450px){
    transform: none;
    bottom: 43px;
  };
`;

const Head = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  font-size: 40px;
  text-align: center;
  @media (max-width: 690px){
  font-size: 35px;
  };
  @media (max-width: 617px){
  font-size: 30px;
  };
  @media (max-width: 520px){
  font-size: 25px;
  };
  @media (max-width: 450px){
    flex-direction: column;
  margin-bottom: 0px;
  };
`;
const Image1 = styled.img.attrs((props) => ({
  src: wing1,
}))`
transform: scaleX(-1);
width: 107px;
@media (max-width: 617px){
  width: 90px;
};
@media (max-width: 450px){
  width: 80px;
};
`;
const Image2 = styled.img.attrs((props) => ({
  src: wing1,
}))`

width: 107px;
@media (max-width: 617px){
  width: 90px;
};
@media (max-width: 450px){
  width: 80px;
};
`;
export default File;
