import React, { useState } from "react"
import styled from "styled-components";
import saveicon from "../../../assets/save.svg";
import bookmark from '../../../assets/bookmark.png';
import { auth, db } from '../../../firebase';
import SignInDialog from "../Folios/SignInDialog";

const Tile = ({
    Image,
    Title,
    Name,
    onclick,
    read,
    id,
}) => {
    const [save, setsave] = useState(false);
    const [openDialog, setopenDialog] = useState(false);
    var saveId = [];
    let uid = '';
    if (localStorage.getItem('allowed') === '1' && auth.currentUser) {
        uid = auth.currentUser.uid;
        const userRef = db.collection('users').doc(uid);
        userRef.get().then(
            doc => {
                if (doc.exists) {
                    if (doc.data().saved.includes(id)) {
                        setsave(true);
                    }
                }
            }
        )
    }

    const changeSaved = async (id) => {
        if (auth.currentUser === null) {
            // checkSignIn();
            setopenDialog(true);
        }
        else {
            const uid = JSON.parse(localStorage.getItem("user"))['uid'];
            // setpass(!pass);
            db.collection("users").doc(uid).get().then((doc) => {
                saveId = doc.data().saved;
                let arr = [...saveId];
                if (arr.indexOf(id) > -1) {

                    arr = arr.filter(e => e !== id);
                    setsave(false);

                    db.collection("users").doc(uid).update({ saved: arr });

                }
                else {

                    arr.push(id);

                    db.collection("users").doc(uid).update({ saved: arr });
                    setsave(true);
                }
            })
        }
    }

    return (
        <>
            <TileBox>
                <Box onClick={() => onclick(id)}>
                    {/* Image */}
                    <Img src={Image}></Img>

                    <Typo>{Title}</Typo>
                    {/* Author */}
                    <Typox>{Name}</Typox>
                    {/* Rating */}
                </Box>
                <Typox>{read} Reads
                    <span onClick={() => changeSaved(id)}>
                        {save ? <BookImg src={bookmark} /> : <Icon src={saveicon} save={save} />}
                    </span>
                </Typox>
            </TileBox>
            {
                openDialog ? (
                    <SignInDialog
                        open={true}
                        from={"Profile"}
                        setopenDialog={setopenDialog}
                    />
                ) : null
            }
        </>

    )

}

// =====================Styling components=============================

const TileBox = styled.div`
justify-content: center;
margin: 0 12px 25px;
@media (max-width: 815px){
    margin: 0 8px 25px;
};
@media (max-width: 370px){
    margin: 0 4px 25px;
};
`
const Box = styled.div`
    width: 149px;
    margin-bottom: 0vh;
    &:hover{
        cursor: pointer;
    }
`
const Img = styled.img`
    width: 149px;
    height: 198px;
`
const Typo = styled.p`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size:14px;
    font-weight:600;
    font-family:Rubik;
    margin:1px;
    margin-top:10px;
    color:black;
    word-break: break-all;
    cursor: pointer;

`
const Typox = styled(Typo)`
    font-size:12px;
    font-weight:400;
    color:#A6A6A6;
    margin-bottom:3px;
    margin-top: 0vh;
    padding-bottom:3px;
`
const BookImg = styled.img`
    width: 14px;
    float: right;
`

const Icon = styled.img.attrs(props => ({
}))`   
    float: right;   

    `;




export default Tile;