import React, { useEffect } from 'react'
import Navbar from "../layouts/Navbar";
import { Head, Content } from './TermsOfService'
import Footer from '../layouts/Footer';

const PrivacyPolicy = () => {
    useEffect(() => {
        document.title = "Privacy Policy | Kredey | Publish and Read Amazing Documents"
        window.scrollTo(0, 0);
    }, []);
    return (
        <>

            <Navbar />
            <Content>

                <Head>WEBSITE PRIVACY POLICY</Head>
                <p>This privacy policy governs your use of the &nbsp;www.Kredey.com website and the other associated/ancillary products, websites and services (together referred to as the &ldquo;website&rdquo;) managed by Kredey. Kredey values your trust and in order to honour that trust, Kredey adheres to ethical standards in gathering, using, and safeguarding any information you provide.</p>
                <p>&nbsp;</p>
                <p>This privacy policy describes, in detail, the information that shall be collected and recorded by Kredey and how it will be used.</p>
                <p><br></br></p>
                <p>In terms of its privacy statement:</p>
                <p><br></br></p>
                <p>User-Provided Information</p>
                <p><br></br></p>
                <p>The website records the information you provide when you download and register for the Services or products. Kredey may use this information to contact you from time to time, to provide you with the Services, important information, required notices and marketing promotions. Kredey will request you when it needs more information that personally identifies you or allows us to contact you.</p>
                <p><br></br></p>
                <p>Kredey will not differentiate between who is using the device to access the Website or Services or products, so long as the log in/access credentials match with yours. In order to make the best use of the Website and enable your Information to be captured accurately on the Website, it is essential that you have logged in using your own credentials.</p>
                <p><br></br></p>
                <p>Kredey will, at all times, provide the option to you, not to provide Information which it seeks from you. Further, you can, at any time while using the Services/products, also have an option to withdraw your consent given earlier to it, to use such information. Withdrawal of the consent by you is required to be sent in writing to us at the contact details provided in this Policy below. In any such event, Kredey fully reserves the right to withdraw further usage of the Website or provide any further Services/products thereunder to you.</p>
                <p><br></br></p>
                <p><br></br></p>
                <p><br></br></p>
                <p>Automatically Collected Information</p>
                <p><br></br></p>
                <p>In addition, the products/Services may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile devices unique device ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browsers you use, and information about the way you use the Services/products. As is true for most Mobile applications, Kredey also collects other relevant information as per the permissions that you provide.</p>
                <p><br></br></p>
                <p>Kredey uses an outside credit card processing company to bill you for the goods and services availed by you. These companies do not retain, share, store or use personally identifiable information of you for any other purpose.</p>
                <p><br></br></p>
                <p><br></br></p>
                <p><br></br></p>
                <p>Use of your Personal Information</p>
                <p><br></br></p>
                <p>Kredey uses the collected information to analyse trends, to conduct research, to administer the Application/Services and products, to learn about each user&rsquo;s learning patterns and movements around the Application/Services and products and to gather demographic information and usage behaviour about its user base as a whole. The LegaliTea may use the individual data and behaviour patterns combined with personal information to provide you with personalized content, and better your learning objectives/experience.</p>
                <p><br></br></p>
                <p>Aggregated and individual, anonymized and non-anonymized data may periodically be transmitted to external service providers to help us improve the Application, products and its Services. Third parties provide certain services which we may use to analyze the data and information you provide to personalize, drive insights and help it better your experience or reach out to you with more value-added products, information and services. However, these third-party companies do not have any independent right to share this information. THE LEGALITEA does not sell, trade or rent your information to any third party unless we have been expressly authorized by you either in writing or electronically to do so.</p>
                <p><br></br></p>
                <p>Kredey may at times provide aggregate statistics about its customers, traffic patterns, and related site information to reputable third parties, however, this information when disclosed will be in an aggregate form and does not contain any of your Personally Identifiable Information.</p>
                <p><br></br></p>
                <p>Kredey may disclose Information:</p>
                <p>&middot; as required by law, such as to comply with a subpoena or similar legal process;</p>
                <p>&middot; to enforce applicable Terms of Use, including investigation of potential violations thereof;</p>
                <p>&middot; when it believes in good faith (doctrine of uberrimae fides) that the disclosure is necessary to protect its rights, protect your safety or the safety of others, investigate fraud, address security or technical issues or respond to a government request;</p>
                <p>&middot; with its trusted services providers who work on its behalf, do not have an independent use of the information Kredey discloses to them, and have agreed to and adhered to the rules set forth in this Policy;</p>
                <p>&middot; to protect against imminent harm to the rights, property or safety of the Website/ Think and Learn Private Limited or its users or the public as required or permitted by law;</p>
                <p>&middot; with third-party service providers in order to personalize the Website/Services/products for better user experience and to perform behavioural analysis.</p>
                <p><br></br></p>
                <p><br></br></p>
                <p>Access to your Personal Information</p>
                <p><br></br></p>
                <p><br></br></p>
                <p>KREDEY will provide you with the means to ensure that your Personal Information is correct and current. If you have filled out a user profile, it will provide an obvious way for you to access and change your profile from its Application/Services/Website/products. Kredey adopts stringent security measures to protect your password from being exposed or disclosed to anyone. Only upon you forgetting the password for its Website or Services, Kredey will have a right to reset the same to you with your prior approval as per standard web practices.</p>
                <p>Cookies</p>
                <p><br></br></p>
                <p>Kredey send cookies (small files containing a string of characters) to your computer, thereby uniquely identifying your browser. Cookies are used to track your preferences, help you log in faster, and aggregated to determine user trends. This data is used to improve its offerings, such as providing more content in areas of greater interest to a majority of users.</p>
                <p><br></br></p>
                <p>Most browsers are initially set up to accept cookies, but you can reset your browser to refuse all cookies or to indicate when a cookie is being sent.</p>
                <p><br></br></p>
                <p>Disclaimer: Some of Kredey features and services may not function properly if your cookies are disabled.</p>
                <p><br></br></p>
                <p>Links</p>
                <p><br></br></p>
                <p>Kredey may present links in a format that enables us to keep track of whether these links have been followed. Kredey uses this information to improve its customized content. Clicking on links may take you to sites outside its domain. Kredey are not responsible for the privacy practices of other web sites. Kredey encourage its users to be aware when they leave its site to read the EULA/T&amp;Cs of each and every web site that collects personally identifiable information. This Privacy Policy applies solely to information collected by its Website.</p>
                <p><br></br></p>
                <p><br></br></p>
                <p><br></br></p>
                <p>Alerts</p>
                <p><br></br></p>
                <p>Kredey may alert you by email or phone (through sms/call) to inform you about new service offerings or other information which it perceives might be useful for you.</p>
                <p><br></br></p>
                <p>Public Forums</p>
                <p><br></br></p>
                <p>When you use certain features on its website like the discussion forums and you post or share your personal information such as comments, messages, files, photos, will be available to all users, and will be in the public domain. All such sharing of information is done at your own risk. Please keep in mind that if you disclose personal information in your profile or when posting on its forums this information may become publicly available.</p>
                <p><br></br></p>
                <p>Security</p>
                <p><br></br></p>
                <p>Kredey are concerned about safeguarding the confidentiality of your Information. Kredey provide physical, electronic, and procedural safeguards to protect Information it processes and maintain. An illustration being, Kredey limits access to this information to authorized employees only who need to know that information in order to operate, develop or improve its Services/products/Website. Please be aware that, although Kredey endeavour is to provide stringent security, for information it possesses and maintains, no security system can prevent all potential security breaches.</p>
                <p><br></br></p>
                <p>How long do Kredey retain user data?</p>
                <p><br></br></p>
                <p>Currently, Kredey retains user data while an account is active and for at least three years afterwards. It may alter this practice according to legal and business requirements. It may lengthen the retention period for some data if needed to comply with the law or voluntary codes of conduct. Unless otherwise prohibited, it may shorten the retention period for some types of data if needed to free up storage space.</p>
                <p><br></br></p>
                <p>Log information</p>
                <p><br></br></p>
                <p>When you access Kredey Website, its servers automatically record information that your browser sends whenever you visit a website. These server logs may include information such as your web request, internet protocol address, browser type, browser language, the date and time of your request and one or more cookies that may uniquely identify your browser.</p>
                <p><br></br></p>
                <p>User communications</p>
                <p><br></br></p>
                <p>When you send an email or other communication to Kredey, it may retain those communications in order to process your inquiries, respond to your requests and improve our Services.</p>
                <p><br></br></p>
                <p>Changes to this Statement</p>
                <p><br></br></p>
                <p>As the Company evolves, its privacy policy will need to evolve as well to cover new situations. You are advised to review this Policy regularly for any changes, as continued use is deemed approval of all changes.</p>
                <p><br></br></p>
                <p>&nbsp;Your Consent</p>
                <p><br></br></p>
                <p>Kredey believes that every user of our Website must be in a position to provide informed consent prior to providing any Information required for the use of the Website. By registering with it, you are expressly consenting to its collection, processing, storing, disclosing and handling of your information as set forth in this Policy now and as amended by us. Processing, your information in any way, including, but not limited to, collecting, storing, deleting, using, combining, sharing, transferring and disclosing information, all of which activities will take place in India. If you reside outside India your information will be transferred, processed and stored in accordance with the applicable data protection laws of India.</p>
                <p><br></br></p>
                <p>Contact Information</p>
                <p><br></br></p>
                <p><br></br></p>
                <p>Kredey Grievance Team shall undertake all reasonable efforts to address your grievances at the earliest possible opportunity. You may contact it at: hello@kredey.com</p>
                <p>Reach out to us, in case of any queries.</p>
                <p><br></br></p>


            </Content>
            <hr style={{ margin: 0, height: "0.8px" }} />
            <Footer />
        </>
    )
}

export default PrivacyPolicy;