import React, { useState, useEffect } from 'react';
import firebase from "firebase";
import { db } from "../../../firebase";
import Main from './Main';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import NavFront from "./NavFront";
import { Link, useHistory } from "react-router-dom";
import CatIcon from '../../../assets/images/caticon.png'
import { auth } from '../../../firebase';
import Onboardings from "../../layouts/First";
import Search from './Search';
import Grid from '@material-ui/core/Grid';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import { Helmet } from "react-helmet";

const Front = (props) => {
  const user = auth.currentUser;
  if (localStorage.getItem('startexperience') === '1') {
    // window.scrollTo(0, 0);
    localStorage.removeItem('startexperience');
  }
  let uid = 'empty';
  if (user) {
    uid = user.uid;
    // console.log(auth.currentUser.emailVerified, 'emailverify');
  }
  const category = props.location.category;
  const [searchtext, setsearchtext] = useState('');
  const [searchtype, setsearchtype] = useState([]);
  const [navClick, setnavClick] = useState(false);
  const [allposts, setallposts] = useState([]);
  const [exacttitle, setexacttitle] = useState('');
  const [searchtitle, setsearchtitle] = useState([]);
  const [searchClicked, setsearchClicked] = useState(false);
  const history = useHistory();

  const logOut = () => {
    auth.signOut();
    localStorage.removeItem("user");
    history.push("/");
    localStorage.setItem("allowed", "0");
    localStorage.setItem("signup", "0");
  };


  const CategoryList = withStyles((theme) => ({
    root: {
      textAlign: "center",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
    }
  }))(List);

  const CategoryListItemOdd = withStyles((theme) => ({
    root: {
      textDecoration: "none",
      background: "#AFAFAF",
      borderRadius: "0px 100px 100px 0px",
      border: "none",
      width: "137.632px",
      height: "43px",
      marginBottom: "10.725px",
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "17px",
      lineHeight: "21.425px",
      color: "#FFFFFF",
      cursor: "pointer",
      justifyContent: "flex-start",
      '&:hover': {
        background: "#6c6c6c",
      },
      '@media (max-width: 950px)': {
        width: "110px",
        fontSize: "15px",
        // paddingLeft: "8px",
      },
      '@media (max-width: 730px)': {
        width: "125px",
        fontSize: "15px",
      },
    }
  }))(ListItem);
  const CategoryListItemEven = withStyles((theme) => ({
    root: {
      textDecoration: "none",
      background: "#AFAFAF",
      borderRadius: "100px 0px 0px 100px",
      border: "none",
      width: "137.632px",
      height: "43px",
      marginBottom: "10.725px",
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "17px",
      lineHeight: "21.425px",
      color: "#FFFFFF",
      cursor: "pointer",
      justifyContent: "flex-end",
      '&:hover': {
        background: "#6c6c6c",
      },
      '@media (max-width: 950px)': {
        width: "110px",
        fontSize: "15px",
        // paddingRight: "8px",
      },
      '@media (max-width: 730px)': {
        width: "125px",
        fontSize: "15px",
      },
    }
  }))(ListItem);



  const drawerWidth = 300;

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      fontFamily: "Rubik"
    },
    drawer: {
      marginTop: "500px",
      width: drawerWidth,
      flexShrink: 0,
      fontFamily: "Rubik",
      '@media (max-width: 950px)': {
        width: "230px",
      },
      '@media (max-width: 730px)': {
        width: "0px",
      },
    },
    appBar: {
      marginTop: "90px",
      marginLeft: "5px",
      width: "35px",
      height: "35px",
      display: "flex",
      justifyContent: "center",
      right: "0",
      left: "0",
      boxShadow: "none",
      background: "transparent",
      boxShadow: "none",
      backgroundColor: "transparent",
    },
    menuButton: {
      width: "15px",
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    drawerPaper: {
      marginTop: "88px",
      paddingBottom: "90px",
      width: drawerWidth,
      fontFamily: 'Rubik',
      overflowX: "hidden",
      '@media (max-width: 950px)': {
        width: `calc(${drawerWidth}px - 70px)`,
      },
      '@media (max-width: 730px)': {
        marginTop: "0px",
        paddingTop: "10px",
        paddingBottom: "0px",
        width: `calc(${drawerWidth}px - 30px)`,
      },

    },
    content: {
      marginTop: "85px",
      fontFamily: 'Rubik',
      flexGrow: 1,
      padding: "24px 24px 24px 24px",
      '@media (max-width: 797px)': {
        padding: "24px 0px 24px 0px",
      },
      '@media (max-width: 395px)': {
        padding: "24px 0px 24px 0px",
      },
    },
    link: {
      textDecoration: 'none',
      color: "black",
      cursor: "pointer",
      '&:hover': {
        textDecoration: 'none',
      },
      '@media (max-width: 950px)': {
        fontSize: "12.8px",
      },
    },
    footer: {
      margin: "214.5px 0px 30px 0px",
      paddingLeft: "24px",
      fontSize: "13px",
      '@media (max-width: 950px)': {
        paddingLeft: "16px",
        fontSize: "12px",
      },
    },
    copyright: {
      textAlign: "center",
      fontSize: "13px",
      '@media (max-width: 950px)': {
        fontSize: "11px",
      },
    }
  }));

  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // useEffect(() => {
  //   document.title = "Home | Home | Home | Kredey | Publish and Read Amazing Documents"
  // }, []);


  const drawer = (
    <div>
      <div><h5 style={{ margin: "20px 0 10px 10px" }}>Categories</h5></div>

      <CategoryList>

        <Link to={{ pathname: '/home', category: 'Geography' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemOdd>Geography</CategoryListItemOdd>
        </Link>

        <Link to={{ pathname: '/home', category: 'Physics' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemEven>Physics</CategoryListItemEven>
        </Link>

        <Link to={{ pathname: '/home', category: 'History' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemOdd>History</CategoryListItemOdd>
        </Link>

        <Link to={{ pathname: '/home', category: 'Spirituality' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemEven>Spirituality</CategoryListItemEven>
        </Link>

        <Link to={{ pathname: '/home', category: 'Engineering' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemOdd>Engineering</CategoryListItemOdd>
        </Link>

        <Link to={{ pathname: '/home', category: 'Psychology' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemEven>Psychology</CategoryListItemEven>
        </Link>

        <Link to={{ pathname: '/home', category: 'Economics' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemOdd>Economics</CategoryListItemOdd>
        </Link>

        <Link to={{ pathname: '/home', category: 'Parenting' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemEven>Parenting</CategoryListItemEven>
        </Link>

        <Link to={{ pathname: '/home', category: 'Chemistry' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemOdd>Chemistry</CategoryListItemOdd>
        </Link>

        <Link to={{ pathname: '/home', category: 'Business' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemEven>Business</CategoryListItemEven>
        </Link>

        <Link to={{ pathname: '/home', category: 'Mystery' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemOdd>Mystery</CategoryListItemOdd>
        </Link>

        <Link to={{ pathname: '/home', category: 'Biology' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemEven>Biology</CategoryListItemEven>
        </Link>

        <Link to={{ pathname: '/home', category: 'Sociology' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemOdd>Sociology</CategoryListItemOdd>
        </Link>

        <Link to={{ pathname: '/home', category: 'Philosophy' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemEven>Philosophy</CategoryListItemEven>
        </Link>

        <Link to={{ pathname: '/home', category: 'Commerce' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemOdd>Commerce</CategoryListItemOdd>
        </Link>

        <Link to={{ pathname: '/home', category: 'Maths' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemEven>Maths</CategoryListItemEven>
        </Link>

        <Link to={{ pathname: '/home', category: 'Statistics' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemOdd>Statistics</CategoryListItemOdd>
        </Link>

        <Link to={{ pathname: '/home', category: 'Lifestyle' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemEven>Lifestyle </CategoryListItemEven>
        </Link>

        <Link to={{ pathname: '/home', category: 'Theater' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemOdd>Theater</CategoryListItemOdd>
        </Link>

        <Link to={{ pathname: '/home', category: 'Design' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemEven>Design</CategoryListItemEven>
        </Link>

      </CategoryList>

      <div style={{ textAlign: "center", fontSize: "13px" }}>
        <Link to="/all" className={classes.link} style={{ cursor: "pointer" }}>All Categories<ChevronRightRoundedIcon style={{ fontSize: "18px" }} /></Link>
      </div>

      <div className={classes.footer}>
        <Grid container spacing={1} >
          <Grid item xs={6}>
            <Link
              to="/homepage"
              // onClick={() => { logOut() }} 
              className={classes.link} style={{ cursor: "pointer" }}><text>Home Page</text></Link>
          </Grid>
          <Grid item xs={6}>
            <Link to="/aboutus" className={classes.link} style={{ cursor: "pointer" }}><text>About Us</text></Link>
          </Grid>
          <Grid item xs={6}>
            <Link to="/terms" className={classes.link} style={{ cursor: "pointer" }}><text>Terms of Service</text></Link>
          </Grid>
          <Grid item xs={6}>
            <Link to="/faq" className={classes.link} style={{ cursor: "pointer" }}><text>FAQ’s</text></Link>
          </Grid>
          <Grid item xs={6}>
            <Link to="/contact" className={classes.link} style={{ cursor: "pointer" }}><text>Contact</text></Link>
          </Grid>
          <Grid item xs={6}>
            <Link to="/privacy" className={classes.link} style={{ cursor: "pointer" }}><text>Privacy Policy</text></Link>
          </Grid>

        </Grid>
      </div>
      <div className={classes.copyright}>
        Copyright &#169;2022 Kredey
      </div>
    </div >
  );



  return (
    <React.Fragment>


      <Helmet>

        {/* <!-- HTML Meta Tags --> */}
        <title>Home | Kredey | Publish and Read Amazing Documents</title>
        <meta name="description" content="Kredey Folio is a platform where people, can read documents to learn new things and publishers, can share their work and reach millions of readers" />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Home | Kredey | Publish and Read Amazing Documents" />
        <meta itemprop="description" content="Kredey Folio is a platform where people, can read documents to learn new things and publishers, can share their work and reach millions of readers" />
        <meta itemprop="image" content="" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://www.kredey.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Home | Kredey | Publish and Read Amazing Documents" />
        <meta property="og:description" content="Kredey Folio is a platform where people, can read documents to learn new things and publishers, can share their work and reach millions of readers" />
        <meta property="og:image" content="" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Home | Kredey | Publish and Read Amazing Documents" />
        <meta name="twitter:description" content="Kredey Folio is a platform where people, can read documents to learn new things and publishers, can share their work and reach millions of readers" />
        <meta name="twitter:image" content="" />

      </Helmet>

      {localStorage.getItem('signup') === '1' || localStorage.getItem(uid) === 'firsttime' ? <Onboardings uid={uid} /> : null}
      <div>
        <NavFront setnavClick={setnavClick} setsearchClicked={setsearchClicked} setexacttitle={setexacttitle} searchtitle={searchtitle} setsearchtitle={setsearchtitle} user={user} searchtext={searchtext} setsearchtext={setsearchtext} setsearchtype={setsearchtype} />
        {!searchClicked && searchtext !== '' ? <Search exacttitle={exacttitle} searchtitle={searchtitle} setsearchClicked={setsearchClicked} searchtext={searchtext} searchtype={searchtype} /> : null}

      </div>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}>

          <img src={CatIcon} alt=""
            style={{ filter: "drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.1))" }}
            className={classes.menuButton}
            onClick={handleDrawerToggle}
          />

        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              // container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, //Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <Main setallposts={setallposts} exacttitle={exacttitle} searchClicked={searchClicked} category={category} searchtitle={searchtitle} user={user} navClick={navClick} searchtext={searchtext} setnavClick={setnavClick} />
        </main>
      </div>
    </React.Fragment>
  );
}


export default Front;
