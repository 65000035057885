import React, { useState, useEffect, forwardRef, useRef } from "react";
import {
  Button,
  Input,
  Card,
  CardContent,
  Typography,
  withStyles,
  TextareaAutosize
} from "@material-ui/core";
import { Link } from "react-router-dom";
import FlipMove from "react-flip-move";
import emoji from "../../assets/emoji.svg";
import send from "../../assets/send.svg";
import Image from "../../assets/z.jfif";
import styled from "styled-components";
import "../../PublicChat.css";
import upvote from "../../assets/upvote.svg";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import CloseIcon from "@material-ui/icons/Close";
import { db, auth } from "../../firebase";
import user2 from "../../assets/user.png";


const CardBody = withStyles((theme) => ({
  root: {
    padding: "3px 7px",
    paddingBottom: 0,
    whiteSpace: "pre-line",
    '&:last-child': {
      paddingBottom: "0px",
    },
    '@media (max-width: 950px)': {
      maxWidth: "28.5vw",
    },
    '@media (max-width: 815px)': {
      maxWidth: "58.6vw",
    },
  }
}))(CardContent);

const ReplyDiv = withStyles((theme) => ({
  root: {
    padding: "0px",
    maxWidth: "20vw",
    whiteSpace: "pre-line",
    '&:last-child': {
      paddingBottom: "14px",
    },
    '@media (max-width: 950px)': {
      maxWidth: "30vw",
    },
    '@media (max-width: 815px)': {
      maxWidth: "50vw",
    },
  }
}))(CardContent);

// const MsgInput = withStyles((theme) => ({
//   root: {
//     width: "20vw",
//     height: "45px",
//     marginLeft: "1vw",
//     border: "none",
//     whiteSpace: "pre-line",
//     '@media (max-width: 815px)': {
//       width: "79vw",
//     },
//   }
// }))(TextareaAutosize);


const Message = forwardRef(
  ({
    message,
    setMessage,
    setsendButtonPressed,
    setplaceholder,
    setclickreplymessageID,
    setreplyClicked,
    id,
    uid,
    name,
    upvote_Counting,
    upvote_Counts,
    chatid,
    upvotedBy,
    profile_pic,
    View,
    date,
    view_prof
  }) => {
    const replyClick = () => {
      setclickreplymessageID(chatid);
      setreplyClicked(true);
      setplaceholder(`replying to ${name}...`)
      setMessage("");
      setsendButtonPressed(false);
      View();
    };
    const [profPic, setprofPic] = useState(null);
    db
      .collection("users")
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          if (doc.data().profilePic === "")
            setprofPic(user2);
          else
            setprofPic(doc.data().profilePic);
        }
      });
    // console.log(id, uid, "iduid", name, message, uid === "", id === "");
    return (
      <>
        <div className="messageCard">
          {id !== uid || uid === "" ? <Avatar pic={profPic} onClick={() => view_prof(id)} /> : null}
          <div
            className={id === uid && uid !== "" ? "userCard" : "couserCard"}
            style={{
              position: "relative",
            }}
          >
            <CardBody>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <Typography
                    style={{
                      fontSize: "13px",
                      textAlign: "left",
                      color: "#5DB0D8",
                      cursor: "pointer"
                    }}
                    onClick={() => view_prof(id)}
                  >
                    {id === uid && uid !== "" ? "You" : name}
                  </Typography>
                </div>
                <div
                  style={{
                    marginLeft: "auto",
                    marginRight: "0px",
                    overflow: "auto",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "11px",
                      textAlign: "right",
                      color: "#A6A6A6",
                      cursor: "default"
                      // marginTop: "0.1vh",
                    }}
                  >
                    {date}
                  </Typography>
                </div>
              </div>
              <Typography
                color="white"
                style={{
                  fontSize: "15px",
                  fontWeight: "400",
                  marginTop: "0vh",
                  cursor: "default",
                  overflowWrap: "break-word",
                }}
              >
                {/* The below line makes the user's own texts appear without a username */}
                {message}
              </Typography>
            </CardBody>

          </div >
        </div >

        {
          uid !== id || uid === "" ? (
            <div className="details">
              <p>
                <a style={{ fontSize: "12px", paddingTop: "1px", cursor: "default" }}>{upvote_Counts}</a>
                <img
                  src={upvote}
                  alt=""
                  onClick={() => upvote_Counting(chatid, uid, upvotedBy)}
                  style={{ height: "13px", cursor: "pointer" }}
                />
                <Link
                  onClick={replyClick}
                  style={{
                    color: "black",
                    textDecoration: "none",
                    cursor: "pointer",
                    fontSize: "12px",
                  }}
                >
                  Reply
                </Link>
              </p>
            </div>
          ) : (
            <div className="details">
              <p style={{ marginRight: "8px" }}>
                <a style={{ fontSize: "12px", paddingTop: "1px" }}>{upvote_Counts}</a>
                <img
                  src={upvote}
                  onClick={() => upvote_Counting(chatid, uid, upvotedBy)}
                  alt=""
                />
              </p>
            </div>
          )
        }
      </>

    );
  }
);

const Reply = forwardRef(
  ({
    message,
    id,
    uid,
    cid,
    cha_id,
    date,
    cname,
    setMessage,
    setsendButtonPressed,
    setplaceholder,
    setclickreplymessageID,
    setreplyClicked,
    View,
    view_prof
  }) => {
    const [name, setname] = useState("");
    db.collection("users")
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setname(doc.data().name);
        }
      });
    return (
      <div style={{ paddingTop: "0vh" }}>
        <ReplyDiv >

          <div
            style={{
              background: "white",
              padding: `${cid !== uid ? "5px 12px 5px 7px" : "5px 7px 5px 12px"}`,
              border: "1px solid black",
              borderBottomRightRadius: `${cid !== uid ? "20px" : "0px"}`,
              borderBottomLeftRadius: `${cid !== uid ? "0px" : "20px"}`,
              marginLeft: `${cid !== uid ? "10px" : "0px"}`,
              marginRight: `${cid !== uid ? "0px" : "10px"}`
            }}>



            <div style={{ display: "flex", alignItems: "center" }}>
              {cid !== uid ? (
                <div>
                  <Typography
                    style={{
                      fontSize: "13px",
                      textAlign: "left",
                      color: "#5DB0D8",
                    }}
                    onClick={() => view_prof(id)}
                  >
                    {id === uid ? "You" : name}
                  </Typography>
                </div>
              ) : (
                <div>
                  <Typography
                    style={{
                      fontSize: "13px",
                      color: "#5DB0D8",
                      textAlign: "left"
                    }}
                  >
                    {id === uid ? "You" : name}
                  </Typography>
                </div>
              )}
              {cid !== uid ? (
                <div
                  style={{
                    overflow: "auto",
                    marginRight: "0px",
                    marginLeft: "auto",
                    cursor: "default"
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "11px",
                      color: "#A6A6A6",
                      cursor: "default"
                    }}
                  >
                    {date}
                  </Typography>
                </div>
              ) : (
                <div style={{ overflow: "auto", marginLeft: "auto" }}>
                  <Typography
                    style={{
                      fontSize: "11px",
                      color: "#A6A6A6",
                      cursor: "default"
                    }}
                  >
                    {date}
                  </Typography>
                </div>
              )}
            </div>
            {cid !== uid ? (
              <Typography
                color="white"
                style={{
                  fontSize: "15px",
                  fontWeight: "400",
                  marginTop: "1px",
                  wordWrap: "break-word",
                }}
              >
                {/* The below line makes the user's own texts appear without a username */}
                {message}
              </Typography>
            ) : (
              <Typography
                color="white"
                style={{
                  fontSize: "15px",
                  fontWeight: "400",
                  marginTop: "1px",
                  textAlign: "left",
                  wordWrap: "break-word",
                  cursor: "default"
                }}
              >
                {/* The below line makes the user's own texts appear without a username */}
                {message}
              </Typography>
            )}
          </div>
        </ReplyDiv>

      </div>
    );
  }
);

export default function PublicChat({ View, view_prof }) {
  const [replyClicked, setreplyClicked] = useState(false);
  const [clickreplymessageID, setclickreplymessageID] = useState(null);
  const [placeholder, setplaceholder] = useState("type here...");
  const [sendButtonPressed, setsendButtonPressed] = useState(false);
  const [currentmessage, setcurrentmessage] = useState("");
  const [upvoteid, setupvoteid] = useState();
  const postid = JSON.parse(localStorage.getItem("postid"))["postid"];
  // const uid = JSON.parse(localStorage.getItem("user"))["uid"];
  let uid = "";
  if (localStorage.getItem("allowed") === "1") {
    uid = JSON.parse(localStorage.getItem("user"))["uid"];
  }
  // console.log(uid, "duidknf");
  let name = "";

  if (auth.currentUser) {
    db.collection("users")
      .doc(uid)
      .get()
      .then((doc) => {
        name = doc.data().name;
      });

  }

  //const [chat, setchat] = useState([{message: 'how are you', photog: true}, {message: 'i am fine', photog: false}, {message: 'fdijgbueffienriernuignierngnergnfdgubgbtugbtrfdijgbueffienriernuignierngnergnfdgubgbtugbtr', photog: true}, {message: 'what is this', photog: false}, {message: 'what is this', photog: false}, {message: 'what is this', photog: false}, {message: 'what is this', photog: false}, {message: 'what is this', photog: false}]);
  const [message, setMessage] = useState(null);
  const [chat, setChat] = useState([]);

  const [replies, setReplies] = useState(null);
  var promises = [];
  // const [chatStarted, setChatStarted] = useState(false)
  var conversations = [];
  const chatting = (e) => {
    setMessage(e.target.value);
  };

  let to_count_upvotes = () => { };
  if (localStorage.getItem("allowed") === "1") {
    to_count_upvotes = async (chatid, uid, upvotedBy) => {
      // console.log(chatid, "This chat is upvoted by", uid);
      let arr = [...upvotedBy];
      if (arr.indexOf(uid) > -1) {
        arr = arr.filter((e) => e !== uid);
        await db
          .collection("posts")
          .doc(postid)
          .collection("conversations")
          .doc(chatid)
          .update({ upvote: arr });
        await db
          .collection("posts")
          .doc(postid)
          .collection("conversations")
          .doc(chatid)
          .update({ upvote_Count: arr.length });
      } else {
        arr.push(uid);
        await db
          .collection("posts")
          .doc(postid)
          .collection("conversations")
          .doc(chatid)
          .update({ upvote: arr });
        await db
          .collection("posts")
          .doc(postid)
          .collection("conversations")
          .doc(chatid)
          .update({ upvote_Count: arr.length });
      }
    };
  }
  const handleKeyDown = (event, x, y) => {
    if (event.shiftKey && event.keyCode == 13) {
      submitMessage(x, y);
    }
  };

  const submitMessage = (x, y) => {

    var txt = document.getElementById('msgarea').value;
    txt = txt.replace(/(\r\n|\n|\r)/gm, "");
    // console.log(txt);
    // console.log(txt.length);
    if (txt.length > 0) {
      if (message !== "") {
        setemojiselected(false);
        if (x) {
          // console.log(y);
          db.collection("posts")
            .doc(postid)
            .collection("conversations")
            .doc(y)
            .collection("replies")
            .add({
              message,
              uid,
              createdAt: new Date(),
            });
          setMessage(null);
          setTimeout(() => {
            setMessage("");
          }, 500);
          setreplyClicked(false);
          setplaceholder("type here...");
          setclickreplymessageID(null);
          //   setReplies({});
        } else {
          db.collection("posts").doc(postid).collection("conversations").add({
            message,
            uid,
            name,
            createdAt: new Date(),
            upvote: [],
            upvote_Count: 0,
          });

          // setMessage("");
          const abc = document.getElementById("messages");

          setTimeout(() => {
            abc.scrollTo(0, abc.scrollHeight);
            setMessage("");
          }, 500);

        }
      }
    }



  };
  const fetchconvo = async () => {
    let replys = {};

    db.collection("posts")
      .doc(postid)
      .collection("conversations")
      .orderBy("upvote_Count", "desc")
      .orderBy("createdAt", "asc")
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const chatid = doc.id;
          db.collection("posts")
            .doc(postid)
            .collection("conversations")
            .doc(chatid)
            .collection("replies")
            .orderBy("createdAt", "asc")
            .onSnapshot((querySnapshot) => {
              replys[chatid] = [];
              querySnapshot.forEach((doc) => {
                replys[chatid].push(doc.data());
              });
            });
          // console.log(replys);
          var data = doc.data();
          data = { ...data, chat_id: chatid };
          conversations.push(data);
        });

        setChat(conversations);
        setReplies(replys);
        // setTimeout(() => {
        //   setMessage("");
        // }, 1000);
        conversations = [];
      });
  };
  useEffect(() => {
    fetchconvo();
  }, [postid]);

  const [emojiselected, setemojiselected] = useState(false);
  const addEmoji = (e) => {
    let emoji = e.native;
    let newmessage = message + emoji;
    setMessage(newmessage);
  };


  return (
    <div>
      <div className="messages" id="messages">
        <FlipMove className="message" style={{ position: "relative" }}>
          {chat &&
            chat.map((c) => (
              <div align={c.uid === uid && uid !== "" ? "right" : "left"}>
                <Message
                  setsendButtonPressed={setsendButtonPressed}
                  message={c.message}
                  setMessage={setMessage}
                  setplaceholder={setplaceholder}
                  setclickreplymessageID={setclickreplymessageID}
                  setreplyClicked={setreplyClicked}
                  name={c.name}
                  view_prof={view_prof}
                  id={c.uid}
                  uid={uid}
                  date={
                    (c.createdAt.toDate().getMonth() + 1) +
                    "/" +
                    c.createdAt.toDate().getDate() +
                    "/" +
                    c.createdAt.toDate().getFullYear()
                  }
                  chatid={c.chat_id}
                  upvotedBy={c.upvote}
                  upvote_Counts={c.upvote_Count}
                  upvote_Counting={to_count_upvotes}
                  View={View}
                />
                {/* {replies && replies[c.chat_id] && replies[c.chat_id].length ? (
                  <></>
                ) : null} */}
                {replies &&
                  Object.keys(replies).map((key, i) =>
                    key === c.chat_id
                      ? replies[c.chat_id].map((reply) => (
                        <div key={key}>
                          <Reply
                            message={reply.message}
                            id={reply.uid}
                            cid={c.uid}
                            cha_id={c.chat_id}
                            uid={uid}
                            key={key}
                            cname={c.name}
                            view_prof={view_prof}
                            setclickreplymessageID={setclickreplymessageID}
                            setreplyClicked={setreplyClicked}
                            setsendButtonPressed={setsendButtonPressed}
                            setMessage={setMessage}
                            setplaceholder={setplaceholder}
                            View={View}
                            date={
                              (reply.createdAt.toDate().getMonth() + 1) +
                              "/" +
                              reply.createdAt.toDate().getDate() +
                              "/" +
                              reply.createdAt.toDate().getFullYear()
                            }
                          />
                          {/* {c.uid!==uid ? <hr
                              style={{
                                marginLeft: "4.5vw",
                                marginRight: "3vw",
                                marginTop: "0vh",
                                height: "0.05vh",
                                color: "#cbcdd0",
                              }}
                            /> : <hr
                            style={{
                              marginLeft: "2vw",
                              marginRight: "1vw",
                              marginTop: "0vh",
                              height: "0.05vh",
                              color: "#cbcdd0",
                            }}
                          />} */}
                          {/* {c.uid!==uid && reply.uid!==uid ? <p onClick={(e) => getName(c.name, reply.uid)} style={{fontSize: '12px', marginTop: '-2vh', marginLeft: '23.5vw'}}>Reply</p> : null} */}
                        </div>
                      ))
                      : null
                  )}
              </div>
            ))}
        </FlipMove>
      </div>
      <div className="send-message">
        {emojiselected ? (
          <div className="picker-container">
            <Picker
              onSelect={addEmoji}
              title=""
              showPreview={false}
              showSkinTones={false}
              emoji=""
              onSelect={addEmoji}
              style={{
                // width: "91%",
                // height: "367px",
                // marginLeft: "0.5vw",
                width: "82%",
                height: "367px",
                marginLeft: "9%",
              }}
            />
          </div>
        ) : null}
        {localStorage.getItem("allowed") === "1" ? (
          <div className="send">
            {!emojiselected ? (
              <img
                src={emoji}
                alt=""
                style={{ height: "24px" }}
                onClick={(e) => setemojiselected(!emojiselected)}
              />
            ) : (
              <CloseIcon
                onClick={(e) => setemojiselected(!emojiselected)}
                style={{
                  // marginTop: "0.6vh",
                  // height: "25px",
                  cursor: "pointer",
                  // marginLeft: "-0.1vw",
                }}
              />
            )}
            <TextareaAutosize
              id="msgarea"
              placeholder={placeholder}
              value={message}
              onChange={chatting}
              onKeyDown={(e) =>
                handleKeyDown(e, replyClicked, clickreplymessageID)
              }
              maxRows={3}
              aria-label="maximum height"
              className="textarea"
            ></TextareaAutosize>

          </div>
        ) : (
          <div
            style={{ borderTop: "1px solid", width: "100%" }}
          >
            <p style={{ fontFamily: "Rubik", fontSize: "16px", textAlign: "center", paddingTop: "10px", margin: 0 }}>
              <a
                href="/signin"
                style={{
                  color: "blue",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
              >
                Sign In
              </a>{" "}
              to pariticipate in the discussion
            </p>
          </div>
        )}
        {localStorage.getItem("allowed") === "1" ? (
          <button style={{ border: "none", backgroundColor: "transparent" }}>
            <img
              src={send}
              alt=""
              // style={{ marginLeft: "26.5vw" }}
              onClick={() => submitMessage(replyClicked, clickreplymessageID)}
            // onClick={(e) => handleKeyDown(e, replyClicked, clickreplymessageID)}
            />
          </button>
        ) : null}
      </div>
    </div>
  );
}

const Avatar = styled.img.attrs((props) => ({
  src: props.pic,
}))`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin-right: 6px;
  cursor: pointer;
`;

const Name = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 2vw;
`;

const ReplyLink = styled.a`
color: black;
font-size: 12px;
margin-left: 15vw;
text-decoration: none;
cursor: pointer;
font-size: 12px;
&:hover{
text-decoration: none;
}
@media (max-width: 950px){
  margin-left: 22vw;
};
@media (max-width: 815px){
  margin-left: 77.5%;
};
`;