import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { db, auth } from '../../../firebase';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from "react-router";
import user2 from "../../../assets/user.png";



const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
    cursor: "pointer",
  },
}));

const ItemSaved = ({
  Image,
  title,
  name,
  onclick,
  id,
  Setpost,
  setsavedId,
  postedby
}) => {
  let saveId = [];
  const uid = auth.currentUser.uid;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [profId, setprofId] = useState(null);
  const [profPic, setprofPic] = useState(null);


  useEffect(() => {
    db
      .collection("users")
      .doc(postedby)
      .get()
      .then((doc) => {
        if (doc.exists) {
          // console.log(doc.data());
          setprofId(doc.id);
          if (doc.data().profilePic === "")
            setprofPic(user2);
          else
            setprofPic(doc.data().profilePic);
        }
      });
  }, [postedby])


  let history = useHistory();
  function Profile(y) {
    // localStorage.setItem(
    //   "profileid",
    //   JSON.stringify({ profileid: y })
    // );
    // console.log(y)
    history.push(`/user/${y}`);
  }


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id2 = open ? 'simple-popover' : undefined;
  const removeSaved = () => {
    db.collection("users").doc(uid).get().then((doc) => {
      saveId = doc.data().saved;
      let arr = [...saveId];
      arr = arr.filter(e => e !== id);
      db.collection("users").doc(uid).update({ saved: arr }).then(() => {
        var temparr = [];
        var promises = [];
        var res = db.collection("users").doc(uid);
        res.get().then((doc) => {
          var i = 0;
          var arr = doc.data().saved;
          setsavedId(doc.data().saved);
          for (i = 0; i < arr.length; i++) {
            promises.push(myFunc(arr[i]));
          }
          Promise.all(promises).then(() => {
            // console.log(temparr);
            Setpost(temparr);
          });
          function myFunc(item) {
            return new Promise((resolve) => {
              var docRef = db.collection("posts").doc(item);
              docRef.get().then((doc) => {
                var data = doc.data();

                data = { ...data, id: item };
                temparr.push(data);
                // console.log(data);
                resolve(temparr);
              });
            });
          }
        });
      });

    });
    handleClose();
  }


  return (
    <>

      <Item>
        {/* Image */}

        <div onClick={() => onclick(id)}>
          <Imagex src={Image} alt="oops nothing to show" />
        </div>

        <Body>

          <div style={{ display: 'flex' }}>
            <Title onClick={() => onclick(id)}>{title}</Title>

            <span style={{ marginLeft: "auto" }}>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>

              <Popover
                id={id2}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Typography onClick={removeSaved} className={classes.typography}>Remove from saved</Typography>
              </Popover>
            </span>
          </div>

          <div style={{ display: 'flex', alignItems: "center" }}>
            <img src={profPic} style={{ height: '27px', width: '27px', borderRadius: '50%', cursor: "pointer" }} onClick={() => { Profile(profId) }} alt="" />
            <Name onClick={() => { Profile(profId) }}>{name}</Name>
          </div>
        </Body>


      </Item>
    </>
  )
}
const Item = styled.div`
    display:flex;
    flex-direction:row;
    margin-top: 20px;
    width: 730px;
    margin-left: 35px;
    @media (max-width: 1115px){
        width: 600px;
    };
    @media (max-width: 985px){
    margin-left: 0px;
    };
    @media (max-width: 950px){
        width: 450px;
    margin-left: 35px;
    };
    @media (max-width: 763px){
        margin-left: 0px;
      };
    @media (max-width: 500px){
        width: 320px;
    };
`

const Imagex = styled.img`
    width: 149px;
    height: 198px;
    cursor: pointer;
    @media (max-width: 950px){
        width: 130px;
        height: 172.75px;
    };
    @media (max-width: 500px){
        width: 105px;
        height: 140px;
    };
`

const Body = styled.div`
width: 100%;
margin-left: 15px;
`
const Title = styled.p`
    font-size:20px;
    width: 90%;
    margin-bottom: 10px;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    cursor: pointer;
    @media (max-width: 950px){
    font-size:18px;
    };
    @media (max-width: 500px){
    font-size:16px;
    };
`
const Name = styled.p`
    margin: 0;
    font-family:Rubik;
    font-size:14px;
    padding-left: 8px;
    cursor: pointer;
    @media (max-width: 950px){
        font-size:13px;
    };
    @media (max-width: 500px){
        font-size:12px;
    };
`
export default ItemSaved