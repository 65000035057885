import React, { useState, useEffect } from "react"
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useMediaQuery } from "@material-ui/core";

// Plugins
import { ScrollMode } from "@react-pdf-viewer/scroll-mode";
import { auth, db } from '../../../firebase';
import {
  SelectionMode,
} from "@react-pdf-viewer/selection-mode";
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';


// Create new plugin instance
const FullScreen = () => {
  const [read, setread] = useState();
  const url = window.location.href;
  const pdf_t = 'https://' + url.substring(url.indexOf('firebase'));
  let postid = '';
  postid = url.substring(url.indexOf('firebase') - 21, url.indexOf('firebase') - 1);
  var docRef = db.collection("posts").doc(postid);
  useEffect(() => {
    docRef.get().then(
      doc => {
        setread(doc.data().read_By);
      }
    );
  }, [])


  const ToRead = async (c_page, t_page) => {
    if (auth.currentUser) {
      c_page = c_page + 1;
      const uid = JSON.parse(localStorage.getItem("user"))['uid'];
      // console.log(read);
      // console.log(c_page);
      // console.log(t_page);
      if (c_page >= Math.round(t_page / 2) || t_page === 1) {
        let arr = [...read];
        if (arr.indexOf(uid) === -1) {
          arr.push(uid);
          await db.collection("posts").doc(postid).update({ read_By: arr });
          await db.collection("posts").doc(postid).update({ read_Count: arr.length });
        }
      }


    }
  }

  const isMobile = useMediaQuery((theme) => theme.breakpoints.up("xs"));

  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          SwitchScrollMode,
          ShowSearchPopover,
          GoToFirstPage,
          GoToLastPage,
          Zoom,
          SwitchSelectionMode,
          ZoomIn,
          ZoomOut
        } = slots;
        return (
          <>
            {!isMobile ? (
              <>
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    overflow: "hidden",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  <div style={{ display: "flex" }}>

                    <ZoomOut />
                    <ZoomIn />
                  </div>

                  <div style={{ padding: "0px 2px", fontWeight: "normal" }}>
                    <ShowSearchPopover style={{ fontWeight: "normal" }} />
                  </div>


                  <div style={{ display: "flex" }}>
                    <div style={{ marginLeft: "auto" }}>
                      <GoToFirstPage />
                    </div>
                    <div style={{ marginLeft: "auto" }}>
                      <GoToPreviousPage />
                    </div>

                    <div style={{ display: 'flex', alignItems: "center", padding: "0 5px" }}>
                      <div style={{ width: '47px' }}><CurrentPageInput /> </div>
                      &nbsp;/&nbsp;<NumberOfPages />
                    </div>

                    <div>
                      <GoToNextPage />
                    </div>

                    <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                      <GoToLastPage />
                    </div>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <SwitchScrollMode mode={ScrollMode.Horizontal} />
                    </div>
                    <div>
                      <SwitchScrollMode mode={ScrollMode.Wrapped} />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    overflow: "hidden",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  {/* <div style={{ padding: "0px 2px" }}>
              <ShowSearchPopover />
            </div> */}
                  <div style={{ padding: "0px 2px" }}>
                    <ZoomOut />
                  </div>
                  <div style={{ padding: "0px 2px" }}>
                    <Zoom />
                  </div>
                  <div style={{ padding: "0px 2px" }}>
                    <ZoomIn />
                  </div>
                  <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                    <GoToFirstPage />
                  </div>
                  <div style={{ padding: "0px 2px", }}>
                    <GoToPreviousPage />
                  </div>

                  <div style={{ display: 'flex', alignItems: "center", padding: "0px 2px" }}>
                    <div style={{ width: '60px' }}><CurrentPageInput /> </div>
                    &nbsp;/&nbsp;<NumberOfPages />
                  </div>


                  <div style={{ padding: "0px 2px" }}>
                    <GoToNextPage />
                  </div>
                  <div style={{ padding: "0px 2px" }}>
                    <ShowSearchPopover />
                  </div>
                  <div style={{ padding: "0px 2px" }}>
                    <GoToLastPage />
                  </div>
                  <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                    <SwitchScrollMode mode={ScrollMode.Horizontal} />
                  </div>
                  <div style={{ padding: "0px 2px" }}>
                    <SwitchScrollMode mode={ScrollMode.Wrapped} />
                  </div>
                  <div style={{ padding: "0px 2px" }}>
                    <SwitchSelectionMode mode={SelectionMode.Text} />
                  </div>
                  <div style={{ padding: "0px 2px" }}>
                    <SwitchSelectionMode mode={SelectionMode.Hand} />
                  </div>
                </div>
              </>
            )
            }
          </>
        );
      }}
    </Toolbar>

  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: defaultTabs => [
      defaultTabs[1], // Bookmarks tab
      defaultTabs[2], // Attachment tab
    ],
    toolbarPlugin: {
      scrollModePlugin: {
        scrollMode: ScrollMode.Horizontal
      },
    },

  });
  const fullScreenPluginInstance = fullScreenPlugin();
  const { EnterFullScreen } = fullScreenPluginInstance;
  return (
    <div
      style={{
        overflow: "hidden",
        boxSizing: "border-box",
        height: '100vh',
      }}
    >
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
        {pdf_t ?
          <Viewer
            onPageChange={(e) => ToRead(e.currentPage, e.doc.numPages)}
            // defaultScale={SpecialZoomLevel.PageFit}
            defaultScale={0.8}
            fileUrl={pdf_t}
            plugins={[defaultLayoutPluginInstance, fullScreenPluginInstance]}
          /> : null

        }
      </Worker>
    </div>
  );
};


export default FullScreen;
