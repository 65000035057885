import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory } from "react-router";
import bookmark from '../../../assets/bookmark.png';
import saveicon from "../../../assets/save.svg";
import user2 from "../../../assets/user.png";
import { auth, db } from '../../../firebase';
const SearchFolio = ({
    Image,
    title,
    name,
    onclick,
    id,
    postedby,
    desc,
    setnavClick
}) => {
    const [save, setsave] = useState(false);
    const [profPic, setprofPic] = useState(null);
    const [profId, setprofId] = useState(null);

    var saveId = [];
    let uid = '';
    if (localStorage.getItem('allowed') === '1' && auth.currentUser) {
        uid = auth.currentUser.uid;
        const userRef = db.collection('users').doc(uid);
        userRef.get().then(
            doc => {
                if (doc.data().saved.includes(id)) {
                    setsave(true);
                }
            }
        )
    }
    const checkSignIn = () => {
        if (auth.currentUser === null) {
            setnavClick(true);
        }
    }
    const changeSaved = async (id) => {
        if (auth.currentUser === null) {
            checkSignIn();
        }
        else {
            const uid = JSON.parse(localStorage.getItem("user"))['uid'];
            db.collection("users").doc(uid).get().then((doc) => {
                saveId = doc.data().saved;
                let arr = [...saveId];
                if (arr.indexOf(id) > -1) {

                    arr = arr.filter(e => e !== id);
                    setsave(false);

                    db.collection("users").doc(uid).update({ saved: arr });

                }
                else {

                    arr.push(id);

                    db.collection("users").doc(uid).update({ saved: arr });
                    setsave(true);
                }
            })
        }
    }

    useEffect(() => {
        db
            .collection("users")
            .doc(postedby)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    setprofId(doc.id);
                    if (doc.data().profilePic === "")
                        setprofPic(user2);
                    else
                        setprofPic(doc.data().profilePic);
                }
            });
    }, [postedby])

    let history = useHistory();
    function Profile(y) {
        // localStorage.setItem(
        //     "profileid",
        //     JSON.stringify({ profileid: y })
        // );
        // console.log(y)
        history.push(`/user/${y}`);
    }


    return (
        <>

            <Item>
                <div onClick={() => onclick(id)}>
                    <Imagex src={Image} alt="oops nothing to show" />
                </div>

                <Body>

                    <div style={{ display: 'flex' }}>
                        <Title onClick={() => onclick(id)}>{title}</Title>

                        <span style={{ marginLeft: "auto" }} onClick={() => changeSaved(id)}>
                            {save ? <BookImg src={bookmark} /> : <Icon src={saveicon} save={save} />}
                        </span>
                    </div>

                    <div style={{ display: 'flex', alignItems: "center" }}>
                        <img src={profPic} style={{ height: '27px', width: '27px', borderRadius: '50%', cursor: "pointer" }} onClick={() => { Profile(profId) }} alt="" />
                        <Name onClick={() => { Profile(profId) }}>{name}</Name>
                    </div>
                    <Div>{desc}</Div>
                </Body>


            </Item>
        </>
    )
}

const Item = styled.div`
    display:flex;
    flex-direction:row;
    margin-top: 20px;
    width: 730px;
    margin-left: 35px;
    @media (max-width: 1115px){
        width: 600px;
    };
    @media (max-width: 985px){
    margin-left: 0px;
    };
    @media (max-width: 950px){
        width: 450px;
    margin-left: 35px;
    };
    @media (max-width: 763px){
        margin-left: 0px;
      };
    @media (max-width: 500px){
        width: 320px;
    };
`

const Body = styled.div`
width: 100%;
margin-left: 15px;
`

const BookImg = styled.img`
    width: 14px;
    cursor: pointer;

`

const Icon = styled.img.attrs(props => ({
}))`
    margin-top: 7px;
    cursor: pointer;

`;

const Title = styled.p`
    font-size:20px;
    width: 90%;
    margin-bottom: 10px;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    cursor: pointer;
    @media (max-width: 950px){
    font-size:18px;
    };
    @media (max-width: 500px){
    font-size:16px;
    };
`
const Name = styled.p`
    margin: 0;
    font-family:Rubik;
    font-size:14px;
    padding-left: 8px;
    cursor: pointer;
    @media (max-width: 950px){
        font-size:13px;
    };
    @media (max-width: 500px){
        font-size:12px;
    };
`

const Div = styled.div`
    width: 90%;
    font-size:13px;
    margin-top: 35px;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    @media (max-width: 950px){
        font-size:12px;
    };
    @media (max-width: 500px){
        margin-top: 20px;
        font-size:11px;
    };
`

const Imagex = styled.img`
    width: 149px;
    height: 198px;
    cursor: pointer;
    @media (max-width: 950px){
        width: 130px;
        height: 172.75px;
    };
    @media (max-width: 500px){
        width: 105px;
        height: 140px;
    };
`

export default SearchFolio;