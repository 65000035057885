import React, { useState, useEffect } from "react";
import { Drawer } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import NavFront from "./Front/NavFront";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { auth, db } from "../../firebase";
import Search from "./Front/Search";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import OneSignal from 'react-onesignal';


const ColorButton = withStyles((theme) => ({
  root: {
    color: "white",
    width: "100px",
    height: "40px",
    border: "none",
    color: "white",
    borderRadius: "18px",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "normal",
    textAlign: "center",
  },
}))(Button);


const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontFamily: "Rubik",
    justifyContent: "center",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    fontSize: "15px",
    boxShadow: "0px 1px 4px rgb(0 0 0 / 20%)",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '@media (max-width: 1020px)': {
      width: `calc(${drawerWidth}px - 40px)`,
    },
    '@media (max-width: 680px)': {
      width: `calc(${drawerWidth}px - 90px)`,
    },
    '@media (max-width: 430px)': {
      width: `calc(${drawerWidth}px - 130px)`,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#DBDBDB",
    overflow: "hidden",
    paddingTop: "100px",
    '@media (max-width: 1020px)': {
      width: `calc(${drawerWidth}px - 40px)`,
    },
    '@media (max-width: 680px)': {
      width: `calc(${drawerWidth}px - 90px)`,
    },
    '@media (max-width: 430px)': {
      width: `calc(${drawerWidth}px - 130px)`,
    },
  },
  elevation4: {
    boxShadow: 0,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    margin: "150px 20px 0",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    border: "none",
    outline: "0",
  },
  link: {
    display: "block",
    textDecoration: "none",
    color: "black",
    cursor: "pointer",
    fontWeight: "500",
    fontSize: "18px",
    padding: "10px 0 10px 2vw",
    marginTop: "35px",
    cursor: "pointer",
    '&:hover': {
      cursor: "pointer",
      textDecoration: "none",
      color: "unset"
    },
    '@media (max-width: 1020px)': {
      fontSize: "16px",
    },
    '@media (max-width: 680px)': {
      fontSize: "14px",
    },
    '@media (max-width: 430px)': {
      fontSize: "12px",
    },
  },
  heading: {
    padding: "10px 0 10px 2vw",
    '@media (max-width: 1020px)': {
      fontSize: "22px",
    },
    '@media (max-width: 680px)': {
      fontSize: "20px",
    },
    '@media (max-width: 430px)': {
      fontSize: "18px",
    },
  },

  title: {
    '@media (max-width: 1020px)': {
      fontSize: "14px",
    },
    '@media (max-width: 680px)': {
      fontSize: "13px",
    },
  },
  set: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    '@media (max-width: 955px)': {
      justifyContent: "left",
    },
  },
  p1: {
    fontWeight: "600",
    margin: 0,
    padding: 0,
    marginRight: "73px",
    '@media (max-width: 1020px)': {
      fontSize: "13px",
    },
    '@media (max-width: 955px)': {
      marginRight: "0px",
    },
    '@media (max-width: 680px)': {
      fontSize: "12px",
    },
  },
  p2: {
    fontSize: "13.5px",
    textAlign: "center",
    marginLeft: "28px",
    '@media (max-width: 1020px)': {
      fontSize: "12.5px",
    },
    '@media (max-width: 955px)': {
      textAlign: "left",
      marginLeft: "58px",
    },
    '@media (max-width: 680px)': {
      fontSize: "11.5px",
    },
  },
  p3: {
    fontSize: "13.5px",
    marginTop: "10px",
    '@media (max-width: 1020px)': {
      fontSize: "12.5px",
    },
    '@media (max-width: 680px)': {
      fontSize: "11.5px",
    },
  },
  p4: {
    fontWeight: "600",
    margin: "0",
    padding: "0",
    '@media (max-width: 1020px)': {
      fontSize: "13px",
    },
    '@media (max-width: 680px)': {
      fontSize: "12px",
    },
  },
  p5: {
    fontSize: "13.5px",
    textAlign: "center",
    marginLeft: "232px",
    '@media (max-width: 1020px)': {
      marginLeft: "252px",
    },
    '@media (max-width: 955px)': {
      textAlign: "left",
      marginLeft: "58px",
    },
    '@media (max-width: 680px)': {
      fontSize: "12.5px",
    },
  }
}));

const SettingNoti = () => {
  const history = useHistory();
  const [searchtype, setsearchtype] = useState([]);
  const [navClick, setnavClick] = useState(false);
  const [searchtext, setsearchtext] = useState("");
  const [exacttitle, setexacttitle] = useState("");
  const [searchtitle, setsearchtitle] = useState([]);

  const [searchClicked, setsearchClicked] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  if (localStorage.getItem("allowed") === "0") {
    window.location.href = "/home";
  }
  const uid = JSON.parse(localStorage.getItem("user"))["uid"];


  const email = JSON.parse(localStorage.getItem("user"))["email"];

  function checkednoti() {
    OneSignal.isPushNotificationsEnabled().then(function (isEnabled) {
      // localStorage.setItem("isNoti", isEnabled);
      if (isEnabled) {
        console.log("Push notifications are enabled!");
      }
      else {
        console.log("Push notifications are not enabled yet.");
      }
    });


    var noti = JSON.parse(localStorage.getItem('isNoti'));
    if (noti) {
      return true;
    } else {
      return false;
    }

  }

  db.collection("users").doc(uid).get().then((doc) => {
    if (doc.exists) {
      if ((doc.data().noti)) {
        localStorage.setItem("isNoti", true);
      } else {
        localStorage.setItem("isNoti", false);
      }
    }
  });

  const [webnoti, setwebnoti] = useState(checkednoti());

  const handleChange = async (event) => {
    setwebnoti(event.target.checked);
    // localStorage.setItem("isNoti", event.target.checked);
    db.collection("users").doc(uid).update({ noti: event.target.checked });

    if (event.target.checked === true) {
      await OneSignal.setSubscription(true);
    } else {
      await OneSignal.setSubscription(false);
    }
  };

  const classes = useStyles();
  const logOut = () => {
    auth.signOut();
    localStorage.removeItem("user");
    history.push('/home');
    localStorage.setItem('allowed', '0');
    localStorage.setItem('signup', '0');
  }

  useEffect(() => {
    document.title = "Notifications Settings"
    window.scrollTo(0, 0);

  }, []);

  return (
    <React.Fragment>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar style={{ boxShadow: 'none' }} position="fixed" className={classes.appBar}>
          <NavFront
            setnavClick={setnavClick}
            setsearchClicked={setsearchClicked}
            setexacttitle={setexacttitle}
            searchtitle={searchtitle}
            setsearchtitle={setsearchtitle}
            searchtext={searchtext}
            setsearchtext={setsearchtext}
            setsearchtype={setsearchtype}
          />
          <div>
            {!searchClicked && searchtext !== "" ? (
              <Search
                exacttitle={exacttitle}
                searchtitle={searchtitle}
                setsearchClicked={setsearchClicked}
                searchtype={searchtype}
                searchtext={searchtext}
              />

            ) : null}
          </div>
        </AppBar>

        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerContainer}>
            <h4 className={classes.heading}>Settings</h4>
            <Divider />
            <List>
              <Link className={classes.link} to="/settings">
                Account
              </Link>

              <Link className={classes.link}
                to="/notification"
                style={{
                  backgroundColor: "#807e7e",
                  color: "white",
                }}
              >
                Notifications
              </Link>

              <Link className={classes.link} to="/feedback">
                Feedback
              </Link>

              <Link className={classes.link}
                onClick={handleOpen}
              >
                Log Out
              </Link>

              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div className={classes.paper}>
                    <h5 id="spring-modal-title">Are you sure you want to Log Out?</h5>
                    <div className={classes.root} style={{ justifyContent: "space-evenly", marginTop: "20px" }}>
                      <ColorButton variant="contained" style={{ background: "#5db0d8" }} onClick={() => { logOut() }}>
                        Yes
                      </ColorButton>
                      <ColorButton variant="contained" color="secondary" onClick={() => { handleClose() }}>No</ColorButton>
                    </div>
                  </div>
                </Fade>
              </Modal>

            </List>
          </div>
        </Drawer>

        <main className={classes.content}>

          <div>

            <h6 className={classes.title}>
              <strong>Desktop Notifications</strong>
            </h6>

            <div className={classes.set}>
              <Switch
                checked={webnoti}
                onChange={handleChange}
                color="primary"
                name="checkedA"
                id="checkedA"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              <p className={classes.p1} style={{ cursor: "default" }}>
                Get notifications in the browser
              </p>
            </div>

            <p className={classes.p2} style={{ cursor: "default" }}
            >
              Receive notifications on your Computer
            </p>

          </div>

          <hr style={{ margin: "30px 0" }} />
          <div>
            {/* <h6 className={classes.title}>
              <strong>Email Notifications</strong>
            </h6>
            <p className={classes.p3} style={{ cursor: "default" }}
            >
              To unsubscribe from an email notification about updates, click the "Toggle" button below.
            </p> */}

            {/* <div className={classes.set}>
              <Switch
                checked={emailnoti}
                onChange={handleChange2}
                color="primary"
                name="checkedB"
                id="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              <p className={classes.p4} style={{ cursor: "default" }}>
                Send me emails about updates I requested
              </p>
            </div> */}
            <p className={classes.p5} style={{ cursor: "default", visibility: "hidden" }}>
              If this setting is turned off, You won't receive any notifications anymore.
            </p>
          </div>
          {/* <hr style={{ margin: "30px 0" }} /> */}
        </main>
      </div>
    </React.Fragment>
  );
};


export default SettingNoti;