import React from 'react';
import Navbar from "../layouts/Navbar";
import TagLine from "../layouts/TagLine";
import Intro from "../layouts/Intro";
import Section from "../layouts/Section";
import Footer from "../layouts/Footer";
import { Helmet } from "react-helmet";
import logo from "../../assets/images/logos/PreviewLogo.svg"


const HomePage = () => {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>

            <Helmet>

                {/* <!-- HTML Meta Tags --> */}
                <title>Kredey | Publish and Read Amazing Documents</title>
                <meta name="description" content="Kredey Folio is a platform where people, can read documents to learn new things and publishers, can share their work and reach millions of readers" />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content="Kredey | Publish and Read Amazing Documents" />
                <meta itemprop="description" content="Kredey Folio is a platform where people, can read documents to learn new things and publishers, can share their work and reach millions of readers" />
                <meta itemprop="image" content={logo} />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content="https://www.kredey.com" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Kredey | Publish and Read Amazing Documents" />
                <meta property="og:description" content="Kredey Folio is a platform where people, can read documents to learn new things and publishers, can share their work and reach millions of readers" />
                <meta property="og:image" content={logo} />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Kredey | Publish and Read Amazing Documents" />
                <meta name="twitter:description" content="Kredey Folio is a platform where people, can read documents to learn new things and publishers, can share their work and reach millions of readers" />
                <meta name="twitter:image" content={logo} />

            </Helmet>

            <Navbar />
            <TagLine />
            <Intro />
            <Section />
            <Footer />
        </>
    )
}

export default HomePage;