import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { db } from '../../../firebase';
import '../../../search.css';

const useStyles = makeStyles({
  root: {
    width: "445px",
    height: "300px",
    marginRight: "150px",
    overflowY: 'scroll',
    '@media (max-width: 1145px)': {
      width: "343px",
      height: "300px",
      marginRight: "125px",
    },
    '@media (max-width: 955px)': {
      width: "256.73px",
      height: "300px",
      marginRight: "110px",
    },
    '@media (max-width: 815px)': {
      width: "214px",
      height: "250px",
      marginRight: "90px",
    },
    '@media (max-width: 730px)': {
      width: "250px",
      height: "250px",
      marginRight: "-70px",
    },
    '@media (max-width: 640px)': {
      width: "250px",
      height: "250px",
      marginRight: "-100px",
    },
    '@media (max-width: 565px)': {
      width: "250px",
      height: "250px",
      marginRight: "-130px",
    },
    '@media (max-width: 510px)': {
      width: "200px",
      height: "250px",
      marginRight: "-70px",
    },
    '@media (max-width: 415px)': {
      marginRight: "-8px",
    },
    '@media (max-width: 330px)': {
      marginRight: "-35px",
    },

  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: "14px",
    fontWeight: "bold",
    display: "block",
    width: "300px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    cursor: "pointer",
    '@media (max-width: 1145px)': {
      width: "250px",
    },
    '@media (max-width: 955px)': {
      width: "200px",
    },
    '@media (max-width: 815px)': {
      width: "160px",
      fontSize: "12px",
    },
    '@media (max-width: 415px)': {
      marginRight: "-8px",
    },
    '@media (max-width: 330px)': {
      marginRight: "-35px",
    },
  },
  description: {
    fontSize: '11px',
    marginTop: '-18px',
    fontWeight: "bold",
  },
  pos: {
    marginBottom: 12,
  },
});

function Search({ searchtext, setsearchClicked, exacttitle, searchtype, searchtitle }) {
  const classes = useStyles();
  let history = useHistory();
  const categories = [
    "agriculture",
    "anthropology",
    "architecture",
    "arthistory",
    "biology",
    "botany",
    "business",
    "design",
    "economics",
    "engineering",
    "english",
    "enterpreneurship",
    "environmentalscience",
    "fantasy",
    "fashion",
    "finearts",
    "finance",
    "food",
    "generalscience",
    "geography",
    "geology",
    "health",
    "horror",
    "humor",
    "law",
    "lifestyle",
    "literature",
    "management",
    "maths",
    "medicalscience",
    "mentalhealth",
    "music",
    "mystery",
    "nonfiction",
    "parenting",
    "personalitydevelopment",
    "philosophy",
    "physicaleducation",
    "physics",
    "poetry",
    "politicalscience",
    "psychology",
    "publicadministration",
    "romance",
    "sci-fi",
    "socialscience",
    "sociology",
    "spirituality",
    "statistics",
    "theater",
    "thriller",
    "youngadult",
    "zoology",
  ];

  // const searchbutton = () => {
  //   setsearchClicked(true);
  //   history.push({
  //     pathname: "/home",
  //     category: searchtext,
  //   });
  // };
  function folio(x) {
    localStorage.setItem(
      "postid",
      JSON.stringify({
        postid: x,
      })
    );
    history.push(`/folio/${x}`);
    window.location.reload();
  }
  function Profile(y) {
    // localStorage.setItem(
    //   "profileid",
    //   JSON.stringify({ profileid: y })
    // );
    history.push(`/user/${y}`);
    window.location.reload();
  }

  async function category(cat) {
    const response = db.collection("posts");
    const data = await response.get();
    let arr = [];
    data.docs.forEach(item => {
      arr.push({ data: item.data(), id: item.id });
      // console.log(arr);
    })
    arr = arr.filter((obj) => obj.data.category.replace(/ /g, "").toLowerCase() === cat.replace(/ /g, "").toLowerCase());
    localStorage.setItem("search", JSON.stringify({ search: arr, category: cat }));
    history.push("/searchresults");
    window.location.reload();
  }
  return (


    <div className="searchdiv">
      <Card style={{ marginLeft: '0px' }} className={classes.root}>
        {searchtype.length !== 0 ?
          searchtype.map(each => {
            return (
              each.type == "Folio" ?
                <CardActions style={{ marginBottom: '-12px' }}>
                  <Button onClick={() => folio(each.id)} fullWidth>
                    <p className={classes.title}>{each.title}</p>
                    <p className={classes.description} style={{ color: "blue" }}><text style={{ color: 'black', fontWeight: "100" }}>in </text>{each.tag}</p>
                  </Button>
                </CardActions> :
                each.type == "Profile" ? <CardActions style={{ marginBottom: '-12px' }}>
                  <Button onClick={() => Profile(each.id)} fullWidth>
                    <p className={classes.title}>{each.title}</p>
                    <p className={classes.description} style={{ color: "red" }} >
                      <text style={{ color: 'black', fontWeight: "100" }}>in </text>
                      {each.tag}
                    </p>
                  </Button>
                </CardActions> : <CardActions style={{ marginBottom: '-12px' }}>
                  <Button onClick={() => category(each.title)} fullWidth>
                    <p className={classes.title}>{each.title}</p>
                    <p className={classes.description} style={{ color: "green" }}>
                      <text style={{ color: 'black', fontWeight: "100" }}>in </text>
                      {each.tag}
                    </p>
                  </Button>
                </CardActions>

            )
          }) : <CardActions>
            <Button fullWidth disabled style={{ color: "black" }}>
              No result found :&#40;
            </Button>
          </CardActions>
        }
      </Card>
    </div >
  );
}


export default Search;
