import React, { useEffect, useState } from "react";
import NavFront from "../Front/NavFront";
import Profile from "./Profile";
import Description from "./Description";
import Cont from "./Cont";
import styled from "styled-components";
import Discussions from "./Discussions";
import Tile from '../Front/Tile';
import { db, auth } from '../../../firebase';
import { useHistory } from "react-router";
import SignInDialog from './SignInDialog';
import Search from '../Front/Search';
import Footer from "../../layouts/Footer";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import favicon from '../../../assets/images/logos/favicon.png'
import { Helmet } from "react-helmet";


const useStyles = makeStyles((theme) => ({
    main: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        margin: "auto",
        padding: "0 30px 110px 18px",
        marginTop: "30px",
        '@media (max-width: 1000px)': {
            padding: "0 20px 20px 8px",
        },
        // '@media (max-width: 950px)': {
        //     marginTop: "65px"
        // },
        '@media (max-width: 815px)': {
            padding: "0 20px 20px 12px",
        },
        '@media (max-width: 450px)': {
            padding: "0 10px 20px 6px",
        },
    },
    grid: {
        display: "flex",
        flexDirection: "row",
        overflow: "scroll",
    },
    heading: {
        margin: "0px 0px 20px 12px",
        '@media (max-width: 815px)': {
            margin: "0px 0px 15px 8px",
        },
        '@media (max-width: 450px)': {
            margin: "0px 0px 15px 4px",
        },
    }
}));

function getIdFromUrl(url) {
    const baseUrl = "https://www.kredey.com/folio/";
    let imagePath = url.replace(baseUrl, "");
    return imagePath;
}


const Folio = () => {
    const user = auth.currentUser;
    const [navClick, setnavClick] = useState(false);
    // console.log(getIdFromUrl(window.location.href));
    localStorage.setItem(
        "postid",
        JSON.stringify({
            postid: getIdFromUrl(window.location.href),
        })
    );

    const postid = JSON.parse(localStorage.getItem("postid"))['postid'];
    let uid = '';
    if (localStorage.getItem('allowed') === '1') {
        uid = JSON.parse(localStorage.getItem("user"))['uid'];
    }
    const [date, setdate] = useState("");

    const [coverpic, setcoverpic] = useState("");

    const [category, setcategory] = useState("");
    const [description, setdescription] = useState("");
    const [title, settitle] = useState("");
    const [pdf, setpdf] = useState("");
    const [name, setName] = useState("");
    const [likecount, setlikeCount] = useState(0);
    const [liked, setLiked] = useState();
    const [follower, setFollower] = useState();
    const [saved, setSaved] = useState();
    const [followercount, setfollowerCount] = useState(0);
    const [readcount, setreadCount] = useState(0);
    const [read, setread] = useState();
    const [puid, setpuid] = useState("");
    const [profPic, setprofPic] = useState(null);
    const [post, Setpost] = useState([]);
    const [page, Setpage] = useState(0);
    const history = useHistory();
    const [searchtext, setsearchtext] = useState('');
    const [searchtype, setsearchtype] = useState([]);
    const [exacttitle, setexacttitle] = useState('');
    const [searchtitle, setsearchtitle] = useState([]);
    const [searchClicked, setsearchClicked] = useState(false);

    var randomLength = Math.floor((Math.random() * 10) + 1);
    function randomName(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }


    var user2 = `https://avatars.dicebear.com/api/adventurer-neutral/:${randomName(randomLength)}.svg`;


    const fetchfolio = async () => {

        var docRef = db.collection("posts").doc(postid);
        docRef.onSnapshot((doc) => {
            if (doc.exists) {
                setpuid(doc.data().postedBy);
                setdate(doc.data().date_created.toDate().toLocaleString('default', { month: 'short' }) + " " + doc.data().date_created.toDate().getDate() + ", " + doc.data().date_created.toDate().getFullYear());
                setcoverpic(doc.data().img_url);
                setcategory(doc.data().category);
                setdescription(doc.data().description);
                settitle(doc.data().title);
                setpdf(doc.data().pdf_url);
                setName(doc.data().name);
                setLiked(doc.data().liked_By);
                setlikeCount(doc.data().like_Count);
                setread(doc.data().read_By);
                setreadCount(doc.data().read_Count);
                var docRef2 = db.collection("users").doc(doc.data().postedBy);
                docRef2.get().then((doc) => {
                    setFollower(doc.data().follower);
                    setfollowerCount(doc.data().follower_Count);
                    setSaved(doc.data().saved);
                    if (doc.data().profilePic === "") {
                        setprofPic(user2);
                    }
                    else {
                        setprofPic(doc.data().profilePic);
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });
            }

        })

        const response = db.collection("posts");
        const data = await response.limit(10).get()
        const arr = [];
        data.docs.forEach(item => {
            var data = item.data();
            data = { ...data, id: item.id }
            arr.push(data);
        })
        Setpost(arr);
    }
    useEffect(() => {

        fetchfolio();
        if (auth.currentUser) {
            ToRead();
        }

        window.scrollTo(0, 0);

    }, [page]);


    const reaction = async () => {
        let arr = [...liked];
        const uid = JSON.parse(localStorage.getItem("user"))['uid'];
        if (arr.indexOf(uid) > -1) {

            arr = arr.filter(e => e !== uid);
            setLiked(arr);
            setlikeCount(arr.length);
            await db.collection("posts").doc(postid).update({ liked_By: arr });
            await db.collection("posts").doc(postid).update({ like_Count: arr.length });
        }
        else {

            arr.push(uid);
            setLiked(arr);
            setlikeCount(arr.length);
            await db.collection("posts").doc(postid).update({ liked_By: arr });
            await db.collection("posts").doc(postid).update({ like_Count: arr.length });
        }

    }
    const Tofollow = async () => {
        const uid = JSON.parse(localStorage.getItem("user"))['uid'];
        let arr = [...follower];
        if (puid !== uid) {
            if (arr.indexOf(uid) > -1) {

                arr = arr.filter(e => e !== uid);
                setFollower(arr);
                setfollowerCount(arr.length);
                await db.collection("users").doc(puid).update({ follower: arr });
                await db.collection("users").doc(puid).update({ follower_Count: arr.length });
            }
            else {
                arr.push(uid);
                setFollower(arr);
                setfollowerCount(arr.length);
                await db.collection("users").doc(puid).update({ follower: arr });
                await db.collection("users").doc(puid).update({ follower_Count: arr.length });
            }
        }


    }
    const Tosave = async () => {
        const uid = JSON.parse(localStorage.getItem("user"))['uid'];
        let arr = [...saved];

        if (arr.indexOf(postid) > -1) {

            arr = arr.filter(e => e !== postid);
            setSaved(arr);

            await db.collection("users").doc(uid).update({ saved: arr });

        }
        else {

            arr.push(postid);
            setSaved(arr);
            await db.collection("users").doc(uid).update({ saved: arr });
        }



    }

    const ToRead = async (c_page, t_page) => {
        if (auth.currentUser) {
            c_page = c_page + 1;
            const uid = JSON.parse(localStorage.getItem("user"))['uid'];
            if (c_page >= Math.round(t_page / 2) || t_page === 1) {
                let arr = [...read];
                if (arr.indexOf(uid) === -1) {
                    arr.push(uid);
                    setread(arr);
                    setreadCount(arr.length);
                    await db.collection("posts").doc(postid).update({ read_By: arr });
                    await db.collection("posts").doc(postid).update({ read_Count: arr.length });
                }
            }
        }
    }

    function tileid(x) {
        localStorage.setItem(
            "postid",
            JSON.stringify({
                postid: x,
            })
        );
        const win = window.open(`/folio/${x}`, "_blank");
        win.focus();
    }
    function profileid(y) {
        // localStorage.setItem("profileid", JSON.stringify({ profileid: y }));
        history.push(`/user/${y}`)
    }

    const classes = useStyles();

    const xscroll = (d) => {
        var element = document.querySelector("#sticky");

        element.addEventListener('wheel', (event) => {
            event.preventDefault();

            element.scrollBy({
                left: event.deltaY < 0 ? -30 : 30,
            });
        });
    }
    useEffect(async () => {
        await xscroll();
    }, []);


    // useEffect(() => {
    //     document.title = `${title}-${description}-${name} | Kredey | Publish and Read Amazing Documents`
    // }, [title, description, name]);

    // var linker = "http://kredey.com/folio/" + postid;

    return (
        <React.Fragment>

            <Helmet>

                {/* <!-- HTML Meta Tags --> */}
                <title>{title} | {description} | {name} | Kredey | Publish and Read Amazing Documents</title>
                <meta name="description" content={description} />
                <meta name="title" content={title + " | " + description + " | " + name + " | Kredey | Publish and Read Amazing Documents"} />
                <link rel="shortcut icon" href={favicon} />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={title + " | " + description + " | " + name + " | Kredey | Publish and Read Amazing Documents"} />
                <meta itemprop="description" content={description} />
                <meta itemprop="image" content={coverpic} />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:title" content={title + " | " + description + " | " + name + " | Kredey | Publish and Read Amazing Documents"} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={coverpic} />
                <meta property="og:url" content={"http://kredey.com/folio/" + postid} />
                <meta property="og:type" content="website" />
                <meta name="og_site_name" property="og:site_name" content="kredey.com" />

                {/* <!-- Twitter Meta Tags --> */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@kredey" />
                <meta name="twitter:creator" content="@kredey" />
                <meta property="twitter:url" content={"http://kredey.com/folio/" + postid} />
                <meta property="twitter:title" content={title + " | " + description + " | " + name + " | Kredey | Publish and Read Amazing Documents"} />
                <meta property="twitter:description" content={description} />
                <meta property="twitter:image" content={coverpic} />
                <meta name="twitter:app:country" content="in" />

            </Helmet>



            {/* Navbar */}
            <NavFront setnavClick={setnavClick} setsearchClicked={setsearchClicked} setexacttitle={setexacttitle} searchtitle={searchtitle} setsearchtitle={setsearchtitle} searchtext={searchtext} setsearchtype={setsearchtype} setsearchtext={setsearchtext} user={user} />
            {!searchClicked && searchtext !== '' ? <Search exacttitle={exacttitle} searchtitle={searchtitle} setsearchClicked={setsearchClicked} searchtype={searchtype} searchtext={searchtext} /> : null}

            <MainBox >
                {navClick ? <SignInDialog open={true} setnavClick={setnavClick} /> : null}
                <Profile Cover={coverpic} title={title} Desc={description} Category={category} Date_c={date} D_Name={name} LikeCount={likecount} click={reaction} Tofollow={Tofollow} readc={readcount} followcount={followercount} postid={postid} puid={puid} prof={profPic} uid={uid} view_prof={profileid} />
                <LineMd />
                <Description Desc={description} />

            </MainBox>
            <Line />
            <PdfBox>
                <Cont pdfUrl={pdf} read={ToRead} id={postid} />
                <Discussions uid={uid} puid={puid} view_prof={profileid} />
            </PdfBox>

            <div className={classes.main}>
                {navClick ? <SignInDialog open={true} setnavClick={setnavClick} /> : null}
                <h5 className={classes.heading}>Suggestions</h5>

                <Grid className={classes.grid} id="sticky"
                >
                    {post &&
                        post.map(p => {
                            return (<Tile Title={p.title} Image={p.img_url} id={p.id} onclick={tileid} Name={p.name} read={p.read_Count} setnavClick={setnavClick} />);
                        })}
                </Grid>
            </div>

            <hr style={{ margin: 0, height: "0.8px" }} />
            <Footer />
        </React.Fragment>
    )
}

const MainBox = styled.div`
/* border:2px solid violet; */
    display:flex;
    flex-direction:row;
    // justify-content:center;
    padding-top: 90px;
    @media (max-width: 815px){
        flex-direction:column;
        align-items: center;
    };
`
const PdfBox = styled.div`
display:flex;
flex-direction:row;
    @media (max-width: 950px){
        justify-content: center;
        flex-direction:column;
        // align-items: center;
    };
`
const LineMd = styled.div`
    @media (max-width: 815px){
        border-bottom: 1.75px solid #C7C7C7;
        width: -webkit-fill-available;
        margin: 30px 20px;
    };
    @media (max-width: 450px){
        margin: 30px 10px;
    };
`
const Line = styled.div`
        border-bottom: 1.75px solid #C7C7C7;
        margin: 10px 30px 0px;
        @media (max-width: 1000px){
            margin: 10px 20px 0px;
        };
        @media (max-width: 815px){
            margin: 30px 20px 0px;
        };
        @media (max-width: 450px){
            margin: 30px 10px 0px;
        };
`


export default Folio;