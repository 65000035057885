import React, { useContext, useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import NavFront from "../Front/NavFront";
import firebase from "../../../firebase";
import file from '../../../assets/file.svg'
import { userContext } from "../../../App";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { auth, db } from "../../../firebase";
import { useHistory } from "react-router";
import Modal from '@material-ui/core/Modal';
import '../../../Upload.css';
import SimpleModal from './FolioModal';
import dropdown from '../../../assets/dropdown.svg';
import Search from "../Front/Search";
import Footer from "../../layouts/Footer";
import { useMediaQuery } from "@material-ui/core";
import { useAlert } from 'react-alert'
import OneSignal from "react-onesignal"

import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    fontFamily: "Rubik",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    marginTop: "85px",
    fontFamily: 'Rubik',
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: "50px 24px 150px",
    justifyContent: "center",
    '@media (max-width: 575px)': {
      flexDirection: "column",
      padding: "50px 15px",
      alignItems: "center",
    },
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: "0 20px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "20px",
    border: "none",
    outline: "0",
    textAlign: "center"
  },
  error: {
    position: 'absolute',
    color: 'red',
    fontWeight: '400',
    marginTop: '-30px',
    fontSize: "14px"
  }
}));

const Upload = () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };


  const history = useHistory();
  if (localStorage.getItem('allowed') === '0') {
    window.location.href = '/home';
  }
  const uid = JSON.parse(localStorage.getItem("user"))["uid"];
  const [name, setname] = useState(null);
  db.collection("users")
    .doc(uid)
    .get()
    .then((doc) => {
      setname(doc.data().name)
    })
  const { state, dispatch } = useContext(userContext);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [imgUrl, setimageUrl] = useState(null);
  const [category, setCategory] = useState("");
  const [follower, setfollower] = useState();
  const [searchtext, setsearchtext] = useState("");
  const [searchtype, setsearchtype] = useState([]);
  const [navClick, setnavClick] = useState(false);
  const [allposts, setallposts] = useState([]);
  const [exacttitle, setexacttitle] = useState("");
  const [searchtitle, setsearchtitle] = useState([]);
  const [searchClicked, setsearchClicked] = useState(false);
  const Data = JSON.parse(localStorage.getItem("post"));
  const [didupload, setdidupload] = useState(true);
  const [opennow, setopennow] = useState(false);
  const [test, setTest] = useState(false);
  // window.OneSignal = window.OneSignal || [];
  // const OneSignal = window.OneSignal;
  useEffect(() => {
    // console.log(imgUrl);
    var sendNotification = function (data) {
      var headers = {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Basic MTMwNTRiZGQtOWViNC00OWFiLTg4ZmYtNTNmYzdhNzFiNTlj",
      };
      var options = {
        // host: "onesignal.com",
        // port: 443,
        // path: "/api/v1/notifications",
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      };
      fetch("https://onesignal.com/api/v1/notifications/", options)
        .then((response) => response.json())
        .then((response) => {
          // Do something with response.
        });
    };
    if (imgUrl) {
      const userPost = {
        postedBy: auth.currentUser.uid,
        title,
        description,
        category,
        name: name,
        pdf_url: Data.tempURL,
        date_created: new Date(),
        img_url: imgUrl,
        liked_By: [],
        like_Count: 0,
        read_Count: 0,
        read_By: [],
      };
      db.collection("posts").doc().set({
        postedBy: auth.currentUser.uid,
        title,
        description,
        name: name,
        category,
        pdf_url: Data.tempURL,
        date_created: new Date(),
        img_url: imgUrl,
        liked_By: [],
        like_Count: 0,
        read_Count: 0,
        read_By: [],
      });
      OneSignal.sendTag("person_posted", name);
      OneSignal.sendTag("folio_title", title);
      const postArray = [];
      postArray.push(userPost);
      localStorage.removeItem("post");
      dispatch({ type: "FOLIO_FORM", payload: postArray });
      // console.log("SENT_DATA");

      db.collection("users")
        .doc(uid)
        .get()
        .then((doc) => {
          var i = 0;
          var arr = doc.data().follower;
          for (i = 0; i < arr.length; i++) {
            var message = {
              app_id: "98200151-ac48-4869-bf4e-9dc618690ed7",
              headings: { en: title },
              contents: { en: description },
              data: { url: `http://localhost:3000/home` },
              included_segments: ["Subscribed Users"],
              filters: [
                { field: "tag", key: "uid", relation: "=", value: arr[i] },
              ],
            };
            var email = {
              app_id: "98200151-ac48-4869-bf4e-9dc618690ed7",
              included_segments: ["Subscribed Users"],
              email_subject: title,
              template_id: "9972f078-fa4d-498f-9edc-9a1aec83e945",
              filters: [
                { field: "tag", key: "uid", relation: "=", value: arr[i] },
              ],
            };
            // console.log(message);
            // console.log(email);

            sendNotification(message);
            sendNotification(email);
          }

          history.push("/home");
          // console.log(doc.data().follower);
        });
    }
  }, [imgUrl]);






  var getFileBlob = function (url, cb) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.addEventListener('load', function () {
      cb(xhr.response);
    });
    xhr.send();
  };

  //Submit the form
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // console.log(imageCropped, 'imgcrop');
    const uid = auth.currentUser.uid;
    let newImageUrl = null;
    var da = new Date().getTime();
    getFileBlob(fileselect, (blob) => {
      firebase.storage().ref(`/folios/${uid}/${da}`).put(blob).then(snap => {
        snap.ref.getDownloadURL().then((url) => {
          newImageUrl = url;
          setimageUrl(url)
        });
      })
    })
    // console.log(imgUrl);
    alert.success(<div style={{ textTransform: 'none', fontSize: "13px", textAlign: "center", padding: "5px" }}>Published Successfully!</div>)
  };

  const types = ["image/png", "image/jpeg", "image/jpg"];
  const [fileselect, setfileselect] = useState(null);
  // console.log(fileselect, 'type');

  const handleChange = (e) => {
    var fileSize = e.target.files[0].size / 1024 / 1024;
    if (fileSize <= 3) {
      // console.log("Size within limits");
      // console.log(e.target.files[0].size / 1024 / 1024)
      // console.log(e.target.files[0]);

      let selectedFile = e.target.files[0];
      // console.log(selectedFile, 'selectedFile')
      setfileselect(URL.createObjectURL(selectedFile));
      // setTest(true);
      // console.log(fileselect, 'fileselect')
      if (selectedFile) {
        // console.log('a')
        if (types.includes(selectedFile.type)) {
          setImage(selectedFile);
        } else {
          // console.log('b')
          setImage(null);
        }
      }
      // console.log(fileselect === null, 'checkfile')

    }
    else {
      handleModalOpen();
      // console.log("Size exceed limits");
      // console.log(e.target.files[0].size / 1024 / 1024)
    }
  };
  useEffect(() => {
    if (!fileselect)
      setTest(false);
    else
      setTest(!test);

  }, [fileselect])


  const [imageCropped, setimageCropped] = useState(false);

  const submitForm = (e) => {
    if (!imageCropped) {
      if (title !== "" && description !== "" && category !== "") {
        e.preventDefault();
        alert.error(<div style={{ textTransform: 'none', fontSize: "13px", textAlign: "center", padding: "5px" }}>Cover Page Required</div>)
      }
    }
    else {
      if (title !== "" && description !== "" && category !== "" && imageCropped) {
        handleFormSubmit(e);
      }
    }
  }

  const handleClear = () => {
    document.querySelector("form").reset();
    setfileselect(null);
  }

  const isMobile = useMediaQuery((theme) => theme.breakpoints.up("xs"));

  const alert = useAlert();

  useEffect(() => {
    document.title = "Publish Folio | Kredey | Publish and Read Amazing Documents"
    window.scrollTo(0, 0);

  }, []);

  return (
    <>

      <Helmet>

        {/* <!-- HTML Meta Tags --> */}
        <title>Publish Folio | Kredey | Publish and Read Amazing Documents</title>
        <meta name="description" content="Kredey Folio is a platform where people, can read documents to learn new things and publishers, can share their work and reach millions of readers" />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Publish Folio | Kredey | Publish and Read Amazing Documents" />
        <meta itemprop="description" content="Kredey Folio is a platform where people, can read documents to learn new things and publishers, can share their work and reach millions of readers" />
        <meta itemprop="image" content="" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://www.kredey.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Publish Folio | Kredey | Publish and Read Amazing Documents" />
        <meta property="og:description" content="Kredey Folio is a platform where people, can read documents to learn new things and publishers, can share their work and reach millions of readers" />
        <meta property="og:image" content="" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Publish Folio | Kredey | Publish and Read Amazing Documents" />
        <meta name="twitter:description" content="Kredey Folio is a platform where people, can read documents to learn new things and publishers, can share their work and reach millions of readers" />
        <meta name="twitter:image" content="" />

      </Helmet>

      <div>
        {/* Navbar */}
        <NavFront
          setnavClick={setnavClick}
          setsearchClicked={setsearchClicked}
          setexacttitle={setexacttitle}
          searchtitle={searchtitle}
          setsearchtitle={setsearchtitle}
          searchtext={searchtext}
          setsearchtext={setsearchtext}
          setsearchtype={setsearchtype}
        />
        {!searchClicked && searchtext !== "" ? (
          <Search
            exacttitle={exacttitle}
            searchtitle={searchtitle}
            setsearchClicked={setsearchClicked}
            searchtype={searchtype}
            searchtext={searchtext}
          />
        ) : null}
      </div>

      <div className={classes.root}
        style={{ minHeight: "100vh" }}
      >
        <main className={classes.content}>
          <Container>
            {test === true ? (

              <SimpleModal
                fileselect={fileselect}
                setfileselect={setfileselect}
                imageCropped={imageCropped}
                setimageCropped={setimageCropped}
                width={!isMobile ? (260) : (331.11)}
                height={!isMobile ? (345.5) : (440)}
                setTest={setTest} />
            ) : null}

            {fileselect !== null ?
              imageCropped === true ?
                <div>
                  <img src={fileselect} style={{ width: "149px", height: "198px", marginBottom: "10px" }} />
                </div>
                : <img className='nofile' src={file}
                />
              : <img className='nofile' src={file} />}



            <Label className='uploadlabel' fileselect={fileselect} for='input-file'><div className='coverbtn'>+  Add Cover Page</div></Label>
            <input
              required
              type="file"
              id='input-file'
              name="image"
              accept="image/x-png,image/gif,image/jpeg,image/jpg"
              onChange={handleChange}
              style={{ visibility: 'hidden', width: "0", height: "0" }}
            />

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              onClose={handleModalClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div className={classes.paper}>
                  <h5 id="spring-modal-title" style={{ marginBottom: "15px" }}>Sorry! Upload failed<br />Size of the Image must not exceed 3MB.</h5>
                  <div className={classes.root}>
                    <Button variant="contained" onClick={() => { handleModalClose() }}>
                      OK
                    </Button>
                  </div>
                </div>
              </Fade>
            </Modal>


          </Container>


          <Form>

            <Label htmlFor="">Title*</Label>
            <input maxLength="80" required type="text" onChange={(e) => setTitle(e.target.value)} className="title-field" />
            <Label htmlFor="">Description*</Label>
            <textarea required maxLength="1000" type="text" onChange={(e) => { setDescription(e.target.value) }} className="desc-field" />
            <select required className='select'
              style={{ backgroundImage: `url(${dropdown})` }}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">Select Category</option>
              <option value="Agriculture">Agriculture</option>
              <option value="Anthropology">Anthropology</option>
              <option value="Architecture">Architecture</option>
              <option value="Art History">Art History</option>
              <option value="Biology">Biology</option>
              <option value="Botany">Botany</option>
              <option value="Business">Business</option>
              <option value="Design">Design</option>
              <option value="Economics">Economics</option>
              <option value="Engineering">Engineering</option>
              <option value="English">English</option>
              <option value="Enterpreneurship">Enterpreneurship</option>
              <option value="Environmental Science">Environmental Science</option>
              <option value="Fantasy">Fantasy</option>
              <option value="Fashion">Fashion</option>
              <option value="Fine Arts">Fine Arts</option>
              <option value="Finance">Finance</option>
              <option value="Food">Food and Nutrition</option>
              <option value="General Science">General Science</option>
              <option value="Geography">Geography</option>
              <option value="Geology">Geology</option>
              <option value="Health">Health and Hygeine</option>
              <option value="Horror">Horror</option>
              <option value="Humor">Humor</option>
              <option value="Law">Law</option>
              <option value="Lifestyle">Lifestyle</option>
              <option value="Literature">Literature</option>
              <option value="Management">Management</option>
              <option value="Maths">Maths</option>
              <option value="Medical Science">Medical Science</option>
              <option value="Mental Health">Mental Health</option>
              <option value="Music">Music</option>
              <option value="Mystery">Mystery</option>
              <option value="Non Fiction">Non Fiction</option>
              <option value="Parenting">Parenting</option>
              <option value="Personality Development">Personality Development</option>
              <option value="Philosophy">Philosophy</option>
              <option value="Physical Education">Physical Education</option>
              <option value="Physics">Physics</option>
              <option value="Poetry">Poetry</option>
              <option value="Political Science">Political Science</option>
              <option value="Psychology">Psychology</option>
              <option value="Public Administration">Public Administration</option>
              <option value="Romance">Romance</option>
              <option value="Sci-Fi">Sci-Fi</option>
              <option value="Social Science">Social Science</option>
              <option value="Sociology">Sociology</option>
              <option value="Spirituality">Spirituality</option>
              <option value="Statistics">Statistics</option>
              <option value="Theater">Theater</option>
              <option value="Thriller">Thriller</option>
              <option value="Young Adult">Young Adult</option>
              <option value="Zoology">Zoology</option>
            </select>

            <div className="btn-sec">
              <Btn onClick={handleClear}>Clear All</Btn>
              <Btn onClick={(e) => submitForm(e)} type="submit">Publish Folio</Btn>
            </div>




          </Form>

        </main>
      </div>
      <hr style={{ margin: 0, height: "0.8px" }} />
      <Footer />
    </>
  );
};
// =====================================STYLING COMPONENTS=================

const Container = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items:center;
  margin: 0 10px;
  width: 178px;
    @media (max-width: 635px){
  width: 150px;
  }
    @media (max-width: 575px){
    margin: 0;
  }
`;

const Form = styled.form`
  text-align: left;
  align-items: baseline;
  margin: 0 10px;
  @media (max-width: 635px){
    width: 300px;
  }
  @media (max-width: 575px){
    align-items: center;
    width: 100%;
    margin: 0;
    margin-top: 30px;
  }
`;

const Label = styled.label`
text-align: left;
margin: 0;
padding: 0;
font-size: 18px;
font-weight: 500;
color: black;
@media (max-width: 635px){
  font-size: 15px;
}
`


const Btn = styled.button`
    width: 190px;
    height: 45px;
    color: black;
    background: #FFFFFF;
    border: 2px solid #000000;
    box-sizing: border-box;
    border-radius: 18px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    @media (max-width: 795px){
      width: 155px;
    }
    @media (max-width: 635px){
      font-size: 14px;
      width: 140px;
    }
`

const Button = styled.button`
width: 140px;
height: 40px;
border: none;
color: white;
background: #5db0d8;
border-radius: 18px;
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 19px;
text-align: center;
@media (max-width: 543px){
    font-size: 16px;
    width: 126.34px;
    height: 35px;
};

@media (max-width: 400px){
  width: 110px;
};

`


export default Upload;