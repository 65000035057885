import React, { useEffect } from 'react';
import styled from 'styled-components';
import ReactPDFviewer from './ReactPDFviewer';

const Cont = ({ pdfUrl, read, id }) => {
  useEffect(() => {

  }, [pdfUrl, read])
  return (
    <PdfContainer>
      <ReactPDFviewer pdf_t={pdfUrl} read_c={read} id={id} />
    </PdfContainer>
  );
}

const PdfContainer = styled.div`
margin: 30px 0px 0px 30px;
height: 712px;
overflow:'scroll';
border:1px solid black;
box-sizing:border-box;
width: 64%;
@media (max-width: 1145px){
  margin: 30px 0px 0px 30px;
  width: 61%;
};
@media (max-width: 1000px){
  margin: 30px 0px 0px 20px;
  width: 62%;
};
@media (max-width: 950px){
  margin: 30px 20px 0px 20px;
  width: -webkit-fill-available;
};
@media (max-width: 450px){
  margin: 30px 10px 0px 10px;
  width: -webkit-fill-available;
};
`

export default Cont;