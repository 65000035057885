import React from "react";
import { useHistory } from "react-router";
import "../../../ProfileCard.css";
import arc from "../../../assets/images/blue-arc.png";

const Profileprofcard = ({ name, desc, follower, profile_pic, profileid }) => {
  let history = useHistory();
  function Profile(y) {
    // localStorage.setItem(
    //   "profileid",
    //   JSON.stringify({ profileid: y })
    // );
    // console.log(y)
    history.push(`/user/${y}`);
  }
  return (
    <>
      <div className="profcard">
        <div className="profimg" style={{ backgroundImage: `url(${profile_pic})` }} onClick={() => { Profile(profileid) }} >
        </div>
        <div className="infos">
          <div className="nameprof">
            <h2 onClick={() => { Profile(profileid) }} >{name}</h2>
          </div>
          <p className="text2" style={{ cursor: "text" }}>
            {desc}
          </p>
          <button onClick={() => { Profile(profileid) }} className="view">View profile</button>
        </div>
        <img src={arc} alt="" className="arc" />

      </div>

      <hr className="line" />
    </>
  )
};
export default Profileprofcard;
