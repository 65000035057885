import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import first from '../../assets/first.png';
import second from '../../assets/second.png';
import third from '../../assets/third.png';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import './Onboarding.css';
import { db } from "../../firebase";
import { withStyles } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import styled from "styled-components";
import { useAlert } from 'react-alert';
import CloseIcon from '@material-ui/icons/Close';

const NextBtn = withStyles((theme) => ({
    root: {
        "border": "2.4px solid #000000",
        "background": "transparent",
        "font-family": "Rubik",
        "font-weight": "400",
        "font-size": "17px",
        "border-radius": "18px",
        "cursor": "pointer",
        "text-align": "center",
        "text-decoration": "none",
        "width": "180px",
        "height": "40px",
        "margin-bottom": "20px",
        '@media (max-width: 565px)': {
            "font-size": "15px",
            "width": "150px",
            height: "33.33px",
        },
        '@media (max-width: 385px)': {
            "font-size": "13px",
            "width": "120px",
            height: "26.67px",
        },
    },
}))(Button);

const CustomDialog = withStyles((theme) => ({
    root: {
        '& .MuiBackdrop-root': {
            background: 'rgba(0, 0, 0, 0.1)',
        }
    },
}))(Dialog);


export default function Onboardings({ uid }) {
    const alert = useAlert();
    const [open, setOpen] = React.useState(true);
    const [pos, setpos] = useState(1);
    const [name, setname] = useState();
    const [isnamechange, setisnamechange] = useState(true);

    const handleClose = () => {
        localStorage.setItem('signup', '0');
        localStorage.setItem(uid, 'secondtime');
        setOpen(false);
    };


    const user = firebase.auth().currentUser;
    var provider;
    if (user !== null) {
        user.providerData.forEach((profile) => {
            provider = profile.providerId;
        });
    }

    const move = () => {
        if (pos === 4) {
            handleClose();
        }
        else {
            if (pos === 1) {
                setpos(2);
            }
            else if (pos === 2) {
                setpos(3);
            }
            else {
                if (provider === "google.com") {
                    setpos(4);
                }
                else {
                    handleClose();
                }
            }
        }
    }

    const fetchinfo = async () => {
        const res = db.collection('users').doc(uid);
        setname((await res.get()).data().name);
    };

    function AvoidSpace(e) {
        if (e.which === 32 || e.key === " ") {
            // console.log('Space Detected');
            e.preventDefault();
        }
    };

    useEffect(() => {
        fetchinfo();
    }, []);

    const handleChangeUserName = (event) => {
        event.preventDefault();
        event.target.value = ("" + event.target.value).toLowerCase();
        setname(event.target.value);
    }

    const avoidCCP = (event) => {
        event.preventDefault();
    }


    const changename = () => {
        setisnamechange(false);
    };
    const savename = async () => {

        db.collection("users").where("name", "==", name)
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    // console.log(doc.id, " => ", doc.data());
                });
            })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
            });

        db.collection("users").where("name", "==", name).get().then(async function (querySnapshot) {
            if (querySnapshot.empty) {
                await db.collection("users").doc(uid).update({ name: name });
                const data = await db.collection("posts").where("postedBy", "==", uid).get();
                await data.docs.forEach(item => {
                    db.collection("posts").doc(item.id).update({ name: name });
                })
                setisnamechange(true);
                let loggedInUser = JSON.parse(localStorage.getItem('user'));
                loggedInUser.name = name;
                localStorage.setItem('user', JSON.stringify(loggedInUser));
            }
            else {
                alert.error(<div style={{ textTransform: 'none', fontSize: "16px", textAlign: "center", padding: "5px" }}>Username Already Exist! Try some other name.</div>)
            }
        });

    };

    if (pos === 1) {
        return (
            <div className='onboarding'>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="content">
                        <DialogContent>
                            <img src={first} className='firstimg' alt='first' />
                        </DialogContent>
                        <div>
                            <FiberManualRecordIcon className='dot' onClick={(e) => setpos(1)} style={{ cursor: 'pointer' }} />
                            <FiberManualRecordIcon className='dot' color='disabled' onClick={(e) => setpos(2)} style={{ cursor: 'pointer' }} />
                            <FiberManualRecordIcon className='dot' color='disabled' onClick={(e) => setpos(3)} style={{ cursor: 'pointer' }} />
                        </div>
                        <NextBtn onClick={move}>Next</NextBtn>
                    </div>

                </Dialog>
            </div>
        );
    }
    else if (pos === 2) {
        return (
            <div className='onboarding'>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="content">

                        <DialogContent>
                            <img src={second} className='firstimg' alt='first' />
                        </DialogContent>
                        <div>
                            <FiberManualRecordIcon className='dot' color='disabled' onClick={(e) => setpos(1)} style={{ cursor: 'pointer' }} />
                            <FiberManualRecordIcon className='dot' onClick={(e) => setpos(2)} style={{ cursor: 'pointer' }} />
                            <FiberManualRecordIcon className='dot' color='disabled' onClick={(e) => setpos(3)} style={{ cursor: 'pointer' }} />
                        </div>
                        <NextBtn onClick={move}>Next</NextBtn>
                    </div>
                </Dialog>
            </div>
        );
    }
    else if (pos === 3) {
        return (
            <div className='onboarding'>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="content">
                        <DialogContent>
                            <img src={third} className='firstimg' alt='first' />
                        </DialogContent>
                        <div>
                            <FiberManualRecordIcon className='dot' color='disabled' onClick={(e) => setpos(1)} style={{ cursor: 'pointer' }} />
                            <FiberManualRecordIcon className='dot' color='disabled' onClick={(e) => setpos(2)} style={{ cursor: 'pointer' }} />
                            <FiberManualRecordIcon className='dot' onClick={(e) => setpos(3)} style={{ cursor: 'pointer' }} />
                        </div>
                        <NextBtn onClick={move}>Let's Go</NextBtn>
                    </div>
                </Dialog>
            </div>
        );
    }

    else {
        if (provider === "google.com") {
            return (
                <div className='onboarding'>
                    <CustomDialog
                        open={open}
                        onClose={handleClose}
                        // open={openUser}
                        // onClose={handleCloseUser}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <div className="content">
                            <div
                                aria-label="close"
                                onClick={handleClose}
                                style={{
                                    color: "rgba(0, 0, 0, 0.54)",
                                    cursor: "pointer",
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "flex-end",
                                    padding: "10px 10px 0 0",
                                }}
                            >
                                <CloseIcon />
                            </div>
                            <DialogTitle id="max-width-dialog-title" style={{ padding: "0 0 10px" }}>Username Confirmation</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Do you want to change or keep this username?
                                </DialogContentText>
                                {/* <h5 className="label">
                                        Username
                                    </h5> */}

                                <input
                                    type="text"
                                    maxlength="20"
                                    minLength='1'
                                    autoComplete="off"
                                    value={name}
                                    className="input-user"
                                    disabled={isnamechange}
                                    onKeyPress={(e) => AvoidSpace(e)}
                                    onChange={(event) => handleChangeUserName(event)}
                                    onCut={avoidCCP}
                                    onCopy={avoidCCP}
                                    onPaste={avoidCCP}
                                />
                                {isnamechange ? (
                                    <Change className='change' onClick={changename}>Change Username</Change>
                                ) : (
                                    <Change className='change' onClick={savename}>Save</Change>
                                )}

                                <Change onClick={move} color="primary" style={{ marginBottom: "20px" }}>
                                    Keep Username
                                </Change>


                            </DialogContent>
                        </div>

                    </CustomDialog >
                </div >
            );
        }

    }
}


const Change = styled.p`
border-radius: 31.55px;
background: #5db0d8;
font-size: 17px;
font-weight: 500;
color: #fff;
border: none;
height: 40px;
width: 100%;
cursor: pointer;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
margin: 15px auto 0;
`;