import React, { useState } from "react";
import NavFront from "./NavFront";
import Search from './Search';
import SearchFolio from './SearchFolio';
import styled from 'styled-components';
import { auth } from "../../../firebase";
import { useHistory } from "react-router";
import ProfileCard from "./ProfileCard";
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Link } from "react-router-dom";
import Onboardings from "../../layouts/First";
import Grid from '@material-ui/core/Grid';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import Hidden from '@material-ui/core/Hidden';
import CatIcon from '../../../assets/images/caticon.png'
import SignInDialog from '../Folios/SignInDialog';

const CategoryList = withStyles((theme) => ({
  root: {
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  }
}))(List);

const CategoryListItemOdd = withStyles((theme) => ({
  root: {
    textDecoration: "none",
    background: "#AFAFAF",
    borderRadius: "0px 100px 100px 0px",
    border: "none",
    width: "137.632px",
    height: "43px",
    marginBottom: "10.725px",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    lineHeight: "21.425px",
    color: "#FFFFFF",
    cursor: "pointer",
    justifyContent: "flex-start",
    '&:hover': {
      background: "#6c6c6c",
    },
    '@media (max-width: 950px)': {
      width: "110px",
      fontSize: "15px",
      // paddingLeft: "8px",
    },
    '@media (max-width: 730px)': {
      width: "125px",
      fontSize: "15px",
    },
  }
}))(ListItem);
const CategoryListItemEven = withStyles((theme) => ({
  root: {
    textDecoration: "none",
    background: "#AFAFAF",
    borderRadius: "100px 0px 0px 100px",
    border: "none",
    width: "137.632px",
    height: "43px",
    marginBottom: "10.725px",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    lineHeight: "21.425px",
    color: "#FFFFFF",
    cursor: "pointer",
    justifyContent: "flex-end",
    '&:hover': {
      background: "#6c6c6c",
    },
    '@media (max-width: 950px)': {
      width: "110px",
      fontSize: "15px",
      // paddingRight: "8px",
    },
    '@media (max-width: 730px)': {
      width: "125px",
      fontSize: "15px",
    },
  }
}))(ListItem);


const SearchedResult = (props) => {
  const history = useHistory();
  const [searchtext, setsearchtext] = useState('');
  const [searchtype, setsearchtype] = useState([]);
  const [navClick, setnavClick] = useState(false);
  const [exacttitle, setexacttitle] = useState('');
  const [searchtitle, setsearchtitle] = useState([]);
  const [searchClicked, setsearchClicked] = useState(false);
  const searcharr = JSON.parse(localStorage.getItem("search"))['search'];
  const category = JSON.parse(localStorage.getItem("search"))['category'];
  const profile = JSON.parse(localStorage.getItem("search"))['profile'];

  const user = auth.currentUser;
  if (localStorage.getItem('startexperience') === '1') {
    window.scrollTo(0, 0);
    localStorage.removeItem('startexperience');
  }
  let uid = 'empty';
  if (user) {
    uid = user.uid;
    // console.log(auth.currentUser.emailVerified, 'emailverify');
  }

  const drawerWidth = 300;

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      fontFamily: "Rubik",
    },
    drawer: {
      marginTop: "500px",
      width: drawerWidth,
      flexShrink: 0,
      fontFamily: "Rubik",
      '@media (max-width: 950px)': {
        width: "230px",
      },
      '@media (max-width: 730px)': {
        width: "0px",
      },
    },
    appBar: {
      marginTop: "90px",
      marginLeft: "5px",
      width: "35px",
      height: "35px",
      display: "flex",
      justifyContent: "center",
      right: "0",
      left: "0",
      boxShadow: "none",
      background: "transparent",
      boxShadow: "none",
      backgroundColor: "transparent",
    },
    menuButton: {
      width: "15px",
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    drawerPaper: {
      marginTop: "88px",
      paddingBottom: "90px",
      width: drawerWidth,
      fontFamily: 'Rubik',
      '@media (max-width: 950px)': {
        width: `calc(${drawerWidth}px - 70px)`,
      },
      '@media (max-width: 730px)': {
        marginTop: "0px",
        paddingTop: "10px",
        paddingBottom: "0px",
        width: `calc(${drawerWidth}px - 30px)`,
      },

    },
    content: {
      marginTop: "85px",
      fontFamily: 'Rubik',
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: "24px 24px",
      '@media (max-width: 368px)': {
        padding: "24px 15px 24px 15px",
      },
    },
    link: {
      textDecoration: 'none',
      color: "black",
      cursor: "pointer",
      '&:hover': {
        textDecoration: 'none',
      },
      '@media (max-width: 950px)': {
        fontSize: "12.8px",
      },
    },
    footer: {
      margin: "214.5px 0px 30px 0px",
      paddingLeft: "24px",
      fontSize: "13px",
      '@media (max-width: 950px)': {
        paddingLeft: "16px",
        fontSize: "12px",
      },
    },
    copyright: {
      textAlign: "center",
      fontSize: "13px",
      '@media (max-width: 950px)': {
        fontSize: "11px",
      },
    },
    notFound: {
      height: "60vh",
      textAlign: "center",
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
    }
  }));


  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  function tileid(x) {
    localStorage.setItem(
      "postid",
      JSON.stringify({
        postid: x,
      })
    );
    history.push(`/folio/${x}`)
  }

  var storedSearches = JSON.parse(localStorage.getItem("search"));

  function unique(data, key) {
    return [
      ...new Map(
        data.map(x => [key(x), x])
      ).values()
    ]
  }
  // console.log(JSON.stringify(unique(storedSearches['search'], it => it.id)));
  // console.log(unique(storedSearches['search'], it => it.id));

  const searcharr2 = unique(storedSearches['search'], it => it.id);
  // console.log(searcharr2);

  React.useEffect(() => {

    window.scrollTo(0, 0);

  }, []);

  const drawer = (
    <div>
      <div><h5 style={{ margin: "20px 0 10px 10px" }}>Categories</h5></div>

      <CategoryList>

        <Link to={{ pathname: '/home', category: 'Geography' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemOdd>Geography</CategoryListItemOdd>
        </Link>

        <Link to={{ pathname: '/home', category: 'Physics' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemEven>Physics</CategoryListItemEven>
        </Link>

        <Link to={{ pathname: '/home', category: 'History' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemOdd>History</CategoryListItemOdd>
        </Link>

        <Link to={{ pathname: '/home', category: 'Spirituality' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemEven>Spirituality</CategoryListItemEven>
        </Link>

        <Link to={{ pathname: '/home', category: 'Engineering' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemOdd>Engineering</CategoryListItemOdd>
        </Link>

        <Link to={{ pathname: '/home', category: 'Psychology' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemEven>Psychology</CategoryListItemEven>
        </Link>

        <Link to={{ pathname: '/home', category: 'Economics' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemOdd>Economics</CategoryListItemOdd>
        </Link>

        <Link to={{ pathname: '/home', category: 'Parenting' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemEven>Parenting</CategoryListItemEven>
        </Link>

        <Link to={{ pathname: '/home', category: 'Chemistry' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemOdd>Chemistry</CategoryListItemOdd>
        </Link>

        <Link to={{ pathname: '/home', category: 'Business' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemEven>Business</CategoryListItemEven>
        </Link>

        <Link to={{ pathname: '/home', category: 'Mystery' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemOdd>Mystery</CategoryListItemOdd>
        </Link>

        <Link to={{ pathname: '/home', category: 'Biology' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemEven>Biology</CategoryListItemEven>
        </Link>

        <Link to={{ pathname: '/home', category: 'Sociology' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemOdd>Sociology</CategoryListItemOdd>
        </Link>

        <Link to={{ pathname: '/home', category: 'Philosophy' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemEven>Philosophy</CategoryListItemEven>
        </Link>

        <Link to={{ pathname: '/home', category: 'Commerce' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemOdd>Commerce</CategoryListItemOdd>
        </Link>

        <Link to={{ pathname: '/home', category: 'Maths' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemEven>Maths</CategoryListItemEven>
        </Link>

        <Link to={{ pathname: '/home', category: 'Statistics' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemOdd>Statistics</CategoryListItemOdd>
        </Link>

        <Link to={{ pathname: '/home', category: 'Lifestyle' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemEven>Lifestyle </CategoryListItemEven>
        </Link>

        <Link to={{ pathname: '/home', category: 'Theater' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemOdd>Theater</CategoryListItemOdd>
        </Link>

        <Link to={{ pathname: '/home', category: 'Design' }} style={{ textDecoration: 'none' }}>
          <CategoryListItemEven>Design</CategoryListItemEven>
        </Link>

      </CategoryList>

      <div style={{ textAlign: "center", fontSize: "13px" }}>
        <Link to="/all" className={classes.link} style={{ cursor: "pointer" }}>All Categories<ChevronRightRoundedIcon style={{ fontSize: "18px" }} /></Link>
      </div>

      <div className={classes.footer}>
        <Grid container spacing={1} >
          <Grid item xs={6}>
            <Link
              to="/homepage"
              className={classes.link} style={{ cursor: "pointer" }}><text>Home Page</text></Link>
          </Grid>
          <Grid item xs={6}>
            <Link to="/aboutus" className={classes.link} style={{ cursor: "pointer" }}><text>About Us</text></Link>
          </Grid>
          <Grid item xs={6}>
            <Link to="/terms" className={classes.link} style={{ cursor: "pointer" }}><text>Terms of Service</text></Link>
          </Grid>

          <Grid item xs={6}>
            <Link to="/faq" className={classes.link} style={{ cursor: "pointer" }}><text>FAQ’s</text></Link>
          </Grid>
          <Grid item xs={6}>
            <Link to="/contact" className={classes.link} style={{ cursor: "pointer" }}><text>Contact</text></Link>
          </Grid>
          <Grid item xs={6}>
            <Link to="/privacy" className={classes.link} style={{ cursor: "pointer" }}><text>Privacy Policy</text></Link>
          </Grid>

        </Grid>
      </div>
      <div className={classes.copyright}>
        Copyright &#169;2022 Kredey
      </div>
    </div >
  );


  return (
    <React.Fragment>
      {localStorage.getItem('signup') === '1' || localStorage.getItem(uid) === 'firsttime' ? <Onboardings uid={uid} /> : null}
      <div>
        <NavFront setnavClick={setnavClick} setsearchClicked={setsearchClicked} setexacttitle={setexacttitle} searchtitle={searchtitle} setsearchtitle={setsearchtitle} user={user} searchtext={searchtext} setsearchtext={setsearchtext} setsearchtype={setsearchtype} />
        {!searchClicked && searchtext !== '' ? <Search exacttitle={exacttitle} searchtitle={searchtitle} setsearchClicked={setsearchClicked} searchtext={searchtext} searchtype={searchtype} /> : null}

      </div>


      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}>

          <img src={CatIcon} alt=""
            style={{ filter: "drop-shadow(10px 10px 12px rgba(0, 0, 0, 0.2))" }}
            className={classes.menuButton}
          />

        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, //Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          {category !== '' ? <Head>{category}</Head> : <Head>Search Results</Head>}
          {profile && profile !== '' ? <ProfileCard name={profile.name} desc={profile.description} follower={profile.follower} profile_pic={profile.profile_pic} profileid={profile.id} /> : null}
          {searcharr2.length !== 0 ?
            searcharr2.map(each => {
              return (<SearchFolio Image={each.data.img_url} title={each.data.title} name={each.data.name} desc={each.data.description} postedby={each.data.postedBy} id={each.id} onclick={tileid} setnavClick={setnavClick} />)
            }) : (<div className={classes.notFound}>No Folios Found</div>)}
        </main>
      </div>

      {navClick ? <SignInDialog open={true} setnavClick={setnavClick} /> : null}

    </React.Fragment>
  )
}

const Head = styled.p`
text-align: left;
font-size: 22px;
  @media (max-width: 950px){
  font-size: 20px;
};
@media (max-width: 500px){
  font-size: 18px;
};
`

export default SearchedResult;