// Core viewer
import React, { useEffect } from 'react';
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "../../../App.css";
import full from "../../../assets/g.svg";
import { useMediaQuery } from "@material-ui/core";
// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { ScrollMode } from "@react-pdf-viewer/scroll-mode";
import {
  SelectionMode,
} from "@react-pdf-viewer/selection-mode";
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import styled from "styled-components";


// Create new plugin instance
const ReactPDFviewer = ({ pdf_t, read_c, id }) => {

  // console.log(pdf_t);
  useEffect(() => {

  }, [pdf_t])

  const isMobile = useMediaQuery((theme) => theme.breakpoints.up("xs"));
  const renderToolbar = (Toolbar) => (

    <Toolbar style={{ margin: "0", padding: "0" }}>
      {(slots) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          SwitchScrollMode,
          ShowSearchPopover,
          GoToFirstPage,
          GoToLastPage,
          Zoom,
          SwitchSelectionMode,
          ZoomIn,
          ZoomOut
        } = slots;

        return (
          <>
            {!isMobile ? (
              <>
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    overflow: "hidden",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <Controls>
                      <ZoomOut />
                    </Controls>

                    <Controls>
                      <ZoomIn />
                    </Controls>
                  </div>

                  <Controls style={{ padding: "0px 0px", fontWeight: "normal" }}>
                    <ShowSearchPopover style={{ fontWeight: "normal" }} />
                  </Controls>


                  <div style={{ display: "flex" }}>
                    <Controls style={{ marginLeft: "auto" }}>
                      <GoToFirstPage />
                    </Controls>
                    <Controls style={{ marginLeft: "auto" }}>
                      <GoToPreviousPage />
                    </Controls>

                    <Controls style={{ display: 'flex', alignItems: "center", padding: "0 0px" }}>
                      <div style={{ width: '47px' }}><CurrentPageInput /> </div>
                      &nbsp;/&nbsp;<NumberOfPages />
                    </Controls>

                    <Controls>
                      <GoToNextPage />
                    </Controls>

                    <Controls style={{ padding: "0px 0px", marginLeft: "auto" }}>
                      <GoToLastPage />
                    </Controls>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ height: "2rem", margin: 0, padding: "0 5px" }}>
                      <Icon onClick={() => window.open('/p/' + id + '/' + pdf_t.slice(8,))} style={{ height: '35.1px', width: '15.33px', cursor: 'pointer' }} />
                    </div>

                    <Controls>
                      <SwitchScrollMode mode={ScrollMode.Horizontal} />
                    </Controls>
                    <Controls>
                      <SwitchScrollMode mode={ScrollMode.Wrapped} />
                    </Controls>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-around",
                    overflow: "hidden",
                    margin: "0",
                    padding: "0"
                  }}
                >
                  <Controls>
                    <ZoomOut />
                  </Controls>

                  <Controls>
                    <Zoom />
                  </Controls>

                  <Controls>
                    <ZoomIn />
                  </Controls>

                  <Controls style={{ marginLeft: "auto" }}>
                    <GoToFirstPage />
                  </Controls>
                  <Controls style={{ marginLeft: "auto" }}>
                    <GoToPreviousPage />
                  </Controls>

                  <Controls style={{ display: 'flex', alignItems: "center", }}>
                    <div style={{ width: '60px' }}><CurrentPageInput /> </div>
                    &nbsp;/&nbsp;<NumberOfPages />
                  </Controls>

                  <Controls>
                    <GoToNextPage />
                  </Controls>
                  <Controls style={{ padding: "0px 2px" }}>
                    <ShowSearchPopover />
                  </Controls>
                  <Controls style={{ padding: "0px 2px", marginLeft: "auto" }}>
                    <GoToLastPage />
                  </Controls>




                  <div style={{ padding: "0px 10px", marginLeft: "auto" }}>
                    <Icon onClick={() => window.open('/p/' + id + '/' + pdf_t.slice(8,))} style={{ height: '17px', cursor: 'pointer' }} />
                  </div>


                  <Controls>
                    <SwitchScrollMode mode={ScrollMode.Horizontal} />
                  </Controls>
                  <Controls>
                    <SwitchScrollMode mode={ScrollMode.Wrapped} />
                  </Controls>

                  <Controls>
                    <SwitchSelectionMode mode={SelectionMode.Text} />
                  </Controls>
                  <Controls>
                    <SwitchSelectionMode mode={SelectionMode.Hand} />
                  </Controls>
                </div>
              </>
            )}
          </>

        );
      }}
    </Toolbar>

  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (defaultTabs) => [
      defaultTabs[1], // Bookmarks tab
      defaultTabs[2], // Attachment tab
    ],
    toolbarPlugin: {
      scrollModePlugin: {
        scrollMode: ScrollMode.Horizontal
      },
    },

  });
  const fullScreenPluginInstance = fullScreenPlugin();
  const { EnterFullScreen } = fullScreenPluginInstance;

  return (
    <div
      style={{
        overflowX: "hidden",
        boxSizing: "border-box",
        height: '710px',
      }}
    >

      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js" >
        <div style={{ height: "710px" }}>
          {pdf_t ?
            <Viewer
              onPageChange={(e) => read_c(e.currentPage, e.doc.numPages)}
              // defaultScale={SpecialZoomLevel.ActualSize}
              defaultScale={0.8}
              fileUrl={pdf_t}
              plugins={[defaultLayoutPluginInstance, fullScreenPluginInstance]}

            /> : null
          }
        </div>
      </Worker>

    </div >
  );
};

const Icon = styled.img.attrs(props => ({
  src: full
}))`
  height: 21px;
  width: 21px;
`;

const Controls = styled.div`
  padding: 0px 1px;
  font-size: 15px;
  @media (max-width: 1000px){
  padding: 0px 0px;
  font-size: 13px;
  };
`;

export default ReactPDFviewer;
