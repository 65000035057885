import React, { useState, useEffect } from "react";
import Tile from "./Tile";
import { useHistory } from "react-router";
import { db } from "../../../firebase";
import SignInDialog from '../Folios/SignInDialog';
import SearchFolio from './SearchFolio';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
    border: "none",
    outline: "0",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  main: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "center",
    margin: "auto",
    '@media (max-width: 2560px)': {
      width: `calc(173px*12)`,
    },
    '@media (max-width: 2424px)': {
      width: `calc(173px*11)`,
    },
    '@media (max-width: 2251px)': {
      width: `calc(173px*10)`,
    },
    '@media (max-width: 2078px)': {
      width: `calc(173px*9)`,
    },
    '@media (max-width: 1904px)': {
      width: `calc(173px*8)`,
    },
    '@media (max-width: 1731px)': {
      width: `calc(173px*7)`,
    },
    '@media (max-width: 1559px)': {
      width: `calc(173px*6)`,
    },
    '@media (max-width: 1386px)': {
      width: `calc(173px*5)`,
    },
    '@media (max-width: 1212px)': {
      width: `calc(173px*4)`,
    },
    '@media (max-width: 1040px)': {
      width: `calc(173px*3)`,
    },
    '@media (max-width: 797px)': {
      width: `calc(165px*3)`,
    },
    '@media (max-width: 730px)': {
      width: `calc(165px*4)`,
    },
    '@media (max-width: 660px)': {
      width: `calc(165px*3)`,
    },
    '@media (max-width: 495px)': {
      width: `calc(165px*2)`,
    },
    '@media (max-width: 370px)': {
      width: `calc(157px*2)`,
    },
  },
  grid: {
    justifyContent: "left",
    margin: "auto",
  },
}));
const Main = (props) => {
  const history = useHistory();
  const [post, Setpost] = useState([]);
  const fetchBlogs = async () => {

    const response = db.collection("posts");
    const data = await response.get();
    let arr = [];
    data.docs.forEach(item => {
      var data = item.data();
      data = { ...data, id: item.id }

      arr.push(data);
      // console.log(arr);
    })

    if (props.category) {
      arr = arr.filter((obj) => obj.category === props.category);
      Setpost(arr);
    }
    else {
      Setpost(arr);
      props.setallposts(arr);
    }
  }
  useEffect(() => {

    fetchBlogs();
    // var abc = document.getElementById('main');
    document.addEventListener('scroll', (event) => {
      localStorage.setItem("scrollpos", window.scrollY);
    })
  }, []);

  var y = localStorage.getItem("scrollpos");
  window.scrollTo(0, y);

  function tileid(x) {
    localStorage.setItem(
      "postid",
      JSON.stringify({
        postid: x,
      })
    );
    // history.push(`/folio/${x}`)
    window.location.href = `/folio/${x}`;
    // window.location.replace(`/folio/${x}`);
    // window.location.reload();
  }

  const classes = useStyles();

  const customTheme = createTheme({
    breakpoints: {
      keys: ["xs", "sm", "md", "lg", "xl", "xxl"],
      values: { xs: 480, sm: 730, md: 950, lg: 1350, xl: 1920 }
    }
  });

  return (
    <div id="main">
      <div className={classes.main}>
        <h5 style={{ margin: "0 0 18px 12px" }}>{typeof (props.category) === 'undefined' ? 'All Folios' : props.category}</h5>

        <ThemeProvider theme={customTheme}>

          <Grid container className={classes.grid}>
            {props.searchClicked === false ? post && post.filter(obj => !props.category ? true : props.category === obj.category).map(p => {
              return (
                // xs: 0, sm: 480, md: 730, lg: 950, xl: 1280
                <Grid item
                // className={classes.gridItem}
                // xxl={1} xl={2} lg={2} md={3} sm={4} xs={4}
                >
                  <Tile Title={p.title} Image={p.img_url} id={p.id} onclick={tileid} Name={p.name} read={p.read_Count} setnavClick={props.setnavClick} />
                </Grid>
              );
            })
              :
              props.searchtitle.length !== 0 ?
                props.searchtitle.map(each => {
                  return (<SearchFolio Image={each.data.img_url} title={each.data.title} name={each.data.name} desc={each.data.description} id={each.id} onclick={tileid} />)
                })
                :
                null
            }
          </Grid>
        </ThemeProvider>
      </div>


      {props.navClick ? <SignInDialog open={true} setnavClick={props.setnavClick} /> : null}
    </div >
  );
};

export default Main;