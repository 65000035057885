import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import brand from "../../assets/images/logos/logo2.png";
import google from "../../assets/google.png";
import { auth, db } from "../../firebase";
import firebase from "../../firebase";
import { userContext } from "../../App";
import '../../signin.css';
import NotverifiedModal from "./NotverifiedModal";
import ForgotPasswordModal from "./ForgotPasswordModal";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const genUsername = require("unique-username-generator");


const SignIn = (props) => {
  window.scrollTo(0, 0);
  const { state, dispatch } = useContext(userContext);
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setshowError] = useState('');
  const [requiredError, setrequiredError] = useState('');
  const [open, setopen] = useState(false);
  const [forgotpassword, setforgotpassword] = useState(false);

  const onSigninClick = () => {
    if (email === '' || password === '') {
      setrequiredError('*Required');
      setshowError('');
    }
    else {
      setrequiredError('');
    }
  }

  const handleGoogleAuthentication = async () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    try {
      await auth.signInWithPopup(provider);
      let exist = false;
      await db.collection('users').get().then(
        snapshot => snapshot.docs.map(
          doc => {
            if (doc.data().email === auth.currentUser.email) {
              exist = true;
            }
          }
        )
      )
      if (!exist) {
        const username = genUsername.generateUsername("-", 0, 20);

        var randomLength = Math.floor((Math.random() * 10) + 1);
        function randomString(length) {
          var result = '';
          var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          var charactersLength = characters.length;
          for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
              charactersLength));
          }
          return result;
        }

        const userProfPic = `https://avatars.dicebear.com/api/adventurer-neutral/:${randomString(randomLength)}.svg`;

        db.collection("users").doc(auth.currentUser.uid).set({
          email: auth.currentUser.email,
          name: username,
          follower: [],
          follower_Count: 0,
          saved: [],
          profilePic: `${userProfPic}`,
          description: "No Description to show",
          noti: true,
        });

        localStorage.setItem(auth.currentUser.uid, 'firsttime');
      }

      var docRef = db.collection("users").doc(auth.currentUser.uid);
      const data2 = await docRef.onSnapshot((querySnapshot) => {
        if (querySnapshot.exists) {
          const username = querySnapshot.data().name;
          const follower = querySnapshot.data().follower;
          const follower_Count = querySnapshot.data().follower_Count;
          const saved = querySnapshot.data().saved;
          const noti = querySnapshot.data().noti;
          const userData = {
            uid: auth.currentUser.uid,
            email: auth.currentUser.email,
            name: username,
            follower: follower,
            follower_Count: follower_Count,
            saved: saved,
            noti: noti,
          };
          localStorage.setItem("user", JSON.stringify(userData));
          localStorage.setItem('allowed', '1');
          dispatch({ type: "USER_SIGNIN", payload: userData });
        }
      });

      // console.log(data);
      history.push("/home"); //Redirect to home after signed in sucessfully
    } catch (err) {
      console.log(err);
    }
  };

  //Network request for signIn user
  const handleLoggedInCredentials = async (e) => {
    e.preventDefault();
    try {
      setshowError('');
      const signIn = await auth.signInWithEmailAndPassword(
        email.trim(),
        password
      );
      if (auth.currentUser.emailVerified) {
        const res = db.collection('users').doc(auth.currentUser.uid);
        const username = (await res.get()).data().name;
        const follower = (await res.get()).data().follower;
        const follower_Count = (await res.get()).data().follower_Count;
        const saved = (await res.get()).data().saved;
        const noti = (await res.get()).data().noti;
        const userData = {
          uid: auth.currentUser.uid,
          email: auth.currentUser.email,
          name: username,
          follower: follower,
          follower_Count: follower_Count,
          saved: saved,
          noti: noti,

        };
        // console.log("USER SIGN IN SUCCESSFULLY");
        localStorage.setItem("user", JSON.stringify(userData));
        localStorage.setItem('allowed', '1');
        dispatch({ type: "USER_SIGNIN", payload: userData });
        history.push("/home");
      }
      else {
        setopen(true);
      }
    } catch (err) {
      if (err.code === 'auth/user-not-found' || err.code === 'auth/wrong-password') {
        setshowError('Email Address or Password Incorrect');
      }
    }
  };

  const forgotPassword = async () => {
    auth.onAuthStateChanged(async (user) => {
      auth
        .sendPasswordResetEmail(email)
        .then(function () {
          setforgotpassword(true);
          setrequiredError('');
        })
        .catch(function (error) {
          console.log(error);
          if (error.code === 'auth/invalid-email') {
            setrequiredError('*Email Required');
          }
        });
    });
  };

  const [isActive, setActive] = useState("false");

  const handleClickShowPassword = (e) => {
    setActive(!isActive);
    e.preventDefault();
    document.getElementsByClassName("input")[1].focus();
    var pass = document.querySelector('#password');
    if (pass.type === "password") {
      pass.type = "text";
    } else {
      pass.type = "password";
    }
  };

  React.useEffect(() => {
    document.title = "SignIn | Kredey | Publish and Read Amazing Documents"
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      {/* Logo here */}
      <div className='comlogo'>
        <img src={brand} onClick={(e) => window.location.href = '/'} />
      </div>

      {/* Main Content */}
      <div className='content'>
        {open ? <NotverifiedModal open={true} setOpen={setopen} /> : null}
        {forgotpassword ? <ForgotPasswordModal open={true} setforgotpassword={setforgotpassword} /> : null}
        {requiredError !== '' ? requiredError === '*Email Required' ? <p className="requiredError" >{requiredError}</p> : <p className='requiredError'>{requiredError}</p> : null}
        {!requiredError ? <p className='error'>{showError}</p> : null}
        <div>
          <form onSubmit={(e) => handleLoggedInCredentials(e)}>
            <div>

              <h2 className='heading'>Sign In</h2>

              <div>
                <label style={{ cursor: 'default' }}>Email Address <span>*</span></label>
              </div>

              <input className='input' aria-label="email" aria-required="true" autoCapitalize="off" autoCorrect="off" maxLength="75" name="email" type="email" required onChange={(e) => setEmail(e.target.value)} />

              <div>
                <label style={{ cursor: 'default' }}>Password <span>*</span></label>
              </div>

              <input className='input' id="password" aria-label="Password" aria-required="true" autoCapitalize="off" autoCorrect="off" name="password" type="password" required
                onChange={(e) => setPassword(e.target.value)}
                style={{ paddingRight: "60px" }}
              />
              <span className="icon" onClick={(e) => handleClickShowPassword(e)}>
                {isActive ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </span>

            </div>
            <div>
              <button className='signin-button' onClick={onSigninClick}>SIGN IN</button>
            </div>
            <button className='google-button' onClick={handleGoogleAuthentication}>
              <span>
                <img src={google} />
              </span>
              <span
                className='continue'
                style={{
                  fontFamily: "Rubik",
                }}
              >
                Continue With Google
              </span>
            </button>
            <p style={{ cursor: 'default' }}><span className='forgotpassword' onClick={forgotPassword}>Forgot password ?</span></p>

            <div className='or'>
              <hr className='hr-left' />
              <div className='or-text' style={{ fontFamily: "Rubik" }}>OR</div>
              <hr className='hr-left' />
            </div>

            <Link to="signup">
              {" "}
              <button className='create-account-button'>CREATE ACCOUNT</button>
            </Link>
          </form>
        </div>


      </div>

    </React.Fragment>
  );
};


const Button = styled.button`
  border-radius: 31.55px;
  background: #3e6ae1;
  font-family: Rubik;
  font-size: 17px;
  font-weight: 500;
  color: white;
  border: none;
  width: 26.35vw;
  height: 7vh;
  padding: 1.3vh 1.1vw;
  margin-bottom: 3vh;
  cursor: pointer;
`;
export {
  Button
};
export default SignIn;
