import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import styled from "styled-components";
import NavFront from "../Front/NavFront";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Footer from "../../layouts/Footer";
import { auth } from '../../../firebase';
import Search from '../Front/Search';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3),
    },
    heading: {
        fontSize: "22px",
        marginLeft: "30px",
        marginBottom: "30px",
        '@media (max-width: 988px)': {
            marginLeft: "20px",
        },
        '@media (max-width: 908px)': {
            marginLeft: "10px",
        },
        '@media (max-width: 828px)': {
            fontSize: "20px",
        },
        '@media (max-width: 552px)': {
            fontSize: "18px",
        },
    },
    alpha: {
        color: '#5DB0D8',
        marginBottom: "10px",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "22px",
        '@media (max-width: 828px)': {
            fontSize: "20px",
        },
        '@media (max-width: 552px)': {
            fontSize: "18px",
        },
    },
    pLink: {
        fontSize: "16px",
        textDecoration: 'none',
        display: "block",
        color: 'black',
        '&:hover': {
            textDecoration: "none",
            cursor: "pointer",
        },
        '@media (max-width: 828px)': {
            fontSize: "14px",
        },
        '@media (max-width: 552px)': {
            fontSize: "12px",
        },
    },
    main: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "center",
        margin: "auto",

        '@media (max-width: 2560px)': {
            width: `calc(247px*9)`,
        },
        '@media (max-width: 2223px)': {
            width: `calc(247px*8)`,
        },
        '@media (max-width: 1976px)': {
            width: `calc(247px*7)`,
        },
        '@media (max-width: 1729px)': {
            width: `calc(247px*6)`,
        },
        '@media (max-width: 1482px)': {
            width: `calc(247px*5)`,
        },
        '@media (max-width: 1235px)': {
            width: `calc(247px*4)`,
        },
        '@media (max-width: 988px)': {
            width: `calc(227px*4)`,
        },
        '@media (max-width: 908px)': {
            width: `calc(207px*4)`,
        },
        '@media (max-width: 828px)': {
            width: `calc(184px*4)`,
        },
        '@media (max-width: 736px)': {
            width: `calc(184px*3)`,
        },
        '@media (max-width: 552px)': {
            width: `calc(160px*3)`,
        },
        '@media (max-width: 480px)': {
            width: `calc(160px*2)`,
        },

    },
    grid: {
        display: 'flex',
        flexDirection: "row",
        margin: "auto",

    },
    gridItem: {
        width: "187px",
        margin: "0 30px 50px",
        '@media (max-width: 988px)': {
            margin: "0 20px 50px",
        },
        '@media (max-width: 908px)': {
            margin: "0 10px 50px",
        },
        '@media (max-width: 736px)': {
            margin: "0 10px 30px",
        },
        '@media (max-width: 828px)': {
            width: "164px",
        },
        '@media (max-width: 552px)': {
            width: "140px",
        },
    },
    divider: {
        height: "5px",
        margin: 0,
        flexShrink: 0,
    }
}));

const AllCatergory = (props) => {
    window.scrollTo(0, 0);
    const user = auth.currentUser;
    let uid = 'empty';
    if (user) {
        uid = user.uid;
        // console.log(auth.currentUser.emailVerified, 'emailverify');
    }
    const [searchtext, setsearchtext] = useState('');
    const [searchtype, setsearchtype] = useState([]);
    const [navClick, setnavClick] = useState(false);
    const [exacttitle, setexacttitle] = useState('');
    const [searchtitle, setsearchtitle] = useState([]);
    const [searchClicked, setsearchClicked] = useState(false);




    useEffect(() => {
        document.title = "All Categories | Kredey | Publish and Read Amazing Documents"
    }, []);





    const classes = useStyles();
    return (
        <React.Fragment>

            {/* <NavFront /> */}

            <NavFront setnavClick={setnavClick} setsearchClicked={setsearchClicked} setexacttitle={setexacttitle} searchtitle={searchtitle} setsearchtitle={setsearchtitle} searchtext={searchtext} setsearchtype={setsearchtype} setsearchtext={setsearchtext} user={user} />
            {!searchClicked && searchtext !== '' ? <Search exacttitle={exacttitle} searchtitle={searchtitle} setsearchClicked={setsearchClicked} searchtype={searchtype} searchtext={searchtext} /> : null}

            <Main>

                <div className={classes.main}>

                    <p className={classes.heading}>All Categories</p>
                    <Grid container className={classes.grid}>
                        <Grid item className={classes.gridItem}>
                            <div>
                                <p className={classes.alpha}>A</p>
                                <Link to={{ pathname: '/home', category: 'Agriculture' }} className={classes.pLink}>Agriculture</Link>
                                <Link to={{ pathname: '/home', category: 'Anthropology' }} className={classes.pLink}>Anthropology</Link>
                                <Link to={{ pathname: '/home', category: 'Architecture' }} className={classes.pLink}>Architecture</Link>
                            </div>
                        </Grid>

                        <Grid item className={classes.gridItem}>
                            <div>
                                <p className={classes.alpha}>B</p>
                                <Link to={{ pathname: '/home', category: 'Biology' }} className={classes.pLink}>Biology</Link>
                                <Link to={{ pathname: '/home', category: 'Botany' }} className={classes.pLink}>Botany</Link>
                                <Link to={{ pathname: '/home', category: 'Business' }} className={classes.pLink}>Business</Link>
                            </div>
                        </Grid>

                        <Grid item className={classes.gridItem}>
                            <div>
                                <p className={classes.alpha}>C</p>
                                <Link to={{ pathname: '/home', category: 'Chemistry' }} className={classes.pLink}>Chemistry</Link>
                                <Link to={{ pathname: '/home', category: 'Comics' }} className={classes.pLink}>Comics</Link>
                                <Link to={{ pathname: '/home', category: 'Commerce' }} className={classes.pLink}>Commerce</Link>
                                <Link to={{ pathname: '/home', category: 'Computer' }} className={classes.pLink}>Computer</Link>
                                <Link to={{ pathname: '/home', category: 'Cooking' }} className={classes.pLink}>Cooking</Link>
                                <Link to={{ pathname: '/home', category: 'Critical Thinking' }} className={classes.pLink}>Critical Thinking</Link>
                            </div>
                        </Grid>

                        <Grid item className={classes.gridItem}>
                            <div>
                                <p className={classes.alpha}>D</p>
                                <Link to={{ pathname: '/home', category: 'Design' }} className={classes.pLink}>Design</Link>
                            </div>
                        </Grid>

                        <Grid item className={classes.gridItem}>
                            <div>
                                <p className={classes.alpha}>E</p>
                                <Link to={{ pathname: '/home', category: 'Economics' }} className={classes.pLink}>Economics</Link>
                                <Link to={{ pathname: '/home', category: 'Engineering' }} className={classes.pLink}>Engineering</Link>
                                <Link to={{ pathname: '/home', category: 'English' }} className={classes.pLink}>English</Link>
                                <Link to={{ pathname: '/home', category: 'Enterpreneurship' }} className={classes.pLink}>Enterpreneurship</Link>
                                <Link to={{ pathname: '/home', category: 'Enviornmental Science' }} className={classes.pLink}>Environmental Science</Link>
                            </div>
                        </Grid>

                        <Grid item className={classes.gridItem}>
                            <div>
                                <p className={classes.alpha}>F</p>
                                <Link to={{ pathname: '/home', category: 'Fantasy' }} className={classes.pLink}>Fantasy</Link>
                                <Link to={{ pathname: '/home', category: 'Fashion' }} className={classes.pLink}>Fashion</Link>
                                <Link to={{ pathname: '/home', category: 'Finance' }} className={classes.pLink}>Finance</Link>
                                <Link to={{ pathname: '/home', category: 'Fine Arts' }} className={classes.pLink}>Fine Arts</Link>
                                <Link to={{ pathname: '/home', category: 'Food and Nutrition' }} className={classes.pLink}>Food and Nutrition</Link>
                            </div>
                        </Grid>

                        <Grid item className={classes.gridItem}>
                            <div>
                                <p className={classes.alpha}>G</p>
                                <Link to={{ pathname: '/home', category: 'General Science' }} className={classes.pLink}>General Science</Link>
                                <Link to={{ pathname: '/home', category: 'Geography' }} className={classes.pLink}>Geography</Link>
                                <Link to={{ pathname: '/home', category: 'Geology' }} className={classes.pLink}>Geology</Link>
                            </div>
                        </Grid>

                        <Grid item className={classes.gridItem}>
                            <div>
                                <p className={classes.alpha}>H</p>
                                <Link to={{ pathname: '/home', category: 'Health and Hygiene' }} className={classes.pLink}>Health and Hygiene</Link>
                                <Link to={{ pathname: '/home', category: 'Historical Fiction' }} className={classes.pLink}>Historical Fiction</Link>
                                <Link to={{ pathname: '/home', category: 'History' }} className={classes.pLink}>History</Link>
                                <Link to={{ pathname: '/home', category: 'Home Science' }} className={classes.pLink}>Home Science</Link>
                                <Link to={{ pathname: '/home', category: 'Horror' }} className={classes.pLink}>Horror</Link>
                                <Link to={{ pathname: '/home', category: 'Humor' }} className={classes.pLink}>Humor</Link>
                            </div>
                        </Grid>


                        <Grid item className={classes.gridItem}>
                            <div>
                                <p className={classes.alpha}>L</p>
                                <Link to={{ pathname: '/home', category: 'Law' }} className={classes.pLink}>Law</Link>
                                <Link to={{ pathname: '/home', category: 'Lifestyle' }} className={classes.pLink}>Lifestyle</Link>
                                <Link to={{ pathname: '/home', category: 'Literature' }} className={classes.pLink}>Literature</Link>
                            </div>
                        </Grid>

                        <Grid item className={classes.gridItem}>
                            <div>
                                <p className={classes.alpha}>M</p>
                                <Link to={{ pathname: '/home', category: 'Management' }} className={classes.pLink}>Management</Link>
                                <Link to={{ pathname: '/home', category: 'Maths' }} className={classes.pLink}>Maths</Link>
                                <Link to={{ pathname: '/home', category: 'Medical Science' }} className={classes.pLink}>Medical Science</Link>
                                <Link to={{ pathname: '/home', category: 'Mental Health' }} className={classes.pLink}>Mental Health</Link>
                                <Link to={{ pathname: '/home', category: 'Music' }} className={classes.pLink}>Music</Link>
                                <Link to={{ pathname: '/home', category: 'Mystery' }} className={classes.pLink}>Mystery</Link>
                            </div>
                        </Grid>

                        <Grid item className={classes.gridItem}>
                            <div>
                                <p className={classes.alpha}>N</p>
                                <Link to={{ pathname: '/home', category: 'Non Fiction' }} className={classes.pLink}>Non Fiction</Link>
                            </div>
                        </Grid>

                        <Grid item className={classes.gridItem}>
                            <div>
                                <p className={classes.alpha}>P</p>
                                <Link to={{ pathname: '/home', category: 'Parenting' }} className={classes.pLink}>Parenting</Link>
                                <Link to={{ pathname: '/home', category: 'Personality Development' }} className={classes.pLink}>Personality Development</Link>
                                <Link to={{ pathname: '/home', category: 'Philosophy' }} className={classes.pLink}>Philosophy</Link>
                                <Link to={{ pathname: '/home', category: 'Physical Education' }} className={classes.pLink}>Physical Education</Link>
                                <Link to={{ pathname: '/home', category: 'Physics' }} className={classes.pLink}>Physics</Link>
                                <Link to={{ pathname: '/home', category: 'Poetry' }} className={classes.pLink}>Poetry</Link>
                                <Link to={{ pathname: '/home', category: 'Political Science' }} className={classes.pLink}>Political Science</Link>
                                <Link to={{ pathname: '/home', category: 'Psychology' }} className={classes.pLink}>Psychology</Link>
                                <Link to={{ pathname: '/home', category: 'Public Administration' }} className={classes.pLink}>Public Administration</Link>
                            </div>
                        </Grid>


                        <Grid item className={classes.gridItem}>
                            <div>
                                <p className={classes.alpha}>R</p>
                                <Link to={{ pathname: '/home', category: 'Romance' }} className={classes.pLink}>Romance</Link>
                            </div>
                        </Grid>

                        <Grid item className={classes.gridItem}>
                            <div>
                                <p className={classes.alpha}>S</p>
                                <Link to={{ pathname: '/home', category: 'Sci-Fi' }} className={classes.pLink}>Sci-Fi</Link>
                                <Link to={{ pathname: '/home', category: 'Social Science' }} className={classes.pLink}>Social Science</Link>
                                <Link to={{ pathname: '/home', category: 'Sociology' }} className={classes.pLink}>Sociology</Link>
                                <Link to={{ pathname: '/home', category: 'Spirituality' }} className={classes.pLink}>Spirituality</Link>
                                <Link to={{ pathname: '/home', category: 'Statistics' }} className={classes.pLink}>Statistics</Link>
                            </div>
                        </Grid>

                        <Grid item className={classes.gridItem}>
                            <div>
                                <p className={classes.alpha}>T</p>
                                <Link to={{ pathname: '/home', category: 'Theater' }} className={classes.pLink}>Theater</Link>
                                <Link to={{ pathname: '/home', category: 'Thriller' }} className={classes.pLink}>Thriller</Link>
                            </div>
                        </Grid>

                        <Grid item className={classes.gridItem}>
                            <div>
                                <p className={classes.alpha}>Y</p>
                                <Link to={{ pathname: '/home', category: 'Young Adult' }} className={classes.pLink}>Young Adult</Link>
                            </div>
                        </Grid>

                        <Grid item className={classes.gridItem}>
                            <div>
                                <p className={classes.alpha}>Z</p>
                                <Link to={{ pathname: '/home', category: 'Zoology' }} className={classes.pLink}>Zoology</Link>
                            </div>
                        </Grid>
                    </Grid>
                </div>

            </Main>
            <hr style={{ margin: 0, height: "0.8px" }} />
            {/* Footer */}
            <Footer />

        </React.Fragment>
    )
}
// =========================Styled components =================
const Main = styled.div`
display:flex;
flex-direction:column;
justify-content:space-between;
margin:auto;
font-family: 'Rubik';
padding-top: 110px;
padding-bottom: 150px;
background: white;
`
export default AllCatergory;