import React from "react";
import "../../section.css";
import Grid from '@material-ui/core/Grid';

const Section = () => {
    const gotoHome = () => {
        window.location.href = '/home';
        localStorage.setItem('startexperience', '1');
    }
    return (
        <div className="section_main">
            <section className="showcase">
                <Grid container>
                    <Grid item xs={6} className="showcase-img img-2"></Grid>
                    <Grid item xs={6} style={{ margin: "auto" }}>
                        <div className="head sp-r">
                            <p style={{ cursor: "default" }}>Read Amazing<br />Folios And Learn<br />New Things</p>
                        </div>
                    </Grid>
                </Grid>
            </section>

            <section className="showcase">
                <Grid container>
                    <Grid item xs={6} style={{ margin: "auto" }}>
                        <div className="head sp-l">
                            <p style={{ cursor: "default" }}>Spread Your Reach By Uploading Interesting Folios And Connect To Millions Of Readers Directly</p>
                        </div>
                    </Grid>
                    <Grid item xs={6} className="showcase-img img-3"></Grid>
                </Grid>
            </section>

            <section className="showcase">
                <Grid container>
                    <Grid item xs={6} className="showcase-img img-4"></Grid>
                    <Grid item xs={6} style={{ margin: "auto" }}>
                        <div className="head sp-r">
                            <p style={{ cursor: "default" }}>Easily Share And<br />Discuss Folios With<br />Your Friends</p>
                        </div>
                    </Grid>
                </Grid>
            </section>

            <button onClick={gotoHome} className="exp" >Start Experiencing</button>
        </div>
    )
}
export default Section;