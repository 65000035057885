import React, { useState, useEffect } from "react";
import styled from "styled-components";
import NavFront from "../Front/NavFront";
import Tile from "../Front/Tile";
import { auth, db } from "../../../firebase";
import firebase from "../../../firebase";
import { useHistory } from "react-router";
import PublishIcon from '@material-ui/icons/Publish';
import CancelIcon from '@material-ui/icons/Cancel';
import SimpleModal from './SimpleModal';
import ProfileTile from '../Front/ProfileTile';
import Search from '../Front/Search';
import { makeStyles } from "@material-ui/core/styles";
import Footer from "../../layouts/Footer";
import { useMediaQuery } from "@material-ui/core";
import { useAlert } from 'react-alert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SignInDialog from './SignInDialog';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import { Helmet } from "react-helmet";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    fontFamily: "Rubik",
  },
  content: {
    marginTop: "85px",
    fontFamily: 'Rubik',
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "50px 24px 110px",
    alignItems: "center",
    '@media (max-width: 362px)': {
      padding: "50px 12px",
    },
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: "0 20px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "20px",
    border: "none",
    outline: "0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  icon: {
    margin: '5px auto',
    cursor: 'pointer',
  },
  closeicon: {
    cursor: "pointer",
    marginTop: "-24px",
    marginLeft: "auto",
    marginRight: "-20px",
  },
  CnUIconDiv: {
    position: "absolute",
    width: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    margin: "0 20px",
    '@media (max-width: 543px)': {
      margin: "0 13px",
    },
    '@media (max-width: 400px)': {
      margin: "0 5px",
    },
  },
  scrollIcon: {
    padding: "0",
    margin: "-50px 4px 0 auto",
    zIndex: "1",
    opacity: "0.5",
  }
}));

function getIdFromUrl(url) {
  const baseUrl = "https://www.kredey.com/user/";
  let Path = url.replace(baseUrl, "");
  return Path;
}

const User = () => {


  const id = getIdFromUrl(window.location.href);

  // const id = JSON.parse(localStorage.getItem("profileid"))['profileid'];
  var uid;
  if (localStorage.getItem("allowed") === "0") {
    // uid= id;
  } else {
    uid = JSON.parse(localStorage.getItem("user"))['uid'];
  }
  const history = useHistory();
  function tileclick(x) {

    localStorage.setItem(
      "postid",
      JSON.stringify({
        postid: x,

      })
    );
    history.push(`/folio/${x}`)
  }

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };


  const [post, setpost] = useState([]);
  const [name, setname] = useState();

  function getPathStorageFromUrl(url) {
    const baseUrl = "https://firebasestorage.googleapis.com/v0/b/website-1-1-b7be3.appspot.com/o/";
    let imagePath = url.replace(baseUrl, "");
    const indexOfEndPath = imagePath.indexOf("?");
    imagePath = imagePath.substring(0, indexOfEndPath);
    imagePath = imagePath.replace("%2F", "/");
    imagePath = imagePath.replace("%2F", "/");

    return imagePath;
  }


  async function delete_folio(z) {

    var docRef = db.collection("posts").doc(z);
    await docRef.onSnapshot((querySnapshot) => {

      if (querySnapshot.exists) {
        var imageURL = querySnapshot.data().img_url;
        var pdfURL = querySnapshot.data().pdf_url;

        var imgPath = getPathStorageFromUrl(imageURL);
        var pdfPath = getPathStorageFromUrl(pdfURL);

        firebase.storage().ref().child(imgPath).delete().then(function () {
          // File deleted successfully
          // console.log("Img File Deleted from storage")
        }).catch(function (error) {
          // Some Error occurred
          console.log(error);
        });

        firebase.storage().ref().child(pdfPath).delete().then(function () {
          // File deleted successfully
          // console.log("Pdf File Deleted from storage")
        }).catch(function (error) {
          // Some Error occurred
          console.log(error);
        });
      }

    })

    await db.collection("posts").doc(z).collection("conversations").get().then((querySnapshot) => {
      querySnapshot.forEach(async (doc) => {
        // console.log(db.collection("posts").doc(z).collection("conversations").doc(`${doc.id}`));
        if (doc.exists) {
          db.collection("posts").doc(z).collection("conversations").doc(`${doc.id}`).collection("replies").get().then((querySnapshot) => {
            querySnapshot.forEach(async (docx) => {
              // console.log(db.collection("posts").doc(z).collection("conversations").doc(`${doc.id}`));
              if (docx.exists) {
                db.collection("posts").doc(z).collection("conversations").doc(`${doc.id}`).collection("replies").doc(`${docx.id}`).delete().then(function () {
                  // File deleted successfully
                  // console.log("Replies Collec deleted from database of pid")
                }).catch(function (error) {
                  // Some Error occurred
                  console.log(error);
                });
              }
            })
          }
          )

          db.collection("posts").doc(z).collection("conversations").doc(`${doc.id}`).delete().then(function () {
            // File deleted successfully
            // console.log("Convo Collec deleted from database of pid")
          }).catch(function (error) {
            // Some Error occurred
            console.log(error);
          });

        }
      });
    });

    await db.collection("posts").doc(z).delete().then(function () {
      // File deleted successfully
      // console.log("Post Deleted from database")
    }).catch(function (error) {
      // Some Error occurred
      console.log(error);
    });

    alert.success(<div style={{ textTransform: 'none', fontSize: "16px", textAlign: "center", padding: "5px" }}>Folio Deleted Successfully!</div>)

  }

  const [count, setCount] = useState(0);
  const [read, setRead] = useState(0);
  const [follower, setFollower] = useState(0);
  const [allowEdit, setallowEdit] = useState(false);
  const [editname, seteditname] = useState(false);
  const [removePhoto, setremovePhoto] = useState(false);
  const [profilePhoto, setprofilePhoto] = useState();
  const [publishPhoto, setpublishPhoto] = useState(false);
  const [desc, setdesc] = useState("");
  const [imgUrl, setimageUrl] = useState(null);
  const [isfollow, setisfollow] = useState();
  const [followList, setfollowlist] = useState();
  const [searchtext, setsearchtext] = useState('');
  const [searchtype, setsearchtype] = useState([]);
  const [navClick, setnavClick] = useState(false);
  const [allposts, setallposts] = useState([]);
  const [exacttitle, setexacttitle] = useState('');
  const [searchtitle, setsearchtitle] = useState([]);
  const [searchClicked, setsearchClicked] = useState(false);
  const [test, setTest] = useState(false);
  const [verticalScroll, setVerticalScroll] = useState(false);
  const [openDialog, setopenDialog] = useState(false);

  const fetchBlogs = async () => {
    // console.log(id);
    const response = db.collection("posts").where("postedBy", "==", id).orderBy("date_created", "desc");
    const data = await response.onSnapshot((querySnapshot) => {
      const arr = [];
      var c = 0;
      var r = 0;
      querySnapshot.forEach((doc) => {
        var data = doc.data();
        c = c + 1;
        r = r + doc.data().read_Count;
        data = { ...data, id: doc.id }

        arr.push(data);


      })

      setpost(arr);
      setCount(c);
      setRead(r);
    });
    var docRef = db.collection("users").doc(id);
    const data2 = await docRef.onSnapshot((querySnapshot) => {

      if (querySnapshot.exists) {
        var follow = 0; var profile_pic; var name, desc;
        follow = querySnapshot.data().follower_Count;
        profile_pic = querySnapshot.data().profilePic;
        name = querySnapshot.data().name;
        desc = querySnapshot.data().description;
        var f_arr = querySnapshot.data().follower;
        setfollowlist(querySnapshot.data().follower);
        if (f_arr.indexOf(uid) > -1) {
          setisfollow(true)
        }
        else {
          setisfollow(false);
        }
        setprofilePhoto(profile_pic)
        setFollower(follow);
        setname(name);
        setdesc(desc);


        if (id === uid) {

          // console.log(f_arr);

          // var tempid = [];
          // db.collection("users").get().then((querySnapshot) => {
          //   querySnapshot.forEach((doc) => {
          //     var x = db.collection("users").doc(`${doc.id}`)['id'];
          //     tempid.push(x);
          //   });
          // });
          // console.log(tempid);


          if (f_arr.length > 0) {
            for (let i = 0; i < f_arr.length; i++) {
              var tempid = [];
              const element = f_arr[i];
              // console.log(element);
              // console.log(tempid.includes(element));
              db.collection("users").get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  var x = db.collection("users").doc(`${doc.id}`)['id'];
                  // console.log(x);
                  tempid.push(x);
                });
                // console.log("ids exists in database: ", tempid);
                if (tempid.includes(element)) {
                  console.log("true");
                } else {
                  console.log("false");
                  var array = f_arr;
                  // console.log("Before: ", array);
                  const index = array.indexOf(element);
                  if (index > -1) {
                    array.splice(index, 1); // 2nd parameter means remove one item only
                  }
                  // console.log("After: ", array);
                  db.collection("users").doc(uid).update({ follower: array });
                  db.collection("users").doc(uid).update({ follower_Count: array.length });
                }
              });

            }
          }
        }

      }

    });
  }
  useEffect(() => {

    fetchBlogs();
    window.scrollTo(0, 0);

  }, [id]);


  const Tofollow = async () => {
    if (auth.currentUser === null) {
      setopenDialog(true);
    } else {
      let arr = [...followList];
      arr.push(uid);
      await db.collection("users").doc(id).update({ follower: arr });
      await db.collection("users").doc(id).update({ follower_Count: arr.length });
      setisfollow(true);
    }
  }
  const Tounfollow = async () => {
    let arr = [...followList];
    arr = arr.filter(e => e !== uid);
    await db.collection("users").doc(id).update({ follower: arr });
    await db.collection("users").doc(id).update({ follower_Count: arr.length });
    setisfollow(false);
  }


  var randomLength = Math.floor((Math.random() * 10) + 1);
  function randomString(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  const userProfPic = `https://avatars.dicebear.com/api/adventurer-neutral/:${randomString(randomLength)}.svg`;


  let profPic = userProfPic;

  const remove = () => {
    setremovePhoto(true);
    setprofilePhoto(userProfPic);
    setimageCropped(false);
    db.collection("users").doc(uid).update({ profilePic: profPic });
    firebase.storage().ref().child(`users/${uid}`).delete().then(function () {
      // File deleted successfully
      // console.log("profile pic img Deleted from storage")
    }).catch(function (error) {
      // Some Error occurred
      console.log(error);
    });

    // var docRef = db.collection("posts").doc(z);
    // await docRef.onSnapshot((querySnapshot) => {

    //   if (querySnapshot.exists) {
    //     var imageURL = querySnapshot.data().img_url;
    //     var pdfURL = querySnapshot.data().pdf_url;

    //     var imgPath = getPathStorageFromUrl(imageURL);
    //     var pdfPath = getPathStorageFromUrl(pdfURL);

    //     firebase.storage().ref().child(imgPath).delete().then(function () {
    //       // File deleted successfully
    //       console.log("Img File Deleted from storage")
    //     }).catch(function (error) {
    //       // Some Error occurred
    //       console.log(error);
    //     });

    //     firebase.storage().ref().child(pdfPath).delete().then(function () {
    //       // File deleted successfully
    //       console.log("Pdf File Deleted from storage")
    //     }).catch(function (error) {
    //       // Some Error occurred
    //       console.log(error);
    //     });
    //   }

    // })

  }

  const Edit = () => {
    if (auth.currentUser.uid !== null) {
      const uid = auth.currentUser.uid;
      setallowEdit(true);
      seteditname(false);
    }
  }



  const [imageCropped, setimageCropped] = useState(false);
  const [image, setImage] = useState(null);
  const types = ["image/png", "image/jpeg", "image/jpg"];
  const [fileselect, setfileselect] = useState(null);

  var getFileBlob = function (url, cb) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.addEventListener('load', function () {
      cb(xhr.response);
    });
    xhr.send();
  };




  const handleChange = (e) => {


    var fileSize = e.target.files[0].size / 1024 / 1024;
    if (fileSize <= 3) {
      // console.log("Size within limits");
      // console.log(e.target.files[0].size / 1024 / 1024)
      // console.log(e.target.files[0]);

      seteditname(false);
      let selectedFile = e.target.files[0];
      setfileselect(URL.createObjectURL(selectedFile));
      if (selectedFile) {
        // console.log('a')
        if (types.includes(selectedFile.type)) {
          setImage(selectedFile);
        } else {
          // console.log('b')
          setImage(null);
        }
      }
    }
    else {
      handleModalOpen();
      // console.log("Size exceed limits");
      // console.log(e.target.files[0].size / 1024 / 1024)
    }


  };




  useEffect(() => {
    if (!fileselect)
      setTest(false);
    else
      setTest(!test);

  }, [fileselect])

  const alert = useAlert();

  const save = async () => {

    setallowEdit(false);
    setremovePhoto(false);
    if (imageCropped) {
      const uid = auth.currentUser.uid;
      await getFileBlob(fileselect, blob => {
        firebase.storage().ref(`/users/${uid}`).put(blob).then(snap => {
          snap.ref.getDownloadURL().then((url) => {
            setimageUrl(url);
            db.collection("users").doc(uid).update({ profilePic: url });
          });
        })
      })

    }

    if (desc == '') {
      setdesc("No Description to show");
      await db.collection("users").doc(uid).update({ description: "No Description to show" });
    } else {
      await db.collection("users").doc(uid).update({ description: desc });
    }

    if (editname == true) {
      db.collection("users").where("name", "==", name).get().then(async function (querySnapshot) {
        if (querySnapshot.empty) {
          await db.collection("users").doc(uid).update({ name: name });

          const data = await db.collection("posts").where("postedBy", "==", uid).get();
          await data.docs.forEach(item => {
            db.collection("posts").doc(item.id).update({ name: name });
          })


          //for updating names in convo collection
          db.collection("posts").get().then((querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
              db.collection("posts").doc(`${doc.id}`)
                .collection("conversations").get().then((querySnapshot) => {
                  querySnapshot.forEach(async (res) => {
                    db.collection("posts").doc(`${doc.id}`).collection("conversations").where("uid", "==", uid).get().then(async function (querySnapshot) {
                      querySnapshot.forEach(async (docx) => {
                        if (docx.exists) {
                          db.collection("posts").doc(`${doc.id}`).collection("conversations").doc(`${docx.id}`).update({ name: name });
                        }
                      })
                    })
                  })
                })
            });
          });
        }

        else {
          setallowEdit(true);
          alert.success(<div style={{ textTransform: 'none', fontSize: "16px", textAlign: "center", padding: "5px" }}>Username Already Exist! Try some other name.</div>)
        }
      })
    }

  }


  const mediaMatch = window.matchMedia('(max-width: 815px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  const isMobile = useMediaQuery((theme) => theme.breakpoints.up("xs"));

  function AvoidSpace(e) {
    if (e.which == 32 || e.key === " ") {
      // console.log('Space Detected');
      e.preventDefault();
    }
  };

  const handleChangeUserName = (event) => {
    event.preventDefault();
    event.target.value = ("" + event.target.value).toLowerCase();
    setname(event.target.value);
    seteditname(true);
  }

  const avoidCCP = (event) => {
    event.preventDefault();
  }

  const handleClose = async () => {
    var docRef = db.collection("users").doc(id);
    const data2 = await docRef.onSnapshot((querySnapshot) => {
      setname(querySnapshot.data().name);
      setdesc(querySnapshot.data().description);
    })
    // console.log(name);
    setallowEdit(false);
  }

  const handleDesc = (e) => {
    setdesc(e.target.value);
  }

  useEffect(() => {
    var div = document.getElementById('desc');
    var vs = div.scrollHeight > div.clientHeight;

    if (vs) {
      setVerticalScroll(true);
    }
    else {
      setVerticalScroll(false);
    }
  });

  // useEffect(() => {
  //   document.title = `${name} | ${desc} | Kredey | Publish and Read Amazing Documents`
  // }, [name, desc]);


  return (
    <>


      <Helmet>

        {/* <!-- HTML Meta Tags --> */}
        <title>{`${name}`} | {desc} | Kredey | Publish and Read Amazing Documents</title>
        <meta name="description" content={desc} />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content={name + " | " + desc + " | Kredey | Publish and Read Amazing Documents"} />
        <meta itemprop="description" content={desc} />
        <meta itemprop="image" content="" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://www.kredey.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={name + " | " + desc + " | Kredey | Publish and Read Amazing Documents"} />
        <meta property="og:description" content={desc} />
        <meta property="og:image" content="" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={name + " | " + desc + " | Kredey | Publish and Read Amazing Documents"} />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:image" content="" />

      </Helmet>


      <div>
        {/* Navbar */}
        <NavFront
          setnavClick={setnavClick}
          setsearchClicked={setsearchClicked}
          setexacttitle={setexacttitle}
          searchtitle={searchtitle}
          setsearchtitle={setsearchtitle}
          searchtext={searchtext}
          setsearchtext={setsearchtext}
          setsearchtype={setsearchtype}
        />
        {!searchClicked && searchtext !== "" ? (
          <Search
            exacttitle={exacttitle}
            searchtitle={searchtitle}
            setsearchClicked={setsearchClicked}
            searchtype={searchtype}
            searchtext={searchtext}
          />
        ) : null}
      </div>

      <div className={classes.root}
        style={{ minHeight: "100vh" }}
      >
        <main className={classes.content}>

          <Detail>
            <input
              type="file"
              id='input-file'
              name="image"
              accept="image/x-png,image/gif,image/jpeg,image/jpg"
              onChange={handleChange}
              style={{ visibility: 'hidden', position: 'absolute' }}
            />

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              onClose={handleModalClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div className={classes.paper}>
                  <h5 id="spring-modal-title" style={{ marginBottom: "15px" }}>Sorry! Upload failed<br />Size of the Image must not exceed 3MB.</h5>
                  <div className={classes.root}>
                    <Button variant="contained" onClick={() => { handleModalClose() }}>
                      OK
                    </Button>
                  </div>
                </div>
              </Fade>
            </Modal>


            {uid === id ?
              <SideContent>
                {imageCropped ? <Image style={{ backgroundImage: `url(${fileselect})` }}
                // profilePhoto={fileselect}
                /> : <Image style={{ backgroundImage: `url(${profilePhoto})` }}
                //  profilePhoto={profilePhoto}
                />}

                {allowEdit && profilePhoto !== profPic ?

                  <div className={classes.CnUIconDiv}>
                    <CancelIcon color='secondary' className={classes.icon} onClick={remove} />
                    <label fileselect={fileselect} for='input-file' style={{ width: "50%", textAlign: "center" }}>
                      <PublishIcon onClick={(e) => setpublishPhoto(true)} color='primary' className={classes.icon} />
                    </label>
                  </div> : null}


                {allowEdit ? <Button onClick={save}>Save</Button> : <Button onClick={Edit}>Edit</Button>}

                {publishPhoto & test === true ?

                  <SimpleModal
                    fileselect={fileselect}
                    setfileselect={setfileselect}
                    imageCropped={imageCropped}
                    setimageCropped={setimageCropped}
                    width={!isMobile ? (294.94) : (375.61)}
                    height={!isMobile ? (345.5) : (440)}
                    setTest={setTest} /> : null}

              </SideContent> :

              <SideContent>
                <Image style={{ backgroundImage: `url(${profilePhoto})` }} />
                {isfollow === true ?
                  <Button onClick={Tounfollow}>Following</Button>
                  : <Button onClick={Tofollow}>Follow</Button>}
              </SideContent>}

            <Bio>
              {allowEdit ? <CancelIcon color='black' className={classes.closeicon} onClick={handleClose} /> : null}
              <Name
                id="username"
                required
                maxLength='20'
                minLength='1'
                value={name}
                disabled={!allowEdit}
                onKeyPress={(e) => AvoidSpace(e)}
                onChange={(event) => handleChangeUserName(event)}
                onCut={avoidCCP}
                onCopy={avoidCCP}
                onPaste={avoidCCP}
              />

              <Lines id="desc" maxLength='130' minLength='1' rows='5' disabled={!allowEdit} value={desc} onChange={handleDesc}
                onFocus={e => e.target.value == "No Description to show" ? e.target.value = '' : null}
              ></Lines>
              {verticalScroll ? <ExpandMoreIcon className={classes.scrollIcon} /> : null}

              <Tabs>
                <Tab>Folios<span >{count}</span></Tab>
                <Tab>Total Reads<span >{read}</span></Tab>
                <Tab>Followers<span >{follower}</span></Tab>
              </Tabs>
            </Bio>
          </Detail>
          <Hr />

          <TileSec
            // style={{width: `calc(173px * ${count})`}}
            style={{ width: matches ? (`calc(165px * ${count})`) : (`calc(173px * ${count})`) }}
          >
            {post &&
              post.map(p => {
                return (
                  <>
                    {uid === id ?
                      <ProfileTile Title={p.title} Image={p.img_url} id={p.id} onclick={tileclick} read={p.read_Count} deleted={delete_folio} setnavClick={setnavClick} /> : <Tile Title={p.title} Image={p.img_url} id={p.id} onclick={tileclick} Name={p.name} read={p.read_Count} setnavClick={setnavClick} />}
                  </>
                );
              })}
          </TileSec>
        </main>
      </div>
      <hr style={{ margin: 0, height: "0.8px" }} />
      <Footer />

      {
        openDialog ? (
          <SignInDialog
            open={true}
            from={"Profile"}
            setopenDialog={setopenDialog}
          />
        ) : null
      }

    </>
  )
}

export default User;

const Detail = styled.div`
display:flex;
flex-direction: row;
`

const SideContent = styled.div`
display:flex;
flex-direction:column;
margin: 0 10px;
justify-content:space-between;
@media (max-width: 543px){
margin: 0 5px;
};
`

const Image = styled.div`
width: 140px;
height: 164px;
margin-bottom: 15px;
border-radius:20px;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
@media (max-width: 543px){
    width: 126.34px;
    height: 148px;
};
@media (max-width: 400px){
    width: 110px;
    height: 128.86px;
};
`

const Button = styled.button`
width: 140px;
height: 40px;
border: none;
color: white;
background: #5db0d8;
border-radius: 18px;
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 19px;
text-align: center;
@media (max-width: 543px){
    font-size: 16px;
    width: 126.34px;
    height: 35px;
};

@media (max-width: 400px){
  width: 110px;
};

`

const Bio = styled.div`
display:flex;
flex-direction:column;
justify-content: space-between;
margin: 0 10px;
@media (max-width: 543px){
    margin: 0 5px;
    };
`
const Name = styled.input`
// margin-bottom: 10px;
text-overflow: ellipsis;
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 20px;
color: black;
background-color:#fff;
border: 1px solid black;
padding: 0 5px;
// &:focus{
//     border: none;
//     outline: none;
// }
&:disabled{
    
    border: none;
}
&::placeholder{
    color: black;
}

@media (max-width: 543px){
    font-size: 18px;
};

@media (max-width: 400px){
  width: 190px;
    font-size: 16px;
};


`
const Lines = styled.textarea`
resize: none;
width: 320px;
font-size: 16px;
padding: 5px;
color: black;
margin-bottom: 20px;
background-color:#fff;
text-align: justify;
border: 1px solid black;
&:disabled{
   
    border: none;
}
&::placeholder{
    color: black;
}

@media (max-width: 543px){
    font-size: 14px;
    width: 220px;
};
@media (max-width: 400px){
    font-size: 12px;
    width: 190px;
};

`
const Tabs = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    color: black;
    padding: 0 5px;
    @media (max-width: 400px){
        width: 190px;
    };
`

const Tab = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    color: #7D7B7B;
    font-size: 12px;
    text-align: center;
    @media (max-width: 543px){
        font-size: 11px;
    };
    

    span{
    margin-top: 10px;
    color:black;
    font-weight: 500;
    font-size: 16px;
    @media (max-width: 543px){
        font-size: 14px;
    };
    @media (max-width: 400px){
        font-size: 12px;
    };
    }

`

const Hr = styled.hr`
margin: 30px 0;
height: 1px;
color: #5DB0D8;
width: 100%;
`

const TileSec = styled.div`
display:flex;
flex-direction:row;
flex-wrap: wrap;
margin: 0 auto;
max-width: 100%;

@media (max-width: 543px){
      max-width: calc(165px*2);
};
@media (max-width: 370px){
      max-width: calc(157px*2);
};
`