import React from 'react'
import '../../navbar.css'
import Brand from '../../assets/images/logos/logo2.png'
import Logo from '../../assets/images/logos/logo.png'
import { auth } from '../../firebase';
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        fontFamily: "Rubik"
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        border: "none",
        outline: "0",
    },
}));

const ColorButton = withStyles((theme) => ({
    root: {
        color: "white",
        width: "100px",
        height: "40px",
        border: "none",
        color: "white",
        borderRadius: "18px",
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "normal",
        textAlign: "center",
    },
}))(Button);

const Navbar = () => {
    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = React.useState(false);


    const gotoHome = () => {
        if (localStorage.getItem('allowed') === '1') {
            window.location.href = '/home'
        }
        else {
            window.location.href = '/';
        }
    }
    const gotoSignIn = () => {
        window.location.href = '/signin'
    }

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const logOut = () => {
        auth.signOut();
        localStorage.removeItem("user");
        history.push("/home");
        localStorage.setItem("allowed", "0");
        localStorage.setItem("signup", "0");
    };

    return (
        <div className="navbar">
            <div className="brand">
                {/* Brand */}
                <img src={Brand} alt="brand-logo" onClick={gotoHome} style={{ cursor: 'pointer' }} />
            </div>
            <div className="logo">
                {/* Logo */}
                <img src={Logo} alt="logo" onClick={gotoHome} style={{ cursor: 'pointer' }} />
            </div>
            {localStorage.getItem("allowed") == 1 ?
                <button className="btn1" onClick={handleOpen}>Sign Out</button>
                : <button className="btn1" onClick={gotoSignIn}>Sign In</button>}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h5 id="spring-modal-title">Are you sure you want to Log Out?</h5>
                        <div className={classes.root} style={{ justifyContent: "space-evenly", marginTop: "20px" }}>
                            <ColorButton variant="contained" style={{ background: "#5db0d8" }} onClick={() => { logOut() }}>
                                Yes
                            </ColorButton>
                            <ColorButton variant="contained" color="secondary" onClick={() => { handleClose() }}>No</ColorButton>
                        </div>
                    </div>
                </Fade>
            </Modal>

        </div>
    )
}

export default Navbar;