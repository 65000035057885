import React, { useState } from "react";
import styled from "styled-components";
import user2 from "../../../assets/user.png";
import { useHistory } from "react-router-dom";
import { db, auth } from "../../../firebase";
import InputBase from '@material-ui/core/InputBase';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import SignInDialog from '../Folios/SignInDialog';
import BrandLogo from '../../../assets/images/logos/logo2.png'
import Logo from '../../../assets/images/logos/logo.png'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MaterialLink from '@material-ui/core/Link';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import '../../../hideOnScroll.css';

import {
  useMediaQuery,
  Button,
  MenuItem,
} from "@material-ui/core";

const SearchBar = withStyles((theme) => ({
  root: {
    "font-size": "16px",
    fontFamily: 'Rubik',
    "padding": "0 20px",
    "color": "#a6a6a6",
    "max-width": "520px",
    "flexGrow": "1",
    "border": "3px solid #000000",
    "border-radius": "31.5px",
    "box-sizing": "border-box",
    "height": "43px",
    '& input': {
      textAlign: "left"
    },
    '& input::placeholder': {
      textAlign: "center"
    },
    '& input:focus::placeholder': {
      color: "transparent"
    },
    '@media (max-width: 1145px)': {
      "font-size": "15px",
    },
    '@media (max-width: 955px)': {
      "font-size": "14px",
      "height": "40px",
    },
    '@media (max-width: 815px)': {
      "font-size": "13px",
      "height": "38px",
    },
    '@media (max-width: 730px)': {
      "max-width": "305px",
      "height": "36px",
    },
    '@media (max-width: 510px)': {
      "font-size": "12px",
      "padding": "0 10px",
      "height": "30px",
    },
    '@media (max-width: 415px)': {
      height: "32px",
      marginLeft: "5vw",
      "padding": "0 8px",
      "border": "2.4px solid #000000",
    },
  },
}))(InputBase);

const NavLink = withStyles((theme) => ({
  root: {
    "text-decoration": "none",
    display: "block",
    "color": "black",
    "font-size": "16px",
    fontFamily: 'Rubik',
    "margin": "0 30px",
    fontWeight: "500",
    letterSpacing: "0.15px",
    lineHeight: "17px",
    '&:hover': {
      "text-decoration": "none",
      color: "#5db0d8",
      cursor: "pointer"
    },
    '@media (max-width: 1145px)': {
      "margin": "0 20px",
    },
    '@media (max-width: 955px)': {
      "margin": "0 15px",
    },
    '@media (max-width: 815px)': {
      "font-size": "14px",
    },
    '@media (max-width: 730px)': {
      display: "none"
    },
  },
}))(MaterialLink);

const useStyles = makeStyles((theme) => ({
  Klogo: {
    margin: "0 5px 0 30px",
    cursor: "pointer",
    width: "45px",
    height: "45px",
    '@media (max-width: 1145px)': {
      margin: "0 5px 0 20px",
    },
    '@media (max-width: 955px)': {
      margin: "0 5px 0 15px",
      width: "40px",
      height: "40px",
    },
    '@media (max-width: 815px)': {
      width: "35px",
      height: "35px",
    },
    '@media (max-width: 510px)': {
      margin: "0 5px 0 10px",
      width: "30px",
      height: "30px",
    },
    '@media (max-width: 415px)': {
      margin: "0 5px 0 15px",
      width: "40px",
      height: "40px",
    },
  },
  KredayLogo: {
    margin: "0 30px 0 5px",
    cursor: "pointer",
    width: "120px",
    height: "22.55px",
    '@media (max-width: 1145px)': {
      margin: "0 20px 0 5px",
    },
    '@media (max-width: 955px)': {
      margin: "0 15px 0 5px",
    },
    '@media (max-width: 510px)': {
      margin: "0 10px 0 5px",
      width: "90px",
      height: "17px",
    },
    '@media (max-width: 415px)': {
      display: "none"
    },
  },
  toggleIcon: {
    display: "none",
    '@media (max-width: 730px)': {
      display: "block"
    },
  },
  paper: {
    fontFamily: 'Rubik',
    marginRight: theme.spacing(0),
  },
  navbar: {
    fontFamily: 'Rubik',
    position: "fixed",
    transition: "top 0.3s",
  }
}));

const NavFront = (props) => {
  let history = useHistory();
  const [searcharr, setsearcharr] = useState();
  const [prof, setprof] = useState([]);
  const [profile, setprofile] = useState();
  const [category, setcategory] = useState("");
  const [openDialog, setopenDialog] = useState(false);
  const categories = [
    "agriculture",
    "anthropology",
    "architecture",
    "biology",
    "botany",
    "business",
    "design",
    "economics",
    "engineering",
    "english",
    "enterpreneurship",
    "environmentalscience",
    "fantasy",
    "fashion",
    "finearts",
    "finance",
    "food",
    "generalscience",
    "geography",
    "geology",
    "health",
    "history",
    "horror",
    "humor",
    "law",
    "lifestyle",
    "literature",
    "management",
    "maths",
    "medicalscience",
    "mentalhealth",
    "music",
    "mystery",
    "nonfiction",
    "parenting",
    "personalitydevelopment",
    "philosophy",
    "physicaleducation",
    "physics",
    "poetry",
    "politicalscience",
    "psychology",
    "publicadministration",
    "romance",
    "sci-fi",
    "socialscience",
    "sociology",
    "spirituality",
    "statistics",
    "theater",
    "thriller",
    "youngadult",
    "zoology",
  ];


  const searchInput = React.useRef(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const gotoHome = () => {
    if (localStorage.getItem('allowed') === '1') {
      window.location.href = '/home'
    }
    else {
      window.location.href = '/';
    }

  }


  const checkProfile = () => {
    if (auth.currentUser === null) {
      setopenDialog(true);
    }
    else {
      // localStorage.setItem("profileid", JSON.stringify({ profileid: auth.currentUser.uid }));
      window.location.href = `/user/${auth.currentUser.uid}`;
    }
  }

  const checkSaved = () => {
    if (auth.currentUser === null) {
      setopenDialog(true);
    }
    else {
      window.location.href = '/saved';
    }
  }

  const checkPublish = () => {
    if (auth.currentUser === null) {
      setopenDialog(true);
    }
    else {
      window.location.href = '/publish';
    }
  }

  const checkSettings = () => {
    if (auth.currentUser === null) {
      setopenDialog(true);
    }
    else {
      window.location.href = '/settings';
    }
  }

  if (document.activeElement === searchInput.current) {
    // console.log('open')
  }
  else {
    //console.log('closed')
  }


  const textChange = (e) => {
    let arr = [];
    let arr2 = [];
    let temparr = [];
    searchInput.current.focus();
    props.setsearchtext(e.target.value);

    const users = db.collection('users').get().then((docs) => {
      docs.forEach((doc) => {
        temparr.push({ name: doc.data().name, description: doc.data().description, profilePic: doc.data().profilePic, follower_Count: doc.data().follower_Count, id: doc.id })
      })
      setprof(temparr);
      // console.log(temparr);

    });

    db.collection("posts")
      .get()
      .then((docs) => {
        prof.forEach((p) => {
          // console.log(p.name, p.id, p.description, p.profilePic, p.follower_Count);
          if (p.name && p.name.replace(/ /g, "").toLowerCase().includes(e.target.value.replace(/ /g, "").toLowerCase())) {
            arr2.push({ title: p.name, tag: "Profile", type: "Profile", id: p.id })
            if (p.name.replace(/ /g, "").toLowerCase() === e.target.value.replace(/ /g, "").toLowerCase()) {
              let prof;
              if (p.profilePic !== "") {
                prof = p.profilePic;
              }
              else {
                prof = user2;
              }
              setprofile({ name: p.name, description: p.description, follower: p.follower_Count, profile_pic: prof, id: p.id });
            }
          }
        })
        categories.forEach((cat) => {
          if (cat.replace(/ /g, "").toLowerCase().includes(e.target.value.replace(/ /g, "").toLowerCase())) {
            if (cat.replace(/ /g, "").toLowerCase() === e.target.value.replace(/ /g, "").toLowerCase()) {
              setcategory(cat);
            }
            arr2.push({ title: cat, tag: "Category", type: "Category" })

          }
        })
        docs.forEach((doc) => {
          // console.log(
          //   doc.data().title.includes(e.target.value),
          //   e.target.value,
          //   doc.data().title
          // );
          if (
            doc
              .data()
              .title.toLowerCase()
              .includes(e.target.value.toLowerCase())
          ) {
            if (
              doc.data().title.toLowerCase() === e.target.value.toLowerCase()
            ) {
              props.setexacttitle(doc.data());

            } else {
              props.setexacttitle("");
            }
            //props.setsearchtype('folio');
            arr.push({ data: doc.data(), id: doc.id });
            arr2.push({ title: doc.data().title, tag: doc.data().name, type: "Folio", id: doc.id })
          }


          if (
            doc
              .data()
              .category.replace(/ /g, "").toLowerCase()
              .includes(e.target.value.replace(/ /g, "").toLowerCase())
          ) {

            props.setexacttitle("");

            arr.push({ data: doc.data(), id: doc.id });
            arr2.push({ title: doc.data().title, tag: "Folio", type: "Folio", id: doc.id })
          }

          if (
            doc
              .data()
              .name.replace(/ /g, "").toLowerCase()
              .includes(e.target.value.replace(/ /g, "").toLowerCase())
          ) {

            props.setexacttitle("");

            arr.push({ data: doc.data(), id: doc.id });
            arr2.push({ title: doc.data().title, tag: "Profile", type: "Folio", id: doc.id })
          }
        });
        // console.log(prof)


        props.setsearchtitle(arr);
        setsearcharr(arr);
        props.setsearchtype(arr2);
        // console.log(arr2);
      });
  };
  const keyEnter = (e) => {
    // console.log(e.key);
    if (e.key === 'Enter') {
      props.setsearchClicked(true);
      props.setsearchClicked(true);
      localStorage.setItem("search", JSON.stringify({ search: searcharr, category: category, profile: profile }));
      history.push("/searchresults");
      document.getElementById('SearchBox').value = '';
      document.getElementById('SearchBox').blur();
      props.setsearchtext('');
      window.location.reload();
    }
  }

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }


  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);


  React.useEffect(() => {

    var doc = document.documentElement;
    var w = window;

    var curScroll;
    var prevScroll = w.scrollY || doc.scrollTop;
    var curDirection = 0;
    var prevDirection = 0;

    var header = document.getElementById('site-header');
    var toggled;
    var threshold = 100;

    var checkScroll = function () {
      curScroll = w.scrollY || doc.scrollTop;
      if (curScroll > prevScroll) {
        curDirection = 2;
      }
      else {
        curDirection = 1;
      }

      if (curDirection !== prevDirection) {
        toggled = toggleHeader();
      }

      prevScroll = curScroll;
      if (toggled) {
        prevDirection = curDirection;
      }
    };

    var toggleHeader = function () {
      toggled = true;
      if (curDirection === 2 && curScroll > threshold) {
        header.classList.add('hide');
        if (window.innerWidth < 730) {
          // console.log(window.innerWidth);
          setOpen(false);
          var s = document.getElementById('SearchBox');
          if (s) {
            s.value = '';
            s.blur();
            props.setsearchtext('');
          }
        }
      }
      else if (curDirection === 1) {
        header.classList.remove('hide');
      }
      else {
        toggled = false;
      }
      return toggled;
    };

    window.addEventListener('scroll', checkScroll);

  }, [])

  return (
    <>
      {!isMobile ? (
        <Nav style={{ "font-family": "Rubik" }} className={classes.navbar}
          id="site-header"
        >

          <div>
            <img src={Logo} className={classes.Klogo} onClick={gotoHome} />
            <img src={BrandLogo} className={classes.KredayLogo} onClick={gotoHome} />
          </div>

          <SearchBar
            id="SearchBox"
            type="text"
            style={{ outline: 'none', color: 'black', fontFamily: "FontAwesome,Rubik" }}
            placeholder="&#xF002; &nbsp;Search Amazing Folios"
            value={props.searchtext}
            onChange={textChange}
            // onFocus={(e) => e.target.readOnly = false}
            ref={searchInput}
            onKeyPress={keyEnter}
            // readOnly
            autoComplete="off"
          />

          {/* <div> */}
          <ButtonGroup>
            {/* <NavLink onClick={checkSignIn}>
                Chats
              </NavLink> */}
            <NavLink id="navlink" onClick={checkProfile}>
              My Profile
            </NavLink>

            <NavLink id="navlink" onClick={checkSaved}>
              Saved
            </NavLink>

            <NavLink id="navlink" onClick={checkPublish}>
              Publish
            </NavLink>

            <NavLink id="navlink" onClick={checkSettings}>
              Settings
            </NavLink>

          </ButtonGroup>
          {/* </div> */}

          <div className={classes.toggleIcon} >
            <Button
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <MenuRoundedIcon />
            </Button>
            <Popper open={open} role={undefined} transition disablePortal style={{ top: "85px", left: "calc(100% - 110px)" }} >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                        <MenuItem onClick={checkProfile}>
                          My Profile
                        </MenuItem>

                        <MenuItem onClick={checkSaved}>
                          Saved
                        </MenuItem>

                        <MenuItem onClick={checkPublish}>
                          Publish
                        </MenuItem>

                        <MenuItem onClick={checkSettings}>
                          Settings
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Nav >
      ) : (<>
        <Nav style={{ "font-family": "Rubik" }} className={classes.navbar}>

          <div>
            <img src={Logo} className={classes.Klogo} onClick={gotoHome} />
            <img src={BrandLogo} className={classes.KredayLogo} onClick={gotoHome} />
          </div>

          <SearchBar
            id="SearchBox"
            type="text"
            style={{ outline: 'none', color: 'black', "font-family": "FontAwesome,Rubik" }}
            placeholder="&#xF002; &nbsp;Search Amazing Folios"
            value={props.searchtext}
            onChange={textChange}
            // onFocus={(e) => e.target.readOnly = false}
            ref={searchInput}
            onKeyPress={keyEnter}
            autoComplete="off"
          // readOnly
          />


          {/* <div> */}
          <ButtonGroup>
            {/* <NavLink onClick={checkSignIn}>
                Chats
              </NavLink> */}
            <NavLink id="navlink" onClick={checkProfile}>
              My Profile
            </NavLink>

            <NavLink id="navlink" onClick={checkSaved}>
              Saved
            </NavLink>

            <NavLink id="navlink" onClick={checkPublish}>
              Publish
            </NavLink>

            <NavLink id="navlink" onClick={checkSettings}>
              Settings
            </NavLink>

          </ButtonGroup>
          {/* </div> */}

        </Nav >
      </>)}

      {
        openDialog ? (
          <SignInDialog
            open={true}
            from={"Profile"}
            setopenDialog={setopenDialog}
          />
        ) : null
      }
    </>



  );
};

// ========================Styling Components============================================
const Nav = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 20%);
  flex-direction: row;
  z-index: 1;
  overflow-x: hidden;
  width: 100%;
  height: 85px;
  justify-content: space-between;
`;

export default NavFront;
