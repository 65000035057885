import React from 'react';
import '../../tagline.css'

const TagLine = () => {

    return (
        <div className="bgImg" >
            <div className="sec1">
                <h1 className="tag-h1">The New Social Way Of Reading and Sharing Documents</h1>
            </div>
        </div >
    )
}

export default TagLine;