import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NavFront from "../Front/NavFront";
import ItemSaved from "./ItemSaved";
import { useHistory } from "react-router";
import { db } from "../../../firebase";
import Search from "../Front/Search";
import { makeStyles } from '@material-ui/core/styles';
import Footer from "../../layouts/Footer";
import { Helmet } from "react-helmet";


const Saved = () => {
  const [post, Setpost] = useState([]);
  const [key, setkey] = useState(false);
  const [deleted, setDeleted] = useState();
  const [savedId, setsavedId] = useState();
  const [searchtext, setsearchtext] = useState("");
  const [searchtype, setsearchtype] = useState([]);
  const [navClick, setnavClick] = useState(false);
  const [exacttitle, setexacttitle] = useState("");
  const [searchtitle, setsearchtitle] = useState([]);
  const [searchClicked, setsearchClicked] = useState(false);
  const history = useHistory();
  if (localStorage.getItem("allowed") === "0") {
    window.location.href = "/home";
  }
  const uid = JSON.parse(localStorage.getItem("user"))["uid"];



  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      fontFamily: "Rubik",
    },
    content: {
      marginTop: "85px",
      fontFamily: 'Rubik',
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: "24px 24px 150px",
      '@media (max-width: 368px)': {
        padding: "24px 15px 24px 15px",
      },
    },
    nosavedtxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "55vh",
    }
  }));


  const classes = useStyles();


  const fetchsaved = async () => {
    var temparr = [];
    var promises = [];
    var res = db.collection("users").doc(uid);
    res.get().then((doc) => {
      var i = 0;
      var arr = doc.data().saved;
      setsavedId(doc.data().saved);

      // console.log(doc.data().saved);

      for (i = 0; i < arr.length; i++) {
        promises.push(myFunc(arr[i]));
      }
      Promise.all(promises).then(() => {
        // console.log(temparr);
        Setpost(temparr);
      });
      function myFunc(item) {
        return new Promise((resolve) => {
          var docRef = db.collection("posts").doc(item);
          docRef.get().then((doc) => {
            var data = doc.data();

            data = { ...data, id: item };
            temparr.push(data);
            // console.log(data);
            resolve(temparr);
          });
        });
      }
    });
  };
  let count = 0;
  post.map((p) => {
    if (p.img_url) {
      count += 1;
    }
  });

  // console.log(count, "count");

  async function del(id) {
    let arr2 = [...savedId];
    if (arr2.indexOf(id) > -1) {
      arr2 = arr2.filter((e) => e !== id);
      await db.collection("users").doc(uid).update({ saved: arr2 });
      setDeleted(true);
    }
  }

  useEffect(() => {
    if (deleted) {
      fetchsaved();
    }
  }, [deleted]);

  function tileid(x) {
    localStorage.setItem(
      "postid",
      JSON.stringify({
        postid: x,
      })
    );
    history.push(`/folio/${x}`);
  }
  useEffect(() => {
    if (key) {
      fetchsaved()
    }
  }, [key])
  useEffect(() => {
    fetchsaved();
  }, []); //


  useEffect(() => {
    // document.title = "Saved Folios"
    window.scrollTo(0, 0);
  }, []);


  return (
    <React.Fragment>

      <Helmet>

        {/* <!-- HTML Meta Tags --> */}
        <title>Saved Folios | Kredey | Publish and Read Amazing Documents</title>
        <meta name="description" content="Kredey Folio is a platform where people, can read documents to learn new things and publishers, can share their work and reach millions of readers" />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Saved Folios | Kredey | Publish and Read Amazing Documents" />
        <meta itemprop="description" content="Kredey Folio is a platform where people, can read documents to learn new things and publishers, can share their work and reach millions of readers" />
        <meta itemprop="image" content="" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://www.kredey.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Saved Folios | Kredey | Publish and Read Amazing Documents" />
        <meta property="og:description" content="Kredey Folio is a platform where people, can read documents to learn new things and publishers, can share their work and reach millions of readers" />
        <meta property="og:image" content="" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Saved Folios | Kredey | Publish and Read Amazing Documents" />
        <meta name="twitter:description" content="Kredey Folio is a platform where people, can read documents to learn new things and publishers, can share their work and reach millions of readers" />
        <meta name="twitter:image" content="" />

      </Helmet>

      <div>
        {/* Navbar */}
        <NavFront
          setnavClick={setnavClick}
          setsearchClicked={setsearchClicked}
          setexacttitle={setexacttitle}
          searchtitle={searchtitle}
          setsearchtitle={setsearchtitle}
          searchtext={searchtext}
          setsearchtext={setsearchtext}
          setsearchtype={setsearchtype}
        />
        {!searchClicked && searchtext !== "" ? (
          <Search
            exacttitle={exacttitle}
            searchtitle={searchtitle}
            setsearchClicked={setsearchClicked}
            searchtype={searchtype}
            searchtext={searchtext}
          />
        ) : null}
      </div>

      <div className={classes.root} style={{ minHeight: "100vh" }}>
        <main className={classes.content}>
          <Head style={{ cursor: "default" }}>Saved Folios</Head>

          {post &&
            post.map((p) => {
              return !p.img_url ? (
                <Item id="deleted">
                  <p style={{ textAlign: "center", margin: 0, cursor: "default" }}>
                    Sorry! The publisher deleted the folio.
                    {/* Sorry! The publisher of the folio deleted `{p.title}` with id:  {p.id} */}
                  </p>
                  <Close
                    onClick={() => del(p.id)}
                  >
                    x
                  </Close>
                </Item>
              ) : (<></>)
            })
          }








          {count == 0 ? (<div className={classes.nosavedtxt}>No Saved Folios</div>)
            :
            <>
              {post &&
                post.map((p) => {
                  return p.img_url ? (
                    <>
                      <ItemSaved
                        Image={p.img_url}
                        title={p.title}
                        name={p.name}
                        postedby={p.postedBy}
                        desc={p.description}
                        id={p.id}
                        onclick={tileid}
                        Setpost={Setpost}
                        setkey={setkey}
                        setsavedId={setsavedId}
                        key={key}
                      />

                    </>
                  ) : (
                    // <Item id="deleted">
                    //   <p style={{ textAlign: "center", margin: 0, cursor: "default" }}>
                    //     Sorry! The publisher deleted the folio.
                    //     {/* Sorry! The publisher of the folio deleted `{p.title}` with id:  {p.id} */}
                    //   </p>
                    //   <Close
                    //     onClick={() => del(p.id)}
                    //   >
                    //     x
                    //   </Close>
                    // </Item>
                    <></>
                  );
                })}
            </>
          }



        </main>
      </div>

      <hr style={{ margin: 0, height: "0.8px" }} />
      <Footer />
    </React.Fragment>
  );
};
// ==========================Styled components=============
const Head = styled.p`
text-align: left;
font-size: 22px;
  @media (max-width: 950px){
  font-size: 20px;
};
@media (max-width: 500px){
  font-size: 18px;
};
`
const Item = styled.div`
display:flex;
flex-direction:row;
margin-top: 20px;
width: 730px;
height: 100px;
padding: 20px;
margin-left: 35px;
border: 2px solid red;
justify-content: center;
align-items: center;
@media (max-width: 1115px){
    width: 600px;
};
@media (max-width: 985px){
margin-left: 0px;
};
@media (max-width: 950px){
    width: 450px;
margin-left: 35px;
font-size: 14px;
};
@media (max-width: 763px){
    margin-left: 0px;
  };
@media (max-width: 500px){
    width: 320px;
font-size: 13px;
height: 80px;
};
`
const Close = styled.span`
margin-left: auto;
cursor: pointer;
opacity: 0.5;
&:hover{
opacity: 1;
} 
`

export default Saved;