import React, { useEffect, useState } from "react";
import { db, auth } from "../../firebase";
import { Drawer } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import NavFront from "./Front/NavFront";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import firebase from "firebase";
import Search from "./Front/Search";
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import Grid from '@material-ui/core/Grid';
import { useAlert } from 'react-alert'
import CancelIcon from '@material-ui/icons/Cancel';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontFamily: "Rubik"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    fontSize: "15px",
    boxShadow: "0px 1px 4px rgb(0 0 0 / 20%)",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '@media (max-width: 1020px)': {
      width: `calc(${drawerWidth}px - 40px)`,
    },
    '@media (max-width: 680px)': {
      width: `calc(${drawerWidth}px - 90px)`,
    },
    '@media (max-width: 430px)': {
      width: `calc(${drawerWidth}px - 130px)`,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#DBDBDB",
    overflow: "hidden",
    paddingTop: "100px",
    '@media (max-width: 1020px)': {
      width: `calc(${drawerWidth}px - 40px)`,
    },
    '@media (max-width: 680px)': {
      width: `calc(${drawerWidth}px - 90px)`,
    },
    '@media (max-width: 430px)': {
      width: `calc(${drawerWidth}px - 130px)`,
    },
  },
  elevation4: {
    boxShadow: 0,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    margin: "150px 50px 0",
    '@media (max-width: 1020px)': {
      margin: "150px 30px 0",
    },
    '@media (max-width: 680px)': {
      margin: "150px 20px 0",
    },
    '@media (max-width: 430px)': {
      margin: "150px 10px 0",
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    border: "none",
    outline: "0",
  },
  link: {
    display: "block",
    textDecoration: "none",
    color: "black",
    cursor: "pointer",
    fontWeight: "500",
    fontSize: "18px",
    padding: "10px 0 10px 2vw",
    marginTop: "35px",
    cursor: "pointer",
    '&:hover': {
      cursor: "pointer",
      textDecoration: "none",
      color: "unset"
    },
    '@media (max-width: 1020px)': {
      fontSize: "16px",
    },
    '@media (max-width: 680px)': {
      fontSize: "14px",
    },
    '@media (max-width: 430px)': {
      fontSize: "12px",
    },
  },
  heading: {
    padding: "10px 0 10px 2vw",
    '@media (max-width: 1020px)': {
      fontSize: "22px",
    },
    '@media (max-width: 680px)': {
      fontSize: "20px",
    },
    '@media (max-width: 430px)': {
      fontSize: "18px",
    },
  },

  title: {
    '@media (max-width: 1020px)': {
      fontSize: "26px",
    },
    '@media (max-width: 680px)': {
      fontSize: "24px",
    },
    '@media (max-width: 430px)': {
      fontSize: "22px",
    },
  },
  grid: {
    marginTop: "50px",
    marginBottom: "50px",
    textAlign: "center",
    '@media (max-width: 950px)': {
      marginTop: "30px",
      marginBottom: "30px",
      textAlign: "left",
    },
  },
  input: {
    color: "black",
    width: "210px",
    padding: "1.5px",
    '@media (max-width: 950px)': {
      width: "280px",
    },
    '@media (max-width: 480px)': {
      width: "220px",
    },
  },
  label: {
    '@media (max-width: 1020px)': {
      fontSize: "18px",
    },
    '@media (max-width: 680px)': {
      fontSize: "16px",
    },
    '@media (max-width: 430px)': {
      fontSize: "14px",
    },
  },
  disabletxt: {
    color: "#666666",
    fontSize: "12px",
    paddingTop: "8px",
    cursor: "default",
    '@media (max-width: 950px)': {
      width: "280px",
      textAlign: "center",
      paddingTop: "44px",
    },
    '@media (max-width: 480px)': {
      width: "220px",
      fontSize: "11px",
    },
  }
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: "white",
    width: "100px",
    height: "40px",
    border: "none",
    color: "white",
    borderRadius: "18px",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "normal",
    textAlign: "center",
  },
}))(Button);

const SettingAcc = (props) => {
  const classes = useStyles();
  const history = useHistory();
  if (localStorage.getItem("allowed") === "0") {
    window.location.href = "/home";
  }
  const logOut = () => {
    auth.signOut();
    localStorage.removeItem("user");
    history.push("/home");
    localStorage.setItem("allowed", "0");
    localStorage.setItem("signup", "0");
  };


  const [open, setOpen] = React.useState(false);
  const [openDeactivate, setOpenDeactivate] = React.useState(false);
  const [name, setname] = useState();
  const [email, setemail] = useState();
  const [isnamechange, setisnamechange] = useState(true);
  const [ispasschange, setispasschange] = useState(true);
  const [currentPassword, setcurrentPassword] = useState();
  const [newPassword, setnewPassword] = useState("");
  const [searchtype, setsearchtype] = useState([]);
  const [navClick, setnavClick] = useState(false);
  const [searchtext, setsearchtext] = useState("");
  const [exacttitle, setexacttitle] = useState("");
  const [searchtitle, setsearchtitle] = useState([]);
  const [searchClicked, setsearchClicked] = useState(false);


  const uid = JSON.parse(localStorage.getItem("user"))["uid"];

  const alert = useAlert();

  // const deleteFromAuth = async () => {

  //   const user = firebase.auth().currentUser;

  //   user.delete().then(() => {
  //     // User deleted.
  //     console.log("deleted user from firebase authentication")
  //   }).catch((error) => {
  //     // An error ocurred
  //     // ...
  //     console.log(error);
  //   });
  // }


  const deleteFromDatabase = async () => {
    // deleted posts of the user from firebase database post collection
    const convodata = await db.collection("posts").where("postedBy", "==", uid).get();
    await convodata.docs.forEach(item => {
      db.collection("posts").doc(item.id).collection("conversations").get().then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          if (doc.exists) {
            // console.log(db.collection("posts").doc(item.id).collection("conversations").doc(`${doc.id}`));
            db.collection("posts").doc(item.id).collection("conversations").doc(`${doc.id}`).collection("replies").get().then((querySnapshot) => {
              querySnapshot.forEach(async (docx) => {
                if (docx.exists) {
                  // console.log(db.collection("posts").doc(item.id).collection("conversations").doc(`${doc.id}`).collection("replies").doc(`${docx.id}`));
                  db.collection("posts").doc(item.id).collection("conversations").doc(`${doc.id}`).collection("replies").doc(`${docx.id}`).delete().then(function () {
                    // File deleted successfully
                    // console.log("Replies Collec deleted from database of pid")
                  }).catch(function (error) {
                    // Some Error occurred
                    console.log(error);
                  });
                }
              })
            }
            )

            db.collection("posts").doc(item.id).collection("conversations").doc(`${doc.id}`).delete().then(function () {
              // File deleted successfully
              // console.log("Convo Collec deleted from database of pid")
            }).catch(function (error) {
              // Some Error occurred
              console.log(error);
            });

          }
        });
      });
    });


    const postdata = await db.collection("posts").where("postedBy", "==", uid).get();
    await postdata.docs.forEach(item => {
      db.collection("posts").doc(item.id).delete().then(function () {
        // console.log("deleted posts firebase database")
      }).catch(function (error) {
        console.log(error);
      });
    });



    //deleted user from firebase database user collection
    await db.collection("users").doc(uid).delete().then(function () {
      // console.log("deleted user from firebase database")
    }).catch(function (error) {
      console.log(error);
    });


    // //for deleting all convos msgs of uid
    db.collection("posts").get().then((querySnapshot) => {
      querySnapshot.forEach(async (doc) => {
        if (doc.exists) {
          db.collection("posts").doc(`${doc.id}`)
            .collection("conversations").where("uid", "==", uid).get().then((querySnapshot) => {
              querySnapshot.forEach(async (res) => {
                if (res.exists) {
                  db.collection("posts").doc(`${doc.id}`).collection("conversations").doc(`${res.id}`).delete().then(function () {
                    // console.log("deleting all convos msgs of uid from firebase database")
                  }).catch(function (error) {
                    console.log(error);
                  });
                }
              })
            })
        }
      });
    });

    //for deleting all replies of uid
    db.collection("posts").get().then((querySnapshot) => {
      querySnapshot.forEach(async (doc) => {
        if (doc.exists) {
          db.collection("posts").doc(`${doc.id}`)
            .collection("conversations").get().then((querySnapshot) => {
              querySnapshot.forEach(async (res) => {
                if (res.exists) {
                  db.collection("posts").doc(`${doc.id}`).collection("conversations").doc(`${res.id}`).collection("replies").where("uid", "==", uid).get().then(async function (querySnapshot) {
                    querySnapshot.forEach(async (docx) => {
                      if (docx.exists) {
                        db.collection("posts").doc(`${doc.id}`).collection("conversations").doc(`${res.id}`).collection("replies").doc(`${docx.id}`).delete().then(function () {
                          // console.log(" deleting all replies of uid from firebase database")
                        }).catch(function (error) {
                          console.log(error);
                        });
                      }
                    })
                  })
                }
              })
            })
        }
      });
    });

  }


  const deleteFromStorage = async () => {
    //deleted user profile pic from firebase storage
    const x = firebase.storage().ref().child(`users/${uid}`);
    await x.delete().then(function () {
      // console.log("profile pic img Deleted from storage")
    }).catch(function (error) {
      console.log(error);
    });

    //deleted pdfs and cover pics of the posts by user from firebase storage
    const storageRef = firebase.storage().ref(`folios/${uid}`);
    await storageRef.listAll().then((listResults) => {
      const promises = listResults.items.map((item) => {
        firebase.storage().ref().child(item.fullPath).delete().then(function () {
          // console.log("Pdf and Img Folder Deleted from storage")
        }).catch(function (error) {
          console.log(error);
        });
      });
      Promise.all(promises);
    });
  }



  const deleteUser = async () => {

    const user = firebase.auth().currentUser;

    user.delete().then(async () => {
      // User deleted.
      // console.log("deleted user from firebase authentication");
      await deleteFromDatabase();
      await deleteFromStorage();
      setTimeout(() => {
        auth.signOut();
        localStorage.clear();
        localStorage.removeItem("user");
        localStorage.removeItem("isEmail");
        localStorage.setItem("allowed", "0");
        localStorage.setItem("signup", "0");
        history.push("/");
        alert.success(<div style={{ textTransform: 'none', fontSize: "13px", textAlign: "center", padding: "5px" }}>User Deleted Successfully!</div>)
      }, 2000);

    }).catch((error) => {
      console.log(error);
      if (error.code === 'auth/requires-recent-login') {
        alert.error(<div style={{ textTransform: 'none', fontSize: "13px", textAlign: "center", padding: "5px" }}>Please, Sign in again!!</div>)
      }
    });
  };
  const fetchinfo = async () => {

    if (uid) {
      const res = db.collection('users').doc(uid);
      setname((await res.get()).data().name);
      setemail((await res.get()).data().email);
    }
  };


  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDeactivate = () => {
    setOpenDeactivate(true);
  };
  const handleCloseDeactivate = () => {
    setOpenDeactivate(false);
  };


  useEffect(() => {
    fetchinfo();
  }, []);


  const changename = () => {
    setisnamechange(false);
  };

  const savename = async () => {

    db.collection("users").where("name", "==", name)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // console.log(doc.id, " => ", doc.data());
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });

    db.collection("users").where("name", "==", name).get().then(async function (querySnapshot) {
      if (querySnapshot.empty) {
        await db.collection("users").doc(uid).update({ name: name });

        const data = await db.collection("posts").where("postedBy", "==", uid).get();
        await data.docs.forEach(item => {
          db.collection("posts").doc(item.id).update({ name: name });
        })


        //for updating names in convo collection
        db.collection("posts").get().then((querySnapshot) => {
          querySnapshot.forEach(async (doc) => {
            db.collection("posts").doc(`${doc.id}`)
              .collection("conversations").get().then((querySnapshot) => {
                querySnapshot.forEach(async (res) => {
                  db.collection("posts").doc(`${doc.id}`).collection("conversations").where("uid", "==", uid).get().then(async function (querySnapshot) {
                    querySnapshot.forEach(async (docx) => {
                      if (docx.exists) {
                        db.collection("posts").doc(`${doc.id}`).collection("conversations").doc(`${docx.id}`).update({ name: name });
                      }
                    })
                  })
                })
              })
          });
        });

        setisnamechange(true);
        let loggedInUser = JSON.parse(localStorage.getItem('user'));
        loggedInUser.name = name;
        localStorage.setItem('user', JSON.stringify(loggedInUser));
      }
      else {
        alert.success(<div style={{ textTransform: 'none', fontSize: "16px", textAlign: "center", padding: "5px" }}>Username Already Exist! Try some other name.</div>)
      }
    });

  };

  const changePassword = async () => {
    var user = auth.currentUser;
    var cred = firebase.auth.EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    if (currentPassword == newPassword) {
      setcurrentPassword(null);
      setnewPassword(null);
      window.alert("cant set as same");
    } else {
      if (await newPassword.length < 6) {
        // console.log(newPassword.length);
        alert.error(<div style={{ textTransform: 'none', fontSize: "16px", textAlign: "center", padding: "5px" }}>Password should be of atleast 6 characters</div>)

      } else {
        user
          .reauthenticateWithCredential(cred)
          .then(() => {
            // console.log("reauthenticated");
            user
              .updatePassword(newPassword)
              .then(() => {
                alert.success(<div style={{ textTransform: 'none', fontSize: "16px", textAlign: "center", padding: "5px" }}>Password updated!!</div>)
                setcurrentPassword(null);
                setnewPassword(null);
              })
              .catch((error) => {
                window.alert(error);
              });
          })
          .catch((error) => {
            if (error.code === 'auth/wrong-password') {
              alert.error(<div style={{ textTransform: 'none', fontSize: "16px", textAlign: "center", padding: "5px" }}>Please enter the valid old password</div>)

            }
            else {
              console.log(error);
            }
          });

        setispasschange(true);
      }
    }
  };


  function AvoidSpace(e) {
    if (e.which == 32 || e.key === " ") {
      // console.log('Space Detected');
      e.preventDefault();
    }
  };

  const handleChangeUserName = (event) => {
    event.preventDefault();
    event.target.value = ("" + event.target.value).toLowerCase();
    setname(event.target.value);
  }

  const avoidCCP = (event) => {
    event.preventDefault();
  }

  const handleChangePassword = () => {
    setispasschange(false);
  }


  const user = firebase.auth().currentUser;
  var provider;
  if (user !== null) {
    user.providerData.forEach((profile) => {
      provider = profile.providerId;
    });
  }

  const handleCancel = async () => {
    var docRef = db.collection("users").doc(uid);
    await docRef.onSnapshot((querySnapshot) => {
      setname(querySnapshot.data().name);
    })
    // console.log(name);
    setisnamechange(true);
  }

  useEffect(() => {
    document.title = "Account Settings"
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar style={{ boxShadow: 'none' }} position="fixed" className={classes.appBar}>
          <NavFront
            setnavClick={setnavClick}
            setsearchClicked={setsearchClicked}
            setexacttitle={setexacttitle}
            searchtitle={searchtitle}
            setsearchtitle={setsearchtitle}
            searchtext={searchtext}
            setsearchtext={setsearchtext}
            setsearchtype={setsearchtype}
          />
          <div>
            {!searchClicked && searchtext !== "" ? (
              <Search
                exacttitle={exacttitle}
                searchtitle={searchtitle}
                setsearchClicked={setsearchClicked}
                searchtype={searchtype}
                searchtext={searchtext}
              />

            ) : null}
          </div>
        </AppBar>

        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerContainer}>
            <h4 className={classes.heading}>Settings</h4>
            <Divider />
            <List>
              <Link className={classes.link}
                to="/settings"
                style={{
                  backgroundColor: "#807e7e",
                  color: "white",
                }}
              >
                Account
              </Link>

              <Link className={classes.link} to="/notification">
                Notifications
              </Link>

              <Link className={classes.link} to="/feedback">
                Feedback
              </Link>

              <Link className={classes.link}
                onClick={handleOpen}
              >
                Log Out
              </Link>

              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div className={classes.paper}>
                    <h5 id="spring-modal-title">Are you sure you want to Log Out?</h5>
                    <div className={classes.root} style={{ justifyContent: "space-evenly", marginTop: "20px" }}>
                      <ColorButton variant="contained" style={{ background: "#5db0d8" }} onClick={() => { logOut() }}>
                        Yes
                      </ColorButton>
                      <ColorButton variant="contained" color="secondary" onClick={() => { handleClose() }}>No</ColorButton>
                    </div>
                  </div>
                </Fade>
              </Modal>

            </List>
          </div>
        </Drawer>

        <main className={classes.content}>

          <h3 className={classes.title}>General</h3>

          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={12} md={4} className={classes.gridItem}>
              <h5 className={classes.label}>
                Email ID
              </h5>
              <input
                value={email}
                type="email"
                autoComplete="off"
                style={{
                  color: "#000000",
                }}
                className={classes.input}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={4} className={classes.gridItem}>
              <h5 className={classes.label}>
                Username
              </h5>

              <input
                type="text"
                maxlength="20"
                minLength='1'
                autoComplete="off"
                value={name}
                className={classes.input}
                disabled={isnamechange}
                onKeyPress={(e) => AvoidSpace(e)}
                onChange={(event) => handleChangeUserName(event)}
                onCut={avoidCCP}
                onCopy={avoidCCP}
                onPaste={avoidCCP}

              />
              {!isnamechange ? <>
                <CancelIcon color='black' style={{ width: "0.8em", position: "absolute", marginTop: "-22px" }} onClick={handleCancel} /></> : null}

              {isnamechange ? (
                <Change onClick={changename}>Change</Change>
              ) : (
                <Change onClick={savename}>Save</Change>
              )}
            </Grid>
            <Grid item xs={12} md={4} className={classes.gridItem}>

              {!ispasschange ? (
                <>
                  <h5 className={classes.label}>
                    New Password
                  </h5>
                  <input
                    type="password"
                    autoComplete="off"
                    className={classes.input}
                    onChange={(e) => setnewPassword(e.target.value)}
                  />

                  <div>
                    {" "}
                    <h5 className={classes.label} style={{ marginTop: "20px" }}>
                      Old Password
                    </h5>
                    <input
                      type="password"
                      onChange={(e) => setcurrentPassword(e.target.value)}
                      className={classes.input}
                    />
                    <Change onClick={changePassword}>Save</Change>
                  </div>
                  {/* )} */}

                </>
              ) : (
                <>
                  {provider == "google.com" ?
                    <>
                      <PassChangeBtn style={{ border: "1px solid #999999", backgroundColor: "#cccccc", color: "#666666", cursor: "default" }}
                      >Change Password</PassChangeBtn>
                      <div className={classes.disabletxt}>Signed In with Google.<br />Sorry, you can't change your password </div>
                    </>
                    : <PassChangeBtn
                      onClick={handleChangePassword}
                    >Change Password</PassChangeBtn>
                  }
                </>
              )}

            </Grid>

          </Grid >
          < hr />
          <p style={{ color: "#3e6ae1", cursor: "pointer" }} onClick={handleOpenDeactivate}>
            I wish to deactivate my account
          </p>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openDeactivate}
            onClose={handleCloseDeactivate}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openDeactivate}>
              <div className={classes.paper}>
                <h5 id="spring-modal-title">Are you sure you want to deactivate your account?</h5>
                <div className={classes.root} style={{ justifyContent: "space-evenly", marginTop: "20px" }}>
                  <ColorButton variant="contained" style={{ background: "#5db0d8" }}
                    onClick={() => { deleteUser() }}
                  >
                    Yes
                  </ColorButton>
                  <ColorButton variant="contained" color="secondary" onClick={handleCloseDeactivate}>No</ColorButton>
                </div>
              </div>
            </Fade>
          </Modal>

        </main >
      </div >
    </>
  );
};

const Change = styled.p`
  margin-top: 7px;
  margin-bottom: 0px;
  color: #3e6ae1;
  cursor: pointer;
`;

const PassChangeBtn = styled.p`
border-radius: 31.55px;
background: #5db0d8;
font-size: 15px;
font-weight: 500;
color: #fff;
border: none;
height: 36px;
width: 210px;
cursor: pointer;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
margin: 26px auto 0;
@media (max-width: 950px){
  width: 280px;
  float: left;
  margin: 0px auto 0;
};
@media (max-width: 480px){
  width: 220px;
  font-size: 15px;
  float: left;
};
`;


export default SettingAcc;
