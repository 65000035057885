import React, { useState } from "react";
import styled from "styled-components";
import { auth, db } from "../../../firebase";
import bin from "../../../assets/delete.svg";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';


const ColorButton = withStyles((theme) => ({
  root: {
    color: "white",
    width: "140px",
    height: "40px",
    border: "none",
    color: "white",
    background: "#5db0d8",
    borderRadius: "18px",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "normal",
    textAlign: "center",
    '&:hover': {
      background: "#5db0d8",
    },
  },
}))(Button);


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontFamily: "Rubik",
    justifyContent: "center",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    border: "none",
    outline: "0",
  },
}));

const ProfileTile = ({ Image, Title, Name, onclick, read, id, deleted }) => {
  const [save, setsave] = useState(false);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const checkSignIn = () => {
    if (auth.currentUser === null) {
      alert("Sign In first");
    }
  };
  const changeSaved = () => {
    if (auth.currentUser === null) {
      checkSignIn();
    } else {
      setsave(!save);
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <TileBox>
      <Box onClick={() => onclick(id)}>
        {/* Image */}
        <Img src={Image}></Img>

        <Typo>{Title}</Typo>
        {/* Author */}
        <Typox>{Name}</Typox>
        {/* Rating */}
      </Box>
      <Typox>{read} Reads
        <Bin src={bin} onClick={handleOpen} />


        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <h5 id="spring-modal-title">Are you sure you want to delete?</h5>
              <div className={classes.root} style={{ justifyContent: "center", marginTop: "20px" }}>
                <ColorButton variant="contained" color="primary" onClick={() => { deleted(id); handleClose(); }} >
                  Confirm
                </ColorButton>
              </div>
            </div>
          </Fade>
        </Modal>
      </Typox>
    </TileBox>

  );
};

// =====================Styling components=============================



const TileBox = styled.div`
justify-content: center;
margin: 0 12px 25px;
@media (max-width: 815px){
    margin: 0 8px 25px;
};
@media (max-width: 370px){
    margin: 0 4px 25px;
};
`
const Box = styled.div`
    width: 149px;
    margin-bottom: 0vh;
    &:hover{
        cursor: pointer;
    }
`
const Img = styled.img`
    width: 149px;
    height: 198px;
`
const Typo = styled.p`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size:14px;
    font-weight:600;
    font-family:Rubik;
    margin:1px;
    margin-top:10px;
    color:black;
    word-break: break-all;
    cursor: pointer;
`
const Typox = styled(Typo)`
    font-size:12px;
    font-weight:400;
    color:#A6A6A6;
    margin-bottom:3px;
    margin-top: 0vh;
    padding-bottom:3px;
`
const Bin = styled.img`
height: 14px;
width: 15px;
float: right; 
`;

export default ProfileTile;
