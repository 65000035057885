import React, { useContext } from "react";
import styled from "styled-components";
import { db, auth } from '../../../firebase';
import firebase from "firebase";
import { useHistory } from "react-router";
import google from '../../../assets/google.png';
import { userContext } from '../../../App';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';

const genUsername = require("unique-username-generator");

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontFamily: "Rubik"
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: "none",
    outline: "0",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    paddingBottom: "48px",
    textAlign: "center",
    width: "500px",
    border: "none",
    outline: "0",
    '@media (max-width: 560px)': {
      width: "320px",
    },
  },
  closeButton: {
    color: theme.palette.grey[500],
    display: "flex",
    marginLeft: "auto",
    justifyContent: "flex-end",
  },
  continue: {
    fontWeight: "normal",
    position: "relative",
    left: "75px",
    '@media (max-width: 560px)': {
      left: "15px",
    },
  }
}));


export default function SignInDialog(props) {
  let history = useHistory();
  const classes = useStyles();
  const { state, dispatch } = useContext(userContext);

  const Signup = () => {
    history.push("/signup");
  };
  const handleClose = () => {
    if (props.from === 'Profile') {
      props.setopenDialog(false);
    }
    else {
      props.setnavClick(false);
    }
  };



  const handleGoogleAuthentication = async () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    try {
      const data = await auth.signInWithPopup(provider);
      let exist = false;
      await db.collection('users').get().then(
        snapshot => snapshot.docs.map(
          doc => {
            if (doc.data().email === auth.currentUser.email) {
              exist = true;
            }
          }
        )
      )
      if (!exist) {
        const username = genUsername.generateUsername("-", 0, 20);

        var randomLength = Math.floor((Math.random() * 10) + 1);
        function randomString(length) {
          var result = '';
          var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          var charactersLength = characters.length;
          for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
              charactersLength));
          }
          return result;
        }

        const userProfPic = `https://avatars.dicebear.com/api/adventurer-neutral/:${randomString(randomLength)}.svg`;

        db.collection("users").doc(auth.currentUser.uid).set({
          email: auth.currentUser.email,
          name: username,
          follower: [],
          follower_Count: 0,
          saved: [],
          profilePic: `${userProfPic}`,
          description: "No Description to show",
          noti: true,
        });

        localStorage.setItem(auth.currentUser.uid, 'firsttime');
      }

      var docRef = db.collection("users").doc(auth.currentUser.uid);
      const data2 = await docRef.onSnapshot((querySnapshot) => {
        if (querySnapshot.exists) {
          const follower = querySnapshot.data().follower;
          const follower_Count = querySnapshot.data().follower_Count;
          const saved = querySnapshot.data().saved;
          const noti = querySnapshot.data().noti;
          const userData = {
            uid: auth.currentUser.uid,
            email: auth.currentUser.email,
            name: auth.currentUser.displayName,
            follower: follower,
            follower_Count: follower_Count,
            saved: saved,
            noti: noti,
          };
          localStorage.setItem("user", JSON.stringify(userData));
          localStorage.setItem('allowed', '1');
          dispatch({ type: "USER_SIGNIN", payload: userData });
        }
      });
      // console.log(data);
      history.push("/home"); //Redirect to home after signed in sucessfully
      // if (exist) {window.location.reload()}
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      open={props.open}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open} style={{ border: "none", outline: "0" }}>
        <div className={classes.root}>
          <div className={classes.paper}>

            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>

            <Title id="spring-modal-title">Join Kredey<br />to use this feature</Title>
            <span>Free access to all the Folios</span>


            <ButtonEmail onClick={Signup}>Continue With Email</ButtonEmail>

            <GoogleButton onClick={handleGoogleAuthentication}>
              <span>
                <Google />
              </span>
              <span className={classes.continue}>
                Continue With Google
              </span>
            </GoogleButton>

            <Text>By continuing, you agree to Kredey's <Link href='/terms'>Terms of Service</Link> and acknowledge you've read our <Link href='/privacy'>Privacy Policy</Link></Text>

            <Linkto href='/signin'>Already a member? Sign In</Linkto>

          </div>
        </div>
      </Fade>
    </Modal>
  );
}

const Title = styled.h1`
  font-size: 32px;
  @media (max-width: 560px){
    font-size: 28px;
  },
`

const ButtonEmail = styled.button`
  border-radius: 31.55px;
  background: rgb(230, 0, 35);
  font-family: Rubik;
  font-size: 17px;
  font-weight: 500;
  color: white;
  border: none;
  height: 45px;
  width: 400px;
  padding: 9.3px 16.456px;
  margin: 30px auto 10px;
  cursor: pointer;
  text-align: center;
  @media (max-width: 560px){
    font-size: 15px;
    height: 40px;
    width: 250px;
  },
`;

const GoogleButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 31.55px;
  text-align: center;
  background: #3e6ae1;
  font-size: 17px;
  font-weight: 500;
  color: white;
  border: none;
  height: 45px;
  width: 400px;
  padding: 9.3px 16.456px 9.3px 0.5px;
  cursor: pointer;
  margin: auto;
  @media (max-width: 560px){
    font-size: 15px;
    height: 40px;
    width: 250px;
  },
`;

const Google = styled.img.attrs({
  src: google,
})`
  float: left;
  height: 43px;
  width: 43px;
  @media (max-width: 560px){
    height: 40px;
    width: 40px;
  },
`;

const Text = styled.p`
  font-size: 12px;
  margin: 10px auto 30px;
  width: 400px;
  &:hover{
    cursor: default;
    }
    @media (max-width: 560px){
      width: 250px;
    },
`

const Link = styled.a`
  color: blue;
  cursor: pointer;
  &:hover{
  cursor: pointer;
  }
`

const Linkto = styled.a`
  font-size: 12px;
  color: black;
  font-weight: 900; 
  margin: 30px auto 48px;
  cursor: pointer;
  &:hover{
  cursor: pointer;
  color: black;
  }
`