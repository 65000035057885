import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

// const options = {
//   // you can also just use 'bottom center'
//   position: positions.BOTTOM_CENTER,
//   timeout: 5000,
//   offset: '30px',
//   // you can also just use 'scale'
//   transition: transitions.SCALE
// }

const options = {
  timeout: 10000,
  margin: "50px",
  position: positions.BOTTOM_CENTER,
  containerStyle: {
    zIndex: 99999
  }
};

ReactDOM.render(
  <>
    <React.StrictMode>
      <AlertProvider template={AlertTemplate} {...options} >
        <App />
      </AlertProvider>
    </React.StrictMode>
  </>,
  document.getElementById('root')

);
