import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  root: {
    margin: 0,
    'padding-bottom': "0px",
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const Title = withStyles((theme) => ({
  root: {
    "font-family": "Rubik",
    "font-weight": "bold",
    '@media (max-width: 615px)': {
      "font-size": "17px",
    },
    '@media (max-width: 425px)': {
      "font-size": "15px",
    },
  },
}))(Typography);

const Para = withStyles((theme) => ({
  root: {
    "font-family": "Rubik",
    "font-weight": "bold",
    "color": "#7b7b7b",
    '@media (max-width: 615px)': {
      "font-size": "14px",
    },
    '@media (max-width: 425px)': {
      "font-size": "12px",
    },
  },
}))(Typography);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Title variant="h6" style={{ "margin-right": "30px" }}>{children}</Title>
    </MuiDialogTitle>
  );
}
);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  }
}))(MuiDialogActions);



const OkBtn = withStyles((theme) => ({
  root: {
    'border-radius': "31.55px",
    "background": "#3e6ae1",
    "font-family": "Rubik",
    "font-size": "15px",
    "font-weight": "500",
    color: "white",
    border: "none",
    height: "40px",
    width: "150px",
    margin: "5px 0",
    padding: "9.3px 16.456px",
    cursor: "pointer",
    "text-align": "center",
    '&:hover': {
      backgroundColor: "#3e6ae1",
    },
    '@media (max-width: 615px)': {
      "font-size": "13px",
      width: "100px",

    },
  },
}))(Button);

export default function ForgotPasswordModal({ open, setforgotpassword }) {

  const handleClose = () => {
    setforgotpassword(false);
    window.location.href = '/signin';
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div style={{ padding: "15px" }}>
          <DialogTitle id="alert-dialog-slide-title">Password reset link has been sent to your email address</DialogTitle>
          <DialogContent>
            <Para gutterBottom>
              Please click on the link that has been sent to your email address to reset your password.
            </Para>
          </DialogContent>

          <DialogActions>
            <OkBtn variant="contained" color="primary" onClick={handleClose}>
              Ok
            </OkBtn>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}