import React from "react";
import "../../intro.css";
import styled from "styled-components";
import Navbar from "../layouts/Navbar"
import Footer from "../layouts/Footer";
import Grid from '@material-ui/core/Grid';
// import aboutimg from '../../assets/images/about-image.png'
import aboutimg from '../../assets/images/Creativity.gif'
import aboutimg650 from '../../assets/images/Creativity650.gif'
import planting from '../../assets/images/planting.png'
import cloudbg from '../../assets/images/cloud-bg.png'
import kredeyplant from '../../assets/images/kredey-plant.png'
import ourpurposebg from '../../assets/images/Our-Purpose-Bg.png'
import ourpurposebg650 from '../../assets/images/Our-Purpose-Bg650px.png'
import rajat from '../../assets/images/rajat.jpeg'
import priyanka from '../../assets/images/priyanka.jpeg'
import mayank from '../../assets/images/mayank.jpg'
import rec1 from '../../assets/images/Rectangle1.png'
import bgplantwithkredey from '../../assets/images/bgplantwithkredey.png'
import lines from '../../assets/images/lines.png'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Teamtile from '../pages/Front/Teamtile'
import { withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";



const TopGrid = withStyles((theme) => ({
    root: {

        '@media (max-width: 650px)': {
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundImage: `url(${aboutimg650})`,
        },
    },
}))(Grid);


const HGrid = withStyles((theme) => ({
    root: {
        display: "grid",
        gridAutoFlow: "column",
        justifyContent: "center",
        width: "100%",
        overflowX: "scroll",
        '@media (max-width: 1020px)': {
            justifyContent: "flex-start",
        },
    },
}))(Grid);


const About = () => {
    window.scrollTo(0, 0);
    React.useEffect(() => {
        document.title = "About Us | Kredey | Publish and Read Amazing Documents"
    }, []);

    const customTheme = createTheme({
        breakpoints: {
            keys: ["xs", "sm", "md", "lg", "xl", "xxl"],
            values: { xs: 0, sm: 650, md: 730, lg: 950, xl: 1350 }
        }
    });

    const team = [
        { Name: "RAJAT BANSALA", Job: "FOUNDER, DESIGNER", Image: `${rajat}`, linkedin: "https://www.linkedin.com/in/rajat-bansla-b49177167/" },
        { Name: "PRIYANKA CHATTERJEE", Job: "WEB DEVELOPER", Image: `${priyanka}`, linkedin: "https://www.linkedin.com/in/priyanka-9075/" },
        { Name: "MAYANK AGARWAL", Job: "WEB DEVELOPER", Image: `${mayank}`, linkedin: "https://www.linkedin.com/in/mayank-agarwal-profile/" },
        // { Name: "RAJAT BANSALA", Job: "FOUNDER", Image: `${teamimg}`, x: "4" },
    ];

    const xscroll = (d) => {
        var element = document.querySelector("#sticky");

        element.addEventListener('wheel', (event) => {
            event.preventDefault();

            element.scrollBy({
                left: event.deltaY < 0 ? -30 : 30,
            });
        });
    }
    React.useEffect(async () => {
        await xscroll();
    }, []);

    return (
        <>
            <Navbar />

            <ThemeProvider theme={customTheme}>
                <TopGrid container>
                    <Grid item sm={6} style={{ margin: "auto", flexBasis: "0" }}>
                        <Rec1 src={rec1} alt="" />
                        {/* <Rec1></Rec1> */}
                        <H1 style={{ cursor: "default" }}>Knowledge.</H1>
                        <H1 style={{ cursor: "default" }}>Share.</H1>
                        <H1 style={{ cursor: "default" }}>Discuss.</H1>
                        <div style={{ marginTop: "30px" }}>
                            <Link to='/home' style={{ textDecoration: 'none' }}>
                                <Button>Get Started</Button>
                            </Link>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <Link to='/homepage' style={{ textDecoration: 'none' }}>
                                <Button2>Learn More</Button2>
                            </Link>
                        </div>
                    </Grid>
                    <Grid item sm={6}>
                        <Image style={{ backgroundImage: `url(${aboutimg})` }} />
                    </Grid>
                </TopGrid>

                <Section style={{ backgroundImage: `url(${cloudbg})` }}>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <Image style={{ backgroundImage: `url(${planting})` }} />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ margin: "auto", display: "flex", justifyContent: "center" }}>
                            <Content>
                                <H2 style={{ cursor: "default", color: "#0A7528", letterSpacing: "7px" }}><text>We believe in</text></H2>
                                <H2 style={{ cursor: "default" }}> <span style={{ fontWeight: "bold" }}>Environmental</span><br />
                                    Harmony..</H2>
                                <br />
                                {/* <br /> */}
                                <H2 style={{ cursor: "default" }}>..and in creating<br />things that help<br /><span style={{ fontWeight: "bold" }}>People and<br />Mother Nature.</span></H2>
                            </Content>
                        </Grid>
                    </Grid>

                </Section>
                <Grid container style={{ margin: "50px 0", alignItems: "center" }}>
                    <Grid item xs={12} sm={7} style={{ margin: "auto", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <BgPlant style={{ backgroundImage: `url(${bgplantwithkredey})` }}></BgPlant>
                        <H3 style={{ cursor: "default" }}>We have Pledged to Plant a<br />Tree for every 1 Folio you<br />Publish, until we reach our<br />goal of 100 Trees.<br /> <br />This is our humble effort to<br />pay back mother nature.</H3>
                        <Lines style={{ backgroundImage: `url(${lines})` }}></Lines>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <KPImage style={{ backgroundImage: `url(${kredeyplant})` }} />
                    </Grid>
                    <Rec2 src={rec1} alt="" />
                </Grid>

                <ImgLg src={ourpurposebg} alt="" />
                {/* <ImgLg src={ourpurposebg1} alt="" /> */}
                <ImgSm src={ourpurposebg650} alt="" />

                <div style={{ marginBottom: "150px" }}>
                    <H4>Us @Kredey</H4>
                    <H5>&nbsp;A BETTER WORKSPACE <span style={{ color: "#5DB0D8" }}>STARTS</span> WITH US&nbsp;</H5>

                    <HGrid id="sticky">
                        {team &&
                            team.map(doc => {
                                return (<Teamtile Name={doc.Name} Job={doc.Job} Image={doc.Image} linkedin={doc.linkedin}
                                // x={doc.x} 
                                />);
                            })}
                    </HGrid>
                </div>

            </ThemeProvider>

            <hr style={{ margin: 0, height: "0.8px" }} />
            {/* Footer */}
            <Footer />
        </>
    )
}
export default About;

const Rec1 = styled.img`
// height: 688px;
// width: 3.3vw;
position: absolute;
top: 140px;
left: 0;
@media (max-width: 950px){
    width: 30px;
};
@media (max-width: 768px){
    width: 25px;
};
@media (max-width: 460px){
    width: 20px;
    height: 350px;
};
`

const Rec2 = styled.img`
position: absolute;
top: 960px;
right: 0;
@media (max-width: 1043px){
    top: 1040px;
    width: 35px;
};
@media (max-width: 950px){
    top: 1072px;
    height: 527px;
    width: 30px;
};
@media (max-width: 768px){
    top: 855px;
    width: 25px;
};
@media (max-width: 650px){
    top: 1176px;
    height: 822px;
};
@media (max-width: 540px){
    height: 818px;
};
@media (max-width: 425px){
    width: 20px;
    height: 800px;
};
`

const H1 = styled.h1`
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 40px;
text-align: left;
@media (max-width: 950px){
    font-size: 35px;
};
@media (max-width: 768px){
    font-size: 30px;
};
@media (max-width: 650px){
    text-align: center;
    font-size: 35px;
};
`

const H2 = styled.h1`
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 35px;
text-align: left;
@media (max-width: 1115px){
    font-size: 35px;
};
@media (max-width: 950px){
    font-size: 30px;
};
@media (max-width: 768px){
    font-size: 25px;
};

text{
    margin-left: -200px;
    @media (max-width: 768px){
    margin-left: 0px;
    };
}
`

const H3 = styled.h1`
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 28px;
text-align: center;
margin: auto 4px;
// @media (max-width: 1115px){
//     font-size: 30px;
// };
// @media (max-width: 1025px){
//     font-size: 26px;
//   };
@media (max-width: 950px){
    font-size: 25px;
};
@media (max-width: 650px){
    font-size: 22px;
};
`

const H4 = styled.h1`
font-family: Rubik;
font-style: normal;
font-weight: bold;
font-size: 40px;
color: #8A8A8A;
text-align: center;
margin: auto 20px;
@media (max-width: 950px){
    font-size: 35px;
};
@media (max-width: 768px){
    font-size: 30px;
};
@media (max-width: 650px){
    font-size: 28px;
};
`

const H5 = styled.h1`
font-family: Rubik;
font-style: normal;
font-weight: bold;
font-size: 20px;
text-align: center;
margin: 10px 0px 40px;
::before{
    content: "| ";
    color: #5DB0D8;
}
::after{
    content: " |";
    color: #5DB0D8;
}

@media (max-width: 950px){
    font-size: 20px;
};
@media (max-width: 768px){
    font-size: 17px;
};
@media (max-width: 650px){
    font-size: 15px;
};
`

const Button = styled.button`
width: 250px;
height: 40px;
border: none;
color: white;
background: #5db0d8;
border-radius: 18px;
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 19px;
text-align: center;
@media (max-width: 950px){
    font-size: 16px;
    width: 220px;
};
@media (max-width: 768px){
    font-size: 15px;
    width: 200px;
    height: 35px;
};

@media (max-width: 650px){
    font-size: 17px;
    width: 245px;
    height: 38px;
};

`

const Button2 = styled.button`
width: 250px;
height: 40px;
border: none;
color: #000;
background: #fff;
border: 2.3px solid black;
border-radius: 18px;
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 19px;
text-align: center;
@media (max-width: 950px){
    font-size: 16px;
    width: 220px;
};
@media (max-width: 768px){
    font-size: 15px;
    width: 200px;
    height: 35px;
};

@media (max-width: 650px){
    font-size: 17px;
    width: 245px;
    height: 38px;
};

`


const Image = styled.div`
    display: flex;
    justify-content: center;
    align-self: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    height:500px;
    // height:100%;
    background-position: center;
    @media (max-width: 768px){
        height:400px;
      };
`

const KPImage = styled.div`
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    height:400px;
    background-position: center;
    margin-right: 20px;
    @media (max-width: 950px){
        height:350px;
      };
    @media (max-width: 650px){
        height:300px;
    margin-right: 0px;
      };
`

const ImgLg = styled.img`
    width: 100%;
    @media (max-width: 650px){
    display: none;
      };
`
const ImgSm = styled.img`
    display: none;
    @media (max-width: 650px){
        display: block;
        width: 100%;
    };
`

const BgPlant = styled.div`
    background-repeat: no-repeat;
    background-size: contain;
    width: 465px;
    height: 125px;
    margin-bottom: 30px;
    background-position: center;
    @media (max-width: 950px){
        width: 400px;
        height: 108px;
      };
    @media (max-width: 768px){
        width: 350px;
        height: 94px;
      };
    @media (max-width: 650px){
        width: 350px;
        height: 94px;
      };
    @media (max-width: 425px){
        width: 290px;
        height: 78px;
      };
`

const Lines = styled.div`
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 18px;
    margin-top: 25px;
    background-position: center;
    @media (max-width: 650px){
        height:20px;
    margin-bottom: 25px;
      };
`


const Section = styled.section`
    background: #E6F9F4;
    background-repeat: no-repeat;
    background-size: cover;
    // background-size: contain;
`

const Content = styled.div`
@media (max-width: 650px){
    padding:30px;
  };
`