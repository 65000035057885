import React, { useEffect } from 'react'
import Navbar from "../layouts/Navbar";
import styled from 'styled-components'
import Footer from '../layouts/Footer';

const TermsOfService = () => {

    useEffect(() => {
        document.title = "Terms Of Service | Kredey | Publish and Read Amazing Documents"
        window.scrollTo(0, 0);
    }, []);

    return (
        <>

            <Navbar />
            <Content>
                <Head>WEBSITE TERMS OF SERVICE</Head>

                <p>These Terms of Service (&ldquo;Terms&rdquo;) of (a) use of our website &nbsp;(&ldquo;Website&rdquo;) or any product(s) or service(s) in connection with Kredey or (b) any modes of registrations or usage of products, including through SD cards, tablets or other storage/transmitting device are between Kredey (&ldquo;We/Us/Our&rdquo;) and its users (&ldquo;User/You/Your&rdquo;).</p>

                <p><br /></p>
                <p>These Terms constitute an electronic record according to the provisions of the Information Technology Act, 2000 and the Information Technology (Intermediaries guidelines) Rules, 2011 and further amendments.</p>
                <p><br /></p>
                <p>Please read the Terms and the privacy policy of the Kredey (&ldquo;Privacy Policy&rdquo;) with respect to registration, the use of the Website, Services and products carefully before using the Application, Website, Services or products. The provisions of T&amp;C shall prevail in the case of any discrepancy.</p>
                <p><br /></p>
                <p>Your use/access/browsing of the Website or the Services or products or registration (with or without payment/with or without a subscription) through any means shall signify Your acceptance of the Terms and Your agreement to be legally bound by the same.</p>
                <p><br /></p>
                <p>Refrain from using availing services/using products if the Terms are not agreeable. Accessing our Services/products through registrations/subscription is non-transferable.</p>
                <p><br /></p>
                <p>1) Except as mentioned below, all information, content, material, trademarks, services marks, trade names, and trade secrets including but not limited to the software, text, images, graphics, video, script and audio, contained in the Application, Website, Services and products are proprietary property of the Kredey. No Proprietary Information may be copied, downloaded, reproduced, modified, republished, uploaded, posted, transmitted or distributed in any way without prior written permission from the Kredey. Nothing on this Website or Services or products shall be deemed to confer a license of or any other right, interest or title to or in any of the intellectual property rights belonging to the Kredey, to the User. You may own the medium on which the information, content or materials resides, but the Kredey retains complete title to the information, content or materials and all intellectual property rights inserted by the Kredey on such medium. Certain contents on the Website belonging to third parties with prior permission from the third party would stay with the third party. Further, you acknowledge that the ownership of all trademarks, copyright, logos, service marks and other intellectual property owned by any third party shall continue to vest with such party. You are not permitted to use the same without the consent of the third party.</p>
                <p><br /></p>
                <p>2) You can use our products solely for non-commercial purposes. Use of the Application, Website, Services or products or their contents other than for personal purposes is prohibited. Personal and non-commercial use is subjected to the following restrictions:</p>
                <p><br /></p>
                <p>You may not decompile, reverse engineer, or disassemble the contents of the Website and/or Services/ products or modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or remove any copyright, trademark registration, or other proprietary notices from the contents of our Website and/or Services/products.</p>
                <p></p>
                <p>You will not (a) use our Website and/or any of our product/s or service/s for any commercial purposes, or (b) advertise/sell the products, Services or domain names or otherwise (whether or not for profit), or solicit others (including, without limitation, solicitations for contributions or donations) or use any public forum for commercial purposes of any kind, or (c) use the Website/our products and Services in any way that is unlawful, or harms the Kredey or any other person or entity as determined by the Kredey.</p>
                <p><br /></p>
                <p>3) The users are prohibited from performing the following:</p>
                <p><br /></p>
                <p>Making available any content that is misleading, unlawful, harmful, threatening, abusive, tortious, defamatory, libellous, vulgar, obscene, child-pornographic, lewd, lascivious, profane, invasive of another&rsquo;s privacy, hateful, or racially, ethnically or otherwise objectionable;</p>
                <p></p>
                <p>Stalking, intimidating and/or harassing another and/or inciting others to commit violence;</p>
                <p></p>
                <p>Transmitting material that encourages anyone to commit a criminal offence, that results in civil liability or otherwise breaches any relevant laws, regulations or code of practice;</p>
                <p></p>
                <p>Interfering with any other person&rsquo;s use or enjoyment of the Website/Services;</p>
                <p></p>
                <p>Making, transmitting or storing electronic copies of materials protected by copyright without the permission of the owner, committing any act that amounts to the infringement of intellectual property or making available any material that infringes any intellectual property rights or other proprietary rights of anyone else;</p>
                <p></p>
                <p>Making available any content or material, not your right to make available under any law or contractual or fiduciary relationship, unless you own or control the rights thereto or have received all necessary consents for such use of the content;</p>
                <p></p>
                <p>Impersonate any person or entity, or falsely state or otherwise misrepresent Your affiliation with a person or entity;</p>
                <p></p>
                <p>Post, transmit or make available any material that contains viruses, Trojan horses, worms, spyware, time bombs, cancelbots, or other computer programming routines, code, files or such other programs that may harm services, interests or rights of other users or limit the functionality of any computer software, hardware or telecommunications, or that may harvest or collect any data or personal information about other Users without their consent;</p>
                <p></p>
                <p>Access or use the Website/Services/products in any manner that could damage, disable, overburden or impair any of the Application&rsquo;s/Website&rsquo;s servers or the networks connected to any of the servers on which the Application/Website is hosted;</p>
                <p></p>
                <p>Intentionally/unintentionally interfere with or disrupt the Services or violate any applicable laws related to the access to or use of the Website/Services/products, violate any requirements, procedures, policies or regulations of networks connected to the Website/Services/products, or engage in any activity prohibited by these Terms;</p>
                <p></p>
                <p>Disrupt or interfere with the security of, or otherwise cause harm to, the Website/Services/products, materials, systems resources, or gain unauthorized access to user accounts, passwords, servers or networks connected to or accessible through the Website/Services/products or any affiliated or linked sites;</p>
                <p></p>
                <p>Interfere with, or inhibit any user from using and enjoying access to the Website/ Services/products, or other affiliated sites, or engage in disruptive attacks such as the denial of service attack on the Website;</p>
                <p></p>
                <p>Use deep-links, page-scrape, robot, spider or other automatic device, program, algorithm or methodology, or any similar or equivalent manual processes, to increase traffic to the Website/Services/products, to access, acquire, copy or monitor any portion of the Website/Services/products, or in any way reproduce or circumvent the navigational structure or presentation of any content, to obtain or attempt to obtain any content, documents or information through any means not specifically made available through the Website/Services/products;</p>
                <p></p>
                <p>Alter or modify any part of the Services;</p>
                <p></p>
                <p>Use the Services for purposes that are not permitted by (i) these Terms; and (ii) any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdiction; or</p>
                <p></p>
                <p>Violate any of the terms specified under the Terms for the use of the Website/Services/products.</p>
                <p></p>
                <p></p>
                <p></p>
                <p>4) By submitting content , you grant us a worldwide, non-exclusive, royalty-free license (with the right to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit, display and distribute such Material in any and all media or distribution methods (now known or later developed) and to associate your Material with you, except as described below. You agree to let others use the Material in the same way as any other content available through the Services and others may fork, tweak and repurpose your Material in accordance with these Terms. On deleting your user account your Material and name may remain available through the Services.</p>
                <p><br /></p>
                <p>5) In the preparation of the Website/Services/products and contents therein, every effort has been made to offer current, correct, and clearly expressed information possible, but error can occur. The Kredey disclaims responsibility for any errors and accuracy of the information on the Website. To enhance the quality of the information, feedback is welcome. The Kredey reserves the right and discretion to make changes/corrections or withdraw/add contents at any time without notice. Neither the Kredey nor third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on Website/Services/products. The information and materials may contain inaccuracies or errors. We expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</p>
                <p><br /></p>
                <p>6) Our Website provides Users with access to compiled educational information. Such information is provided on an &lsquo;As Is&rsquo; basis and we assume no liability for the accuracy or completeness or use or non-obsolescence of such information. We shall neither be liable to update or ensure continuity of such information contained on the Website nor be responsible for information compiled from third party sources. The links provided are solely for convenience. It neither means that we endorse it nor do we have responsibility for the link provided. You should not create a link to the Website from another website or document without the Kredey&rsquo;s prior written consent.</p>
                <p></p>
                <p>&nbsp;</p>
                <p></p>
                <p>7) Content contained on or made available through the Web Site is not intended to and does not constitute legal advice and no attorney-client relationship is formed, nor is anything submitted to this Web Site treated as confidential. The accuracy, completeness, adequacy or currency of the Content is not warranted or guaranteed. Your use of information on the Web Site or materials linked from the Web Site is at your own risk.</p>
                <p><br /></p>
                <p>8) The contents of the Services/products are developed on the concepts in the structured curriculum syllabus for students in academics. The usage of the Services/products is not endorsed as a substitution to the academic curriculum but to supplement the same by explaining and presenting the concepts to enhance understanding. The basic definitions and formulae of subject matter would remain the same. The Kredey acknowledges the various means of delivering structured curriculum pedagogy and inclusion does not lead to an endorsement of any particular method nor does exclusion imply disapproval. Subscription does not guarantee admission to any educational institutions or passing of any exams or achievement of any specified percentage of marks in any examinations.</p>
                <p><br /></p>
                <p>9) &nbsp;Certain contents in the Services/Website/products (particularly relating to assistance in preparations for judicial services) may be opinionated. The Kredey shall not be responsible for such opinions/claims resulting from them. Further, the Kredey makes no warranties or representations whatsoever regarding the quality, content, completeness, or adequacy of such information and data.</p>
                <p><br /></p>
                <p>10) Parts of the Services are interactive, contributions are encouraged, which may or may not be subject to editorial control prior to being posted. The Kredey is not liable for the material communicated to the third party in this way. The Kredey reserves the right to remove, review, edit or delete any content. The Kredey will neither be responsible for content uploaded by Users directly on the Website (irrespective of whether We have certified any answer uploaded by the User) nor be responsible to verify whether such questions/answers or contents placed by any User contain infringing materials or not.</p>
                <p><br /></p>
                <p>11) The Kredey may (based on any form of access to the Services or Website or registrations through any source whatsoever), contact you through SMS, email, WhatsApp and call, to notify updates and/or to seek permission for demonstration of its products. You grant permission to contact him/her through telephone, SMS, e-mail, WhatsApp. The Kredey isn&rsquo;t liable for financial penalties, damages, expenses in case the User&rsquo;s mobile number is registered with Do not Call (DNC) database. On registration, you agree to make available your contact details to our employees, associates and partners so that you may be contacted for information and promotions through telephone, SMS, email, WhatsApp etc.</p>
                <p><br /></p>
                <p>12) To enable effective provision of services, Kredey may contact you through the registered or other mobile number or e-mail provided. The User/Student is deemed to expressly permit Kredey to contact him/her on successful registration. Kredey shall reserve the right to monitor, download and use Services/products and the contents by the User/student, to analyse such usage and discuss the same with the User/student.</p>
                <p></p>
                <p>&nbsp;</p>
                <p></p>
                <p>13) This Web Site may contain advertising and sponsorship. Advertisers and sponsors are responsible for ensuring that material submitted for inclusion on the Web Site is accurate and complies with applicable laws. Provider will not be responsible for the illegality of or any error or inaccuracy in advertisers&apos; or sponsors&apos; materials.</p>
                <p></p>
                <p>&nbsp;</p>
                <p></p>
                <p>&nbsp;</p>
                <p></p>
                <p>&nbsp;</p>
                <p></p>
                <p>14) While Kredey makes efforts to train the sales and services department to enable quality control, it neither makes warranties or representations regarding the quality and competence of the personnel nor would be responsible for any deviant behaviour. Feedback is always welcome but Kredey reserves the right and discretion to act on this regard.</p>
                <p></p>
                <p>15) Provider does not represent or warrant that the Web Site will be error-free, free of viruses or other harmful components, or that defects will be corrected. Provider does not warrant or represent that the information available on or through the Web Site will be correct, accurate, timely, or otherwise reliable. Provider may make improvements and/or changes to its features, functionality or Content at any time.</p>
                <p></p>
                <p>&nbsp;</p>
                <p></p>
                <p>16) Third party content may appear on this Web Site or may be accessible via links from this Web Site. Provider is not responsible for and assumes no liability for any mistakes, misstatements of law, defamation, slander, libel, omissions, falsehood, obscenity, pornography or profanity in the statements, opinions, representations or any other form of content contained in any third party content appearing on the Web Site. You understand that the information and opinions in the third party content is neither endorsed by nor does it reflect the belief of Provider.</p>
                <p><br /></p>
                <p>17) Kredey conducts competitions and the user agrees that Kredey use the submissions including videos, written content, craftwork etc. for promotional purposes. All rights are reserved by Kredey. The user grants permission to Kredey for using every kind of submitted information for promotional campaigns on its discretion and the user ceases rights on the same once submitted.</p>
                <p><br /></p>
                <p>18) Persons &ldquo;competent/capable&rdquo; of contracting within the meaning of the Indian Contract Act, 1872 shall be eligible to register.</p>
                <p><br /></p>
                <p>19) The user agrees to defend, indemnify and hold harmless Kredey, its officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney&rsquo;s fees) arising from: (i) Your use of and access of the Website/Services; (ii) Your violation of any term of these Terms or any other policy of Kredey; (iii) Your violation of any third party right, including without limitation, any copyright, property, or privacy right; or (iv) any claim that Your use of the Website/Services has caused damage to a third party. This defense and indemnification obligation will survive these Terms.</p>
                <p><br /></p>
                <p>12) Kredey, its officers, directors, employees, partners or agents shall not be liable to You or any third party for any special, incidental, indirect, consequential or punitive damages whatsoever, including those resulting from loss of use, data or profits or any other claim arising out, of or in connection with, your use of, or access to, the Website.</p>
                <p><br /></p>
                <p>21) The breach of these Terms, would cause Kredey irreparable damage. Kredey, therefore, shall be entitled to obtain an injunction against such a breach from any court of competent jurisdiction. Kredey&rsquo;s right to obtain such relief shall not limit its right to obtain other remedies.</p>
                <p><br /></p>
                <p>22) Violation of the T&amp;C may result in immediate suspension/termination of your Accounts in addition to legal actions. Kredey may disclose the account information if required by any Governmental or legal authority on such circumstances. You acknowledge that violation can result in liabilities upon you.</p>
                <p><br /></p>
                <p>23) The Terms shall be according to laws of India, without regard to conflict of law principles. Further, the Terms shall be subject to the exclusive jurisdiction of the competent courts in Bihar and you accede to and accept the jurisdiction of such courts.</p>
                <p><br /></p>
                <p>24) Kredey has the right to change, modify, suspend, or discontinue and/or eliminate any aspect(s), features or functionality of the Services accordingly without notice. Further, Kredey reserves the right to amend the Terms without prior notice. Kredey makes no commitment, express or implied, to maintain or continue any aspect of the Application. You agree that Kredey shall not be liable to You or any third party for any modification, suspension or discontinuance of the Services. All prices are subject to change without notice.</p>
                <p></p>
                <p>&nbsp;</p>
                <p></p>
                <p>General Provisions</p>
                <p></p>
                <p>Notice: All notices served by Kredey shall be provided via email to your account or as a general notification on the Application. Notices to be sent via editor: hello@kredey.com</p>
                <p></p>
                <p>Entire Agreement: The Terms, along with the Privacy Policy, and any other guidelines made applicable to the Application from time to time, constitute the entire agreement between Kredey and You with respect to Your access to or use of the Website and the Services thereof.</p>
                <p></p>
                <p>Assignment: You cannot assign or otherwise transfer Your obligations under the Terms, or any right granted hereunder to any third party. Kredey&rsquo;s rights under the Terms are freely transferable by the Kredey to any third parties without the requirement of seeking Your consent.</p>
                <p></p>
                <p>Severability: If for any reason, a court of competent jurisdiction finds any provision of the Terms, or portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to give effect to the intent of the parties as reflected by that provision, and the remainder of the Terms shall continue in full force and effect.</p>
                <p></p>
                <p>Waiver: Any failure by Kredey to enforce or exercise any provision of the Terms, or any related right, shall not constitute a waiver by Kredey of that provision or right.</p>
                <p></p>
                <p>Kredey provides these Terms so that you are aware of the terms that apply to your use of the Website and Services. You acknowledge that, Kredey has given You a reasonable opportunity to review these Terms and that you have agreed to them.</p>
                <p></p>
                <p>Feedback</p>
                <p></p>
                <p>All Feedbacks are non-confidential. The Website assures free use of information. By submitting the feedback, You represent and warrant that (i) Your feedback does not contain confidential or proprietary information of You or of third parties; (ii) Kredey is not under any obligation of confidentiality, express or implied, with respect to the feedback; (iii) the Website may have something similar to the Feedback already under consideration or in development; and (iv) You are not entitled to any compensation or reimbursement of any kind from Kredey for the feedback under any circumstances, unless specified.</p>
                <p></p>
                <p>Under no circumstances shall Kredey be held responsible in any manner for any content provided by other users even if the content is offensive, hurtful or offensive. Please exercise caution while accessing the Website.</p>
                <p><br /></p>
                <Head>Plagiarism Policy</Head>
                {/* <p><br /></p> */}
                <p>UNIVERSITY GRANTS COMMISSION (PROMOTION OF ACADEMIC INTEGRITY AND PREVENTION OF PLAGIARISM IN HIGHER EDUCATIONAL INSTITUTIONS) REGULATIONS, 2018 Click here</p>
                <p></p>
                <p>Plagiarism occurs if someone intentionally or knowingly copies others work or someone copies content without providing the appropriate references.</p>
                <p></p>
                <p>Plagiarism before Publishing</p>
                <p></p>
                <p>Kredey Folio will judge any case of plagiarism on its limits. If plagiarism is detected by the editorial board member, reviewer, editor etc., in any stage of article process- before or after acceptance, during editing or at a page proof stage. We will alert the same to the author(s) and will ask them to rewrite the content or to cite the references from where the content has been taken. If more than 10% of the paper is plagiarized- the article may be rejected and the same is notified to the author.</p>
                <p></p>
                <p>When Plagiarism Check Done?</p>
                <p></p>
                <p>All the submitted manuscripts for publication are checked for plagiarism after submission and before starting review.</p>
                <p></p>
                <p>Handling Plagiarism?</p>
                <p></p>
                <p>The manuscripts or papers in which the plagiarism is detected are handled based on the extent of the plagiarism.</p>
                <p></p>
                <p>&lt;5% Plagiarism: The manuscript will be given an ID and the manuscript is sent to author for content revision.</p>
                <p></p>
                <p>5- 30% Plagiarism: The manuscript will not be given an ID and the manuscript is sent back to author for content revision.</p>
                <p></p>
                <p>&gt;30% Plagiarism: The manuscript will be rejected without the review. The authors are advised to revise the manuscript and resubmit the manuscript.</p>
                <p></p>
                <p>Why manuscripts with &gt;10% Plagiarism is rejected?</p>
                <p></p>
                <p>If the plagiarism is detected more than 10%, it is found that the authors are very unlikely to revise the manuscript and submit the revised version. However, authors are welcome to do the required revisions and submit the manuscript as a new submission.</p>
                <p></p>
                <p>What if Plagiarism detected after Publication</p>
                <p></p>
                <p>If plagiarism is detected after publication, the Papers/Articles will conduct an investigation. If plagiarism is found, the Papers/Articles editorial office will contact the author&rsquo;s institute and funding agencies. The paper containing the plagiarism will be marked on each page of the PDF. Depending on the extent of the plagiarism, the paper may also be formally retracted.</p>
                <p></p>
                <p>Originality</p>
                <p></p>
                <p>By submitting Author(s) Articles/Papers &nbsp;it is understood that it is an original manuscript and is unpublished work and is not under consideration elsewhere. Plagiarism, including duplicate publication of the author&rsquo;s own work, in whole or in part without proper citation is not tolerated by the Papers/Articles. Manuscripts submitted to the Papers/Articles may be checked for originality using anti-plagiarism software.</p>
                <p></p>
                <p>Plagiarism misrepresents ideas, words, and other creative expression as one&rsquo;s own. Plagiarism represents the violation of copyright law. Plagiarism appears in various forms.</p>
                <p></p>
                <p>Copying the same content from the other source. Purposely using portions of another author&rsquo;s paper or content.</p>
                <p></p>
                <p>Copying elements of another author&rsquo;s paper, such as figures, tables, equations or illustrations that are not common knowledge, or copying or purposely using sentences without citing the source.</p>
                <p></p>
                <p>Using exact text downloaded from the internet.</p>
                <p></p>
                <p>Copying or downloading figures, photographs, pictures or diagrams without acknowledging your sources.</p>
                <p></p>
                <p>&nbsp;Acknowledging Author(s) Sources</p>
                <p></p>
                <p>Self-plagiarism is a related issue. In this document we define self-plagiarism as the verbatim or near-verbatim reuse of significant portions of one&rsquo;s own copyrighted work without citing the original source. Note that self-plagiarism does not apply to publications based on the author&rsquo;s own previously copyrighted work (e.g., appearing in a conference proceedings) where an explicit reference is made to the prior publication. Such reuse does not require quotation marks to delineate the reused text but does require that the source be cited.</p>
                <p></p>
                <p>Accidental or Unintentional</p>
                <p></p>
                <p>One may not even know that they are plagiarizing. It is the author(s) whose responsibility is to make certain that they understand the difference between quoting and paraphrasing, as well as the proper way to cite material.</p>
                <p></p>
                <p>Report Plagiarism</p>
                <p></p>
                <p>Editorial team, reviewer team has taken due care to assess the plagiarism but in case plagiarism is found with published manuscripts/papers, it is the sole duty of the original contributor to report the plagiarized content to us. Such reported cases will be re-evaluated and notified to the Author(s) for making revision. We will remove the paper from web sources/Application until author resubmit revised paper. To report plagiarism contact to hello@kredey.com</p>
                <p></p>
            </Content>
            <hr style={{ margin: 0, height: "0.8px" }} />
            <Footer />
        </>
    )
}
const Content = styled.div`
    margin: 50px 32px 200px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    cursor: default;
    min-height: calc(100vh - 150px);
    p{
        font-size: 17px;
        @media(max-width:600px){
            font-size: 15px;
        }
    }
    @media(max-width:600px){
        font-size: 15px;
    }
`;
const Head = styled.p`
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 50px;
`
export { Head, Content }
export default TermsOfService;