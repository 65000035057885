import React from 'react';
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiTypography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Navbar from "../layouts/Navbar"
import Footer from "../layouts/Footer"
import bg from "../../assets/images/illustrations/bg.png";

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const Typography = withStyles((theme) => ({
    root: {
        fontSize: '16px',
        fontWeight: theme.typography.fontWeightRegular,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        '@media(max-width:900px)': {
            fontSize: '15px',
        },
        '@media(max-width:700px)': {
            fontSize: '14px',
        }
    },
}))(MuiTypography);

export default function FAQs() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    React.useEffect(() => {
        document.title = "FAQs | Kredey | Publish and Read Amazing Documents"
        window.scrollTo(0, 0);

    }, []);

    return (
        <>

            <div style={{ minHeight: "100vh" }}>
                <Navbar />
                <Main>
                    <Bg src={bg} />
                    <Title>How can we help you?</Title>
                    <Head>FAQs</Head>


                    <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>1. Why the content on the website called Folios, instead of Documents?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                We needed a Single and a Short Word to characterise different type of written content that is published on our platform, that’s why we decided to use the word Folio. Document is one type of written content, whereas by Folio we mean any information in textual form, for example: notes, research paper, infographic brochure, etc.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')} >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2d-content" id="panel2d-header">
                            <Typography>2. Can I download Folios?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                No, you can’t download or save Folios.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')} >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3d-content" id="panel3d-header">
                            <Typography>3. What are the subscription charges?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Everything is free on our website. But to use some features you must create an account.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel4'} onChange={handleChange('panel4')} >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4d-content" id="panel4d-header">
                            <Typography>4. Who can Publish/Upload?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Anyone! who believes that his/her content is worth sharing to the world.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel5'} onChange={handleChange('panel5')} >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5d-content" id="panel5d-header">
                            <Typography>5. Can we upload content from other websites or sources?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                A Big No, because we promote unique and thoughtful content on our platform. Also, copying a data without the permission of its owner is violation of law.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel6'} onChange={handleChange('panel6')} >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6d-content" id="panel6d-header">
                            <Typography>6. What we can upload? What file type is permitted?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Any written content that is informative and insightful, it could be anything - document, notes, research paper, infographic brochure, etc. You can only upload PDF files.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel7'} onChange={handleChange('panel7')} >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel7d-content" id="panel7d-header">
                            <Typography>7. How Publishers will benefit from sharing their content?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Knowledge is something that must not be kept in papers and it must reach someone, who needs it and Kredey provides the best medium. Publishers can reach millions of readers by sharing their written content on Kredey. Our Public Discussion feature connects Readers and Publishers directly, this feature reduces the communication gap between them. In Future, Publishers will also have a chance to monetise their Folios.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel8'} onChange={handleChange('panel8')} >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel8d-content" id="panel8d-header">
                            <Typography>8. Is my Published Content protected from Copyright Infringement?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                We don’t allow duplication of content on our platform, because we value the hard-work and efforts, you put into creating an Amazing Folio.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel9'} onChange={handleChange('panel9')} >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel9d-content" id="panel9d-header">
                            <Typography>9. Can I edit my Folio, after it is published?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                No, you’ve to first delete the existing Folio and then re-publish the edited one.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel10'} onChange={handleChange('panel10')} >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel10d-content" id="panel10d-header">
                            <Typography>10. Why my Folios and my Account is Deleted?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                We constantly work on the improving our platform to give best possible reading experience. If any Person or Folio(s) violates our rules and guidelines, then we have full right to delete/remove that Person’s Account or Folio(s) without any prior notice.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Main>
            </div>
            <hr style={{ margin: 0, height: "0.8px" }} />
            <Footer />
        </>
    );
}

const Main = styled.div`
    font-family: Rubik;
    font-style: normal;
    margin: 50px 32px 200px;
    @media(max-width:600px){
        margin: 50px 12px 200px;
    }
`

const Head = styled.p`
    font-weight: bold;
    font-size: 17px;
    margin-top: 61px;
    @media(max-width:900px){
        font-size: 16px;
    margin-top: 26px;
    }
    @media(max-width:700px){
        font-size: 15px;
    margin-top: 0px;
    }
    @media(max-width:500px){
        text-align: center;
        margin-bottom:0;
        padding-top: 50px;
    }
`
const Bg = styled.img`
    position: absolute;
    width: 800px;
    left: 50%;
    transform: translate(-50%, 0);
    @media(max-width:900px){
        width: 600px;
    }
    @media(max-width:700px){
        width: 450px;
    }
    @media(max-width:450px){
        width: 350px;
        margin-top:23px;
    }
`
const Title = styled.h1`
    font-weight: 600;
    font-size: 28px;
    color: #0842a0;
    text-align: center;
    padding-top: 50px;
    @media(max-width:900px){
        font-size: 24px;
    padding-top: 45px;
    }
    @media(max-width:700px){
        font-size: 20px;
        padding-top: 34px;
    }
    @media(max-width:500px){
        font-size: 20px;
        padding-top: 0px;
    }
`


// import React, { useEffect } from 'react'
// import Navbar from "../layouts/Navbar"
// import Footer from "../layouts/Footer"
// import styled from 'styled-components'
// import { withStyles } from '@material-ui/core/styles';
// import MuiAccordion from '@material-ui/core/Accordion';
// import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
// import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
// import MuiTypography from '@material-ui/core/Typography';
// import bg from "../../assets/images/illustrations/bg.png";
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// const Accordion = withStyles({
//     root: {
//         // border: '1px solid rgba(0, 0, 0, .125)',
//         boxShadow: 'none',
//         // margin: 'auto',
//         position: 'relative',
//     },
// })(MuiAccordion);

// const AccordionSummary = withStyles({
//     root: {
//         backgroundColor: 'rgba(0, 0, 0, .03)',
//         borderBottom: '1px solid rgba(0, 0, 0, .125)',
//         marginBottom: -1,
//         minHeight: 56,
//     },
// })(MuiAccordionSummary);

// const AccordionDetails = withStyles((theme) => ({
//     root: {
//         padding: theme.spacing(2),
//     },
// }))(MuiAccordionDetails);

// const Typography = withStyles((theme) => ({
//     root: {
//         fontSize: '16px',
//         fontWeight: theme.typography.fontWeightRegular,
//         fontFamily: 'Rubik',
//         fontStyle: 'normal',
//         '@media(max-width:900px)': {
//             fontSize: '15px',
//         },
//         '@media(max-width:700px)': {
//             fontSize: '14px',
//         }
//     },
// }))(MuiTypography);

// const FAQs = () => {

//     useEffect(() => {
//         document.title = "FAQs | Kredey | Publish and Read Amazing Documents"
//     }, []);


//     window.scrollTo(0, 0);
//     return (
//         <>
//             <div style={{ minHeight: "100vh" }}>
//                 <Navbar />
//                 <Main>
//                     <Bg src={bg} />
//                     <Title>How can we help you?</Title>
//                     <Head>FAQs</Head>


//                     <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
//                         <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header">
//                             <Typography>1. Why the content on the website called Folios, instead of Documents?</Typography>
//                         </AccordionSummary>
//                         <AccordionDetails>
//                             <Typography>
//                                 We needed a Single and a Short Word to characterise different type of written content that is published on our platform, that’s why we decided to use the word Folio. Document is one type of written content, whereas by Folio we mean any information in textual form, for example: notes, research paper, infographic brochure, etc.
//                             </Typography>
//                         </AccordionDetails>
//                     </Accordion>



//                     <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')} >
//                         <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2d-content" id="panel2d-header">
//                             <Typography>2. Can I download Folios?</Typography>
//                         </AccordionSummary>
//                         <AccordionDetails>
//                             <Typography>
//                                 No, you can’t download or save Folios.
//                             </Typography>
//                         </AccordionDetails>
//                     </Accordion>
//                     <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')} >
//                         <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
//                             <Typography>3. What are the subscription charges?</Typography>
//                         </AccordionSummary>
//                         <AccordionDetails>
//                             <Typography>
//                                 Everything is free on our website. But to use some features you must create an account.
//                             </Typography>
//                         </AccordionDetails>
//                     </Accordion>
//                     <Accordion square expanded={expanded === 'panel4'} onChange={handleChange('panel4')} >
//                         <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
//                             <Typography>4. Who can Publish/Upload?</Typography>
//                         </AccordionSummary>
//                         <AccordionDetails>
//                             <Typography>
//                                 Anyone! who believes that his/her content is worth sharing to the world.
//                             </Typography>
//                         </AccordionDetails>
//                     </Accordion>
//                     <Accordion square expanded={expanded === 'panel5'} onChange={handleChange('panel5')} >
//                         <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
//                             <Typography>5. Can we upload content from other websites or sources?</Typography>
//                         </AccordionSummary>
//                         <AccordionDetails>
//                             <Typography>
//                                 A Big No, because we promote unique and thoughtful content on our platform. Also, copying a data without the permission of its owner is violation of law.
//                             </Typography>
//                         </AccordionDetails>
//                     </Accordion>
//                     <Accordion square expanded={expanded === 'panel6'} onChange={handleChange('panel6')} >
//                         <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
//                             <Typography>6. What we can upload? What file type is permitted?</Typography>
//                         </AccordionSummary>
//                         <AccordionDetails>
//                             <Typography>
//                                 Any written content that is informative and insightful, it could be anything - document, notes, research paper, infographic brochure, etc. You can only upload PDF files.
//                             </Typography>
//                         </AccordionDetails>
//                     </Accordion>
//                     <Accordion square expanded={expanded === 'panel7'} onChange={handleChange('panel7')} >
//                         <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
//                             <Typography>7. How Publishers will benefit from sharing their content?</Typography>
//                         </AccordionSummary>
//                         <AccordionDetails>
//                             <Typography>
//                                 Knowledge is something that must not be kept in papers and it must reach someone, who needs it and Kredey provides the best medium. Publishers can reach millions of readers by sharing their written content on Kredey. Our Public Discussion feature connects Readers and Publishers directly, that reduces the communication gap between them. In Future, Publishers will also have a chance to monetise their Folios.
//                             </Typography>
//                         </AccordionDetails>
//                     </Accordion>
//                     <Accordion square expanded={expanded === 'panel8'} onChange={handleChange('panel8')} >
//                         <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
//                             <Typography>8. Is my Published Content protected from Copyright Infringement?</Typography>
//                         </AccordionSummary>
//                         <AccordionDetails>
//                             <Typography>
//                                 We don’t allow duplication of content on our platform, because we value the hard-work and efforts, you put into creating an Amazing Folio.
//                             </Typography>
//                         </AccordionDetails>
//                     </Accordion>
//                     <Accordion square expanded={expanded === 'panel9'} onChange={handleChange('panel9')} >
//                         <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
//                             <Typography>9. Can I edit my Folio, after it is published?</Typography>
//                         </AccordionSummary>
//                         <AccordionDetails>
//                             <Typography>
//                                 No, you’ve to first delete the existing Folio and then re-publish the edited one.
//                             </Typography>
//                         </AccordionDetails>
//                     </Accordion>
//                     <Accordion square expanded={expanded === 'panel10'} onChange={handleChange('panel10')} >
//                         <AccordionSummary aria-controls="panel10d-content" id="panel10d-header">
//                             <Typography>10. Why my Folios and my Account is Deleted?</Typography>
//                         </AccordionSummary>
//                         <AccordionDetails>
//                             <Typography>
//                                 We constantly work on the improving our platform to give best possible reading experience. If any Person or Folio(s) violates our rules and guidelines, then we have full right to delete/remove that Person’s Account or Folio(s) without any prior notice.
//                             </Typography>
//                         </AccordionDetails>
//                     </Accordion>
//                 </Main>
//             </div>
//             <hr style={{ margin: 0, height: "0.8px" }} />
//             <Footer />
//         </>
//     )
// }
// export default FAQs;

// const Main = styled.div`
//     font-family: Rubik;
//     font-style: normal;
//     margin: 50px 32px;
//     @media(max-width:600px){
//         margin: 50px 12px;
//     }
// `

// const Head = styled.p`
//     font-weight: bold;
//     font-size: 17px;
//     margin-top: 61px;
//     @media(max-width:900px){
//         font-size: 16px;
//     margin-top: 26px;
//     }
//     @media(max-width:700px){
//         font-size: 15px;
//     margin-top: 0px;
//     }
//     @media(max-width:500px){
//         text-align: center;
//         margin-bottom:0;
//         padding-top: 34px;
//     }
// `
// const Bg = styled.img`
//     position: absolute;
//     width: 800px;
//     left: 50%;
//     transform: translate(-50%, 0);
//     @media(max-width:900px){
//         width: 600px;
//     }
//     @media(max-width:700px){
//         width: 450px;
//     }
//     @media(max-width:450px){
//         width: 350px;
//         margin-top:23px;
//     }
// `
// const Title = styled.h1`
//     font-weight: 600;
//     font-size: 28px;
//     color: #0842a0;
//     text-align: center;
//     padding-top: 50px;
//     @media(max-width:900px){
//         font-size: 24px;
//     padding-top: 45px;
//     }
//     @media(max-width:700px){
//         font-size: 20px;
//         padding-top: 34px;
//     }
//     @media(max-width:500px){
//         font-size: 20px;
//         padding-top: 0px;
//     }
// `