export const initialState = null;

export const reducer = (state, action) => {
    if (action.type === "USER_SIGNUP") {
        return action.payload


    }
    if (action.type === "USER_SIGNIN") {
        return action.payload

    }
    if (action.type === "USER") {
        return action.payload


    }
    // console.log(state)
    if (action.type === "USER_POST") {
        return {
            ...state,
            userPost: [action.payload]
        }
    }
    // console.log(state)
    if (action.type === "POST_URL") {
        return {
            ...state,
            userPost: [{ url: action.payload }]
        }
    }
    if (action.type === "SIGNOUT") {
        return null;
    }
    return state;

}
