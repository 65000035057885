import React from "react";
import styled from "styled-components";

const Description = ({ Desc }) => {
    return (
        <ContainerBox>
            <Heading>Full Description</Heading>
            <Content>
                <Text style={{ cursor: "text" }}>{Desc}</Text>
            </Content>
        </ContainerBox>

    )
}

// =====================Styling components==================================
const ContainerBox = styled.div`
    margin-top: 24px;
    width: 58.3%;
    height: 365px;
    padding: 0 30px 0 20px;
    display:flex;
    flex-direction:column;
    border-left:1.75px solid  #C7C7C7;
   word-break: break-word;
   @media (max-width: 1000px){
    width: 55%;
    padding: 0 20px;
  };
   @media (max-width: 815px){
    margin-top: 0px;
    width: 100%;
    padding: 0 60px;
    height: 265px;
    border-left:none;
  };
   @media (max-width: 620px){
    height: 230px;
    padding: 0 20px;
  };
  @media (max-width: 450px){
    height: 180px;
    width: 100%;
    padding: 0 10px;
    };
`
const Heading = styled.div`
margin-bottom: 16px;
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 14px;
@media (max-width: 1000px){
    font-size: 12px;
}
`

const Text = styled.p`
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 18px;
text-align: justify;
white-space: pre-line;
@media (max-width: 1000px){
    font-size: 15px;
}
`
const Content = styled.div`
    padding:8px;
    width: 100%;
    height: 318px;
    background: #F2F1F2;
    overflow:scroll;
`


export default Description;