import React, { useState, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import Cropper from 'react-easy-crop';
import { Slider } from '@material-ui/core';
import getCroppedImg from './CropImage';
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  body: {
    height: '440px',
    '@media (max-width: 991.9px)': {
      flexDirection: "column"
    },
    '@media (max-width: 525px)': {
      // width: 260px;
      height: "345.5px",
    }
  },
  footer: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  sliderbox: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-evenly",
    margin: '20px auto',
    width: "100%",
    '@media (max-width: 991.9px)': {
      flexDirection: "column"
    }
  },
  txt: {
    fontSize: '20px',
    '@media (max-width: 575.9px)': {
      fontSize: '16px',
    }
  },
  slider: {
    width: '300px',
    '@media (max-width: 575.9px)': {
      height: '1px',
    },
    '@media (max-width: 525px)': {
      height: '3px',
    }
  },
}));


export default function SimpleModal(props) {
  const classes = useStyles();
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false)
    props.setfileselect(true)
  };

  const [croppedArea, setcroppedArea] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(2);
  const [rotation, setRotation] = useState(0);


  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setcroppedArea(croppedAreaPixels);
  }, []);

  const cropSize = { height: props.height, width: props.width };

  const Reset = () => {
    setZoom(2);
    setRotation(0);
    setCrop({ x: 0, y: 0 });
  }

  const Save = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        props.fileselect,
        croppedArea,
        rotation
      )
      props.setfileselect(croppedImage)
      props.setimageCropped(true);
    } catch (e) {
      console.error(e)
    }
    setShow(false);
  }, [croppedArea, rotation])

  // console.log(props.imageCropped, '++++++++++++++++')
  const body = (
    <div>
      <Cropper
        image={props.fileselect}
        crop={crop}
        zoom={zoom}
        rotation={rotation}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onRotationChange={setRotation}
        onZoomChange={setZoom}
        cropSize={cropSize}
        zoomWithScroll={false}
      // objectFit="contain"
      />
    </div>
  );


  return (
    <div>
      <Modal show={show} onHide={handleClose} size='xl' style={{ height: "-webkit-fill-available" }}>
        <Modal.Body className={classes.body}>{body}</Modal.Body>
        <Modal.Footer className={classes.footer}>
          <div className={classes.sliderbox}>
            <div>
              <p className={classes.txt}>Zoom</p>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.001}
                onChange={(e, zoom) => setZoom(zoom)}
                className={classes.slider}
              />
            </div>
            <div>
              <p className={classes.txt}>Rotate</p>
              <Slider
                value={rotation}
                min={-180}
                max={180}
                step={1}
                aria-labelledby="Zoom"
                onChange={(e, rotation) => setRotation(rotation)}
                className={classes.slider}
              />
            </div>
          </div>
          <BtnBox>
            <Button onClick={Reset}>
              Reset
            </Button>
            <Button onClick={Save}>
              Save
            </Button>
          </BtnBox>
        </Modal.Footer>
      </Modal>
    </div >
  );
}

const BtnBox = styled.div`
width:100%;
display: flex;
justify-content: center;
`

const Button = styled.button`
    width: 160px;
    height: 40px;
    border: none;
    color: white;
    background: #5db0d8;
    border-radius: 18px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    text-align: center;
    margin: 0 20px 30px;
    @media (max-width: 575px){
      width: 140px;
      margin: 0 10px 30px;
      height: 40px;
      font-size: 14px;
    }
`